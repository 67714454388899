import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import moment from "moment-timezone";

import { Documentsnotes } from "./Documentsnotes";
import { Documents } from "./Documents";
import Select from "react-select";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Detail = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timezones, setTimezones] = useState([]);
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [isreferences, setreferences] = useState("");
  const [istitleincident, settitleincident] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");

  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");

  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  const [getincidentData, setgetincidentData] = useState([]);

  const [documentupload, setdocumentupload] = useState("document-tab");
  const [isusername, setusername] = useState("");
  const [isusernameall, setusernameall] = useState("");
  const [getallnotes, setgetallnotes] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Incident Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }
    if (userStr !== null) {
      console.log(userStr);
      var fset = userStr[0].first_name ? userStr[0].first_name.charAt(0) : "";
      var mset = userStr[0].middle_name ? userStr[0].middle_name.charAt(0) : "";
      var lset = userStr[0].last_name ? userStr[0].last_name.charAt(0) : "";
      var finalname = fset + mset + lset;
      setusername(finalname);
      var fsett = userStr[0].first_name;
      var msett = userStr[0].middle_name;
      var lsett = userStr[0].last_name;
      var fn = fsett + msett + lsett;
      setusernameall(fn);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australiaTimezones = moment.tz
      .names()
      .filter((zone) => zone.startsWith("Australia/"))
      .map((zone) => ({ value: zone, label: zone }));
    setTimezones(australiaTimezones);
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getincidentdatamiss", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          console.log("check");
          console.log(indata);
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setStateChecked(indata.state);
          setyesNoChecked(indata.incident_safe);
          setIscountryChecked(indata.au);
          if (indata.au === "nz") {
            setactivecounrty(false);
          }
          setreferences(indata.reference);
          setgetincidentData(indata);
        }
      })
      .catch((err) => {});
  }, [id]);
  console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty(event.value);
    }
  };
  const step3Country = (event) => {
    if (event !== "") {
      setisetcountystep3(event.value);
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };
  const backbuttonStep3 = (event) => {
    setactivetab1(false);
    setactivetab3(false);
    setactivetab2(true);
    setactiveTabMenu("injuredperson");
  };
  const backbuttonStep4 = (event) => {
    setactivetab4(false);
    setactivetab3(true);
    setactiveTabMenu("reporter");
  };
  const step2fullname = (e) => {
    console.log(persondetail);
    console.log(e.target.value);
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    console.log(e);
    console.log(e);
  };
  const handincident_safe = (event) => {
    setisincident_safeChecked(event);
    if (event === "Yes") {
      setisincidentafe(true);
    } else {
      setisincidentafe(false);
    }
  };
  const handwasfirst = (event) => {
    setiswasChecked(event);
    if (event === "Yes") {
      setwasfirst(true);
    } else {
      setwasfirst(false);
    }
  };
  const handwhereother = (event) => {
    setiswhereotherChecked(event);
    if (event === "Yes") {
      setwhereother(true);
    } else {
      setwhereother(false);
    }
  };
  const handwitness = (event) => {
    setiswitnessChecked(event);
    if (event === "Yes") {
      setwitness(true);
    } else {
      setwitness(false);
    }
  };
  const references = (event) => {
    setreferences(event.target.value);
  };
  const titleincident = (event) => {
    settitleincident(event.target.value);
  };
  const handbuttonStep1 = (event) => {
    if (isreferences !== "") {
      setactiveTabMenu("injuredperson");
      setactivetab1(false);
      setactivetab2(true);
      var vll = event.target;
      const userStr = JSON.parse(localStorage.getItem("users"));
      if (vll.injury_suffered.value === "on") {
        var inju = "Yes";
      } else {
        var inju = "No";
      }
      let formdata = {
        check_code: uniquecode,
        user_id: userStr[0].id,
        injury_suffered: inju,
        reference: vll.reference.value,
        title: vll.title.value,
        au: iscountryChecked,
        state: isStateChecked,
      };

      axios
        .post("https://jlmining.app/nearmiss_step1", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code !== "") {
            var cod = res.data.code;
            setuniquecode(cod);
            const newUrl = "/near-miss/reporter/" + cod;

            // Use history.push to navigate to the new URL
            navigate(newUrl);
          }
        })
        .catch((err) => {});
    }
  };

  const handdocument = (event) => {
    setdocumentupload(event);
  };
  const handincidentNotes = (event) => {
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      code: uniquecode,
      user_id: userStr[0].id,
      notes: vll.notes.value,
    };
    axios
      .post("https://jlmining.app/incident_notes", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              {/* <Documents /> */}
              <div className="d-block">
                <div className="row gy-3 mb-4">
                  <div className="col-12 hazard-form-block">
                    <ul className="brow-nav">
                      <li
                        className={` ${
                          activeTabMenu === "details" ? "active" : ""
                        }`}
                      >
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("details")}
                        >
                          Details
                        </Link>
                      </li>

                      <li
                        className={` ${
                          activeTabMenu === "reporter" ? "active" : ""
                        }`}
                      >
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("reporter")}
                        >
                          Reporter
                        </Link>
                      </li>
                      <li
                        className={` ${
                          activeTabMenu === "about" ? "active" : ""
                        }`}
                      >
                        <Link
                          disabled={isDisabledtomenu}
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("about")}
                        >
                          About
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <form
                action="javascript:void(0)"
                onSubmit={handbuttonStep1}
                method="post"
              >
                <div className="row gy-3">
                  <div className="col-12 hazard-form-block">
                    <div className="row gy-5">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <h1 className="h-title">Initial details</h1>
                          <p>
                            To be completed in the event of an incident, a major
                            non-conformance, unsafe practice or a near miss that
                            could have resulted in a serious injury or illness.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Was an injury suffered?</label>
                            <div className="d-flex gap-4 align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="injury_suffered"
                                  id="yes"
                                  checked={isyesNoChecked === "Yes"}
                                  onClick={() => handinjured_check("Yes")}
                                />
                                <label
                                  className="form-check-label rabutton"
                                  for="yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="injury_suffered"
                                  id="no"
                                  checked={isyesNoChecked === "No"}
                                  onClick={() => handinjured_check("No")}
                                />
                                <label
                                  className="form-check-label rabutton"
                                  for="no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Title
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              name="title"
                              defaultValue={getincidentData.title}
                              onInput={titleincident}
                              disabled={isDisabled}
                              placeholder=""
                            />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Reference
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              name="reference"
                              defaultValue={getincidentData.reference}
                              onInput={references}
                              disabled={isDisabled}
                              placeholder=""
                            />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Country
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-4 align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="au"
                                  id="au1"
                                  checked={iscountryChecked === "au"}
                                  disabled={isDisabled}
                                  onClick={() => handcountry("au")}
                                />
                                <label
                                  className="form-check-label rabutton"
                                  for="au1"
                                >
                                  AU
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="au"
                                  id="au2"
                                  checked={iscountryChecked === "nz"}
                                  onClick={() => handcountry("nz")}
                                  disabled={isDisabled}
                                />
                                <label
                                  className="form-check-label rabutton"
                                  for="au2"
                                >
                                  NZ
                                </label>
                              </div>
                            </div>
                          </div>
                          {activecounrty && (
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                State
                                <span className="text-danger">*</span>
                              </label>
                              <p className="text-secondary">
                                Please select the state or territory in which
                                the incident occurred. This will ensure the
                                correct guidance is provided
                              </p>
                              <div className="d-flex flex-wrap gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="act"
                                    required
                                    disabled={isDisabled}
                                    onClick={() => handstate("act")}
                                    checked={isStateChecked === "act"}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="act"
                                  >
                                    ACT
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="asw"
                                    required
                                    onClick={() => handstate("asw")}
                                    checked={isStateChecked === "asw"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="asw"
                                  >
                                    ASW
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="nt"
                                    required
                                    onClick={() => handstate("nt")}
                                    checked={isStateChecked === "nt"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="nt"
                                  >
                                    NT
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="qld"
                                    required
                                    onClick={() => handstate("qld")}
                                    checked={isStateChecked === "qld"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="qld"
                                  >
                                    QLD
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="sa"
                                    required
                                    onClick={() => handstate("sa")}
                                    checked={isStateChecked === "sa"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="sa"
                                  >
                                    SA
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="tas"
                                    required
                                    onClick={() => handstate("tas")}
                                    checked={isStateChecked === "tas"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="tas"
                                  >
                                    TAS
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="vic"
                                    required
                                    onClick={() => handstate("vic")}
                                    checked={isStateChecked === "vic"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="vic"
                                  >
                                    VIC
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="state"
                                    id="wa"
                                    required
                                    onClick={() => handstate("wa")}
                                    checked={isStateChecked === "wa"}
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="wa"
                                  >
                                    WA
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="d-flex gap-2 align-items-center buttons-two">
                            <Link to="/ohs" className="cel-btn">
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              disabled={isDisabled}
                              className="rep-btn"
                            >
                              Save and Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                {/* <Documentsnotes /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
export const Login = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useKeyboardShortcutBlocker();
  const [userInput, setUserInput] = useState("");
  const [recording, setRecording] = useState(null); // Stores the recording Blob
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null); // Ref for the audio element

  // Function to handle user input changes
  const handleChange = (event) => {
    setUserInput(event.target.value);
  };

  // Function to start recording audio
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(audioChunks, { type: "audio/webm" });
        setRecording(blob);
        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorder.start();
    } catch (error) {
      console.error("Error recording audio:", error);
    }
  };

  // Function to stop recording audio
  const stopRecording = () => {
    recording?.stop();
  };

  // Function to play the recorded audio
  const playRecording = async () => {
    if (!recording) {
      return; // No recording to play
    }

    const audioURL = URL.createObjectURL(recording);
    audioRef.current.src = audioURL;
    audioRef.current.play();
    setIsPlaying(true);

    // Handle cleanup when playback is finished or interrupted
    audioRef.current.addEventListener("ended", () => setIsPlaying(false));
  };

  // Function to stop playback
  const stopPlayback = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Reset playback position
    setIsPlaying(false);
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log("loginpage");
    console.log(userStr);
    document.title = "Login Page";
    if (userStr !== null) {
      console.log("cx");
      navigate("/home");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      password: vll.password.value,
    };
    axios
      .post("https://jlmining.app/login", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data);
        if (res.data.status == "3") {
          //console.log(res.data);
          let formdataa = {
            email: vll.email.value,
          };
          axios
            .post("https://jlmining.app/getuserdetailsEmail", formdataa, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.status;
              console.log(alldata[0].status);
              if (alldata[0].status === "Inactive") {
                localStorage.setItem("users", JSON.stringify(alldata));
                navigate("/onboarding");
              }
              if (alldata[0].status === "Active") {
                localStorage.setItem("users", JSON.stringify(res.data.status));
                setShowAlert(true);
                setShowAlert({ type: "success" });
                const items = JSON.parse(localStorage.getItem("users"));
                navigate("/home");
              }
            })
            .catch((err) => {});
        } else {
          if (res.data.status == "2") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
          } else {
            localStorage.setItem("users", JSON.stringify(res.data.status));
            setShowAlert(true);
            setShowAlert({ type: "success" });
            const items = JSON.parse(localStorage.getItem("users"));
            navigate("/home");
          }
        }

        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };

  return (
    <>
      <header style={{ position: "relative", left: "0", right: "0" }}>
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="/" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="/" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-9 col-md-4">
              <div className="navbar-list d-flex gap-3 justify-content-end">
                <Link to="/login" className="active">
                  Login
                </Link>
                <Link to="/register">Register</Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="fullscreen d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column gap-3">
          <div className="reg-box d-flex flex-column gap-3 px-3 px-md-5 pt-40 pb-60 w-300 w-md-600">
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex flex-column gap-2">
                <h3 className="fs-30 fw-bold text-center mytext-primary">
                  Login
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert type="error" description="Invalid detail!" closable />
                )}
                {showAlert?.type === "approve" && (
                  <Alert
                    type="error"
                    description="Your account is Inactive,Please wait..."
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Login successfully"
                    closable
                  />
                )}
                <form
                  action="javascript:void(0)"
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <div className="d-flex flex-column gap-3">
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Email-Id <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-envelope top-icon"></i>
                        <input
                          type="text"
                          name="email"
                          required
                          placeholder="Email-Id"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2 text-white">
                        Password <span className="text-danger fs-16">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-lock top-icon"></i>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>

                <div className="d-flex justify-content-center align-items-center my-3">
                  <div className="flex flex-column gap-4 w-md-300">
                    <h3 className="fs-18 fw-bold text-center mytext-primary">
                      <div className="d-flex justify-content-between gap-4">
                        <Link to="/register" className="mytext-primary">
                          Register
                        </Link>
                        <Link to="/forgotpassword" className="mytext-primary">
                          Forgot Password
                        </Link>
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="https://www.hssonline.com.au" target="_blank">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.hssptyltd.com.au" target="_blank">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.jlme.com.au" target="_blank">
                        www.jlme.com.au{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import Select from "react-select";
import { Alert } from "antd";
import { ReactDialogBox } from "react-js-dialog-box";
import { Header } from "./Header";
import { Footer } from "./Footer";
import CreatableSelect from "react-select/creatable";
import {
  FaTrash,
  FaStar,
  FaFilePdf,
  FaEye,
  FaMoon,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaThermometer,
  FaSun,
  FaEdit,
  FaUmbrella,
  FaSync,
  FaTrashAlt,
} from "react-icons/fa";
import "./assets/landing/fonts/all.min.css";
import "./assets/landing/main.css";
import "bootstrap/dist/js/bootstrap.js";
export const Profile = () => {
  const [textmessages, settextmessages] = useState(false);
  const [allcountry, setallcountry] = useState([]);
  const [Equipmentwork, setSelectedEquipmentwork] = useState([]);
  const passworderr = useRef();
  const emailerr = useRef();
  const [UserId, setUserId] = useState("");
  const [tab1, settab1] = useState("tab2");
  const [MentionLic, setSelectedMentionLic] = useState([]);
  const [MentionCertt, setMentionCertt] = useState([]);
  const [trademach, setSelectedTrade] = useState([]);
  const [machineryget, setSelectedmachinery] = useState([]);
  const [Vocationtra, setSelectedVocationtra] = useState([]);

  const [Previouswork, setSelectedprevwork] = useState([]);
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;
  const placeholderText = "Type here...";
  const [selectedtrade_file, setSelectedtrade_file] = useState(null);
  const [setselect_l_msg, setsetselect_l_msg] = useState("");
  const [selectedlicenec_File, setSelectedlicenec_File] = useState(null);
  const [setselect_m_msg, setsetselect_m_msg] = useState("");
  const [setselect_t_msg, setsetselect_t_msg] = useState("");
  const [selectedmachinery_file, setSelectedmachinery_file] = useState(null);
  const currentYear = new Date().getFullYear();
  const [valueget, setValueget] = useState({});
  const [selectcertmsg, setselect_certmsg] = useState("");
  const [selectedcertificate_file, setSelectedcertificate_file] =
    useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isActivec, setIsActivec] = useState(false);
  const [textmessage, settextmessage] = useState("");
  const [setVal, setValues] = useState({});
  const [clienttype, setClientType] = useState("");
  const [clientname, setClientName] = useState("");
  const [clientMinesite, setClientMinesite] = useState("");
  const [vsetval, setvalll] = useState([]);
  const [setoptionskillvalue, setoptionskill] = useState([]);
  const [valuesickleave, setValuesickleave] = useState({});
  const [valueannualleave, setValueannualleave] = useState({});
  const [valueworkerscomp, setValueworkerscomp] = useState({});
  const [valueflyinpm, setValueflyinpm] = useState({});
  const [valueflyoutam, setValueflyoutam] = useState({});
  const [valueflyinam, setValueflyinam] = useState({});
  const [valueinisolationonsite, setValueinisolationonsite] = useState({});
  const [valueleavewithoutpay, setValueleavewithoutpay] = useState({});
  const [valueflyoutpm, setValueflyoutpm] = useState({});
  const [valueworkoffsite, setValueworkoffsite] = useState({});
  const [valuenightshift, setValuenightshift] = useState({});
  const [valuegetUser, setValuegetuser] = useState("");
  const [valuegetClient, setValuegetclient] = useState([]);
  const [ValueDayoff, setValueDayoff] = useState({});
  const [valset, setval] = useState("");
  const [userdetail, setuserdetail] = useState("");
  const isActive = true;
  const [selectvalue, setselectvalue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const lastTwoDigits = currentYear % 100;
  const [indx, setindx] = useState(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  const [editprofile, seteditprofile] = useState(false);
  const [employmenteducation, setEmploymenteducation] = useState([
    {
      year: "",
      course: "",
      institution: "",
    },
  ]);
  const [employmentHistorySections, setEmploymentHistorySections] = useState([
    {
      start_date: "",
      end_date: "",
      company: "",
      role: "",
      company_done: "",
    },
  ]);

  const [selectedOptions_get, setSelectedOptions_get] = useState([]);
  const [selectvalue_mentionl, setselectvalue_mentionl] = useState([]);
  const [MentionLic_get, setMentionLic_get] = useState([]);
  const [licenseArray, setlicenseArray] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [MentionCertt_get, setMentionCertt_get] = useState([]);
  const [selectvalue_mentionc, setselectvalue_mentionc] = useState([]);
  const [trademach_get, setSelectedTrade_get] = useState([]);
  const [selectvalue_trade, setselectvalue_trade] = useState([]);
  const [tradetextArray, settradetextArray] = useState([]);
  const [Vocationtra_get, setSelectedVocationtra_get] = useState([]);
  const [selectvalue_vocational_training, setselectvalue_vocational_training] =
    useState([]);
  const [selectvalue_machinery, setselectvalue_machinery] = useState([]);
  const [machineryget_get, setSelectedmachinery_get] = useState([]);
  const [machinetextArrayy, setmachinetextArrayy] = useState([]);
  const [SelectedEquipmentwork_get, setSelectedEquipmentwork_get] = useState(
    []
  );
  const [selectvalue_equipment_work, setselectvalue_equipment_work] = useState(
    []
  );
  const [Previouswork_get, setSelectedprevwork_get] = useState([]);
  const [selectvalue_previous_work, setselectvalue_previous_work] = useState(
    []
  );
  const [references_get, setreferences_get] = useState([]);
  const [selectvalue_reference, setselectvalue_reference] = useState([]);

  //
  const [allrecord, setallrecord] = useState("");
  const [isemail, setemail] = useState("Email does not match");
  const [issstep2_title, setstep2_title] = useState("");
  const [isstep2_shirt_size, setstep2_shirt_size] = useState("");
  const [isstep2_postal_country, setisstep2_postal_country] = useState("");
  const [isstep2_postal_state, setisstep2_postal_state] = useState("");
  const [optioncountryfirst, setoptioncountryfirst] = useState("");
  const [optionstatefirst, setoptionstatefirst] = useState("");
  const [optionorigin, setoptionorigin] = useState("");
  const [genderOptions, setgenderOptions] = useState("");
  const [erroremail, seterroremail] = useState(false);
  const [errorpassword, seterrorpassword] = useState(false);
  const [step2_Postaladdress, setstep2_Postaladdress] = useState(false);
  const [auscitizenNo, setauscitizenNo] = useState(false);
  const [auscitizenYes, setauscitizenYes] = useState(false);

  const [auscitizenYesBirth, setauscitizenYesBirth] = useState(false);
  const [auscitizenYesPassport, setauscitizenYesPassport] = useState(false);
  const [auscitizenYesCert, setauscitizenYesCert] = useState(false);

  const [isbirthcheck, setisbirthcheck] = useState("");
  const [ispassportcheck, setispassportcheck] = useState("");
  const [iscertificatecheck, setiscertificatecheck] = useState("");

  const [islegalwork, setislegalwork] = useState("");
  const [islegalworkdesc, setislegalworkdesc] = useState(false);
  const [isresidentalAddress, setresidentalAddress] = useState("");
  const [isSelectedEmployeePhoto, setSelectedEmployeePhoto] = useState(null);
  const [isselectcountrybirth, setselectcountrybirth] = useState(null);
  const [isSelectedpermanentAddress, setSelectedpermanentAddress] =
    useState("");
  const [isSelectedPermanentpassport, setSelectedPermanentpassport] =
    useState(null);
  const [selectedProofs, setSelectedProofs] = useState([]);
  const [isBirthcertificate, setBirthcertificate] = useState(null);
  const [isPassportcertificate, setPassportcertificate] = useState(null);
  const [iscertificatefile, setcertificatefile] = useState(null);
  const [iscriminaloffensenses, setcriminaloffensenses] = useState("");
  const [isservedtime, setservedtime] = useState("");
  const [isdefenceforced, setdefenceforced] = useState("");
  const [isdayshift, setdayshift] = useState("");
  const [isnightshift, setnightshift] = useState("");
  const [selectedEmployetype, setselectedEmployetype] = useState([]);
  const [formValues, setFormValues] = useState({
    step2_title: allrecord.step2_title || "",
    first_name: allrecord.first_name || "",
    last_name: "",
    contact: "",
    step2_gender: "",
    step2_origin: "",
    email: "",
    step2_confirm_email: "",
    password: "",
    step2_confirm_password: "",
    address: "",
    step2_address: "",
    step2_city: "",
    step2_state: "",
    step2_Postal: "",
    step2_country: "",
    step2_postal_address: "",
    step2_postal_address2: "",
    step2_postal_city: "",
    step2_postal_state: "",
    step2_postal_code: "",
    step2_postal_country: "",
    step2_dob: "",
    step2_country_birth: "",
    step2_proof_upload: "",
    step2_available_date: "",
    step2_shirt_size: "",
  });
  const [contactstep2, setContactstep2] = useState("");
  const [invalidnumberstep2, setinvalidnumberstep2] = useState("");
  const [invalidnumberstep3, setinvalidnumberstep3] = useState("");
  const [isstep3_postal_state, setisstep3_postal_state] = useState("");
  const [optioncountrythird, setoptioncountrythird] = useState("");
  const [selectvalue_skill, setselectvalue_skill] = useState([]);
  const [MentionLCert_m, setSelectedMentionCert_m] = useState([]);
  const [MentionLCert, setSelectedMentionCert] = useState([]);
  const [formValues_step5, setFormValues_step5] = useState({
    years: "",
  });
  const [formValues_Step3, setFormValues_Step3] = useState({
    step3_title: "",
    step3_first_name: "",
    step3_last_name: "",
    step3_relationship: "",
    step3_contact: "",
    step3_mobile_number: "",
    step3_phone_number: "",
    step3_address: "",
    step3_address2: "",
    step3_city: "",
    step3_state: "",
    step3_postal: "",
    step3_country: "",
  });
  const [issstep3_title, setissstep3_title] = useState("");
  const [isstep3_contact, setisstep3_contact] = useState("");
  const [contactstep3, setContactstep3] = useState("");
  const [contactstep3_alter, setContactstep3_alter] = useState("");
  //Step2

  //Step 3
  const [invalidnumberstep3_alter, setinvalidnumberstep3_alter] = useState("");

  //Ste 3
  const apiurl = "https://jlmining.app/uploads/";
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Profile Page";
    if (userStr === null) {
      window.location.href = "#/login";
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    let formdataa = {
      user_id: 1,
    };
    axios
      .post("https://jlmining.app/getskills", formdataa, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);

        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    setindx(0);
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];
        setValuegetuser(alldata);
        console.log(alldata.skills);
        if (alldata.skills !== null) {
          setvalll(JSON.parse(alldata.skills));
          const inputString = JSON.parse(alldata.skills);

          const optionss = inputString.map((item) => ({
            value: item,
            label: item,
          }));

          setoptionskill(optionss);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getmentionlicence", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionLic(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getequipmentwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedEquipmentwork(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getmentioncertificate", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMentionCertt(res.data.results);
        setSelectedMentionCert(res.data.results);
      })
      .catch((err) => {});
    //Mention certificate

    //Previous Work
    axios
      .post("https://jlmining.app/getprevwork", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedprevwork(res.data.results);
      })
      .catch((err) => {});

    //Trade
    axios
      .post("https://jlmining.app/gettrade", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedTrade(res.data.results);
      })
      .catch((err) => {});
    //Machinery
    axios
      .post("https://jlmining.app/getmachinery", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedmachinery(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getvocationaltra", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedVocationtra(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        if (alldata !== "") {
          setuserdetail(alldata);
          if (alldata.education !== null) {
            setEmploymenteducation(JSON.parse(alldata.education));
          }
          if (alldata.employmentHistorySections !== null) {
            setEmploymentHistorySections(
              JSON.parse(alldata.employmentHistorySections)
            );
          }
          const inputString = JSON.parse(alldata.skills);
          const optionss = inputString.map((item) => ({
            value: item,
            label: item,
          }));
          setselectvalue(optionss);
          setSelectedOptions_get(optionss);
          if (alldata.licence !== null) {
            const mentionss = JSON.parse(alldata.licence);
            const options_m = mentionss.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_mentionl(options_m);
            setMentionLic_get(options_m);
          }
          if (alldata.licence_file !== null) {
            const resultArrays = JSON.parse(alldata.licence_file);
            setlicenseArray(resultArrays);
          }
          if (alldata.certificate_file !== null) {
            const edd = JSON.parse(alldata.certificate_file);
            setedfile(edd);
          }
          if (alldata.certificate !== null) {
            const edd = JSON.parse(alldata.certificate);
            const options_m = edd.map((item) => ({
              value: item,
              label: item,
            }));
            setMentionCertt_get(options_m);
            setselectvalue_mentionc(options_m);
          }
          if (alldata.trade !== null) {
            const sssvt = JSON.parse(alldata.trade);
            const options_m = sssvt.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedTrade_get(options_m);
            setselectvalue_trade(options_m);
          }
          if (alldata.trade_file !== null) {
            const sssvt = JSON.parse(alldata.trade_file);
            settradetextArray(sssvt);
          }
          if (alldata.vocational_training !== null) {
            const sssv = JSON.parse(alldata.vocational_training);
            const options_m = sssv.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedVocationtra_get(options_m);
            setselectvalue_vocational_training(options_m);
          }
          if (alldata.machinery !== null) {
            const sssvm = JSON.parse(alldata.machinery);

            const options_m = sssvm.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_machinery(options_m);
            setSelectedmachinery_get(options_m);
          }
          if (alldata.machinery_file !== null) {
            const eddm = JSON.parse(alldata.machinery_file);
            setmachinetextArrayy(eddm);
          }
          if (alldata.equipment_work !== null) {
            const eqp = JSON.parse(alldata.equipment_work);
            const options_m = eqp.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedEquipmentwork_get(options_m);
            setselectvalue_equipment_work(options_m);
          }
          if (alldata.previous_work !== null) {
            const eqp = JSON.parse(alldata.previous_work);
            const options_m = eqp.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedprevwork_get(options_m);
            setselectvalue_previous_work(options_m);
          }

          if (alldata.references !== null) {
            const eqp = JSON.parse(alldata.references);
            const options_m = eqp.map((item) => ({
              value: item,
              label: item,
            }));
            setreferences_get(options_m);
            setselectvalue_reference(options_m);
          }
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getclientFuser", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        if (alldata.length > 0) {
          let formdata = {
            user_id: userStr[0].id,
            client_id: alldata[0].client_id,
          };

          setClientType(alldata[0].type);
          setClientName(alldata[0].name);
          setClientMinesite(alldata[0].location_name);
          // console.log(alldata);
          //For Sick Leave
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailsickLeave",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValuesickleave(alldata);
            })
            .catch((err) => {});
          //For Sick Leave

          //For Annual Leave
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailannualLeave",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueannualleave(alldata);
            })
            .catch((err) => {});
          //For Annual Leave

          //For Works Comp
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailworkerscomp",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueworkerscomp(alldata);
            })
            .catch((err) => {});
          //For Works Comp

          //For Fly In Pm
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailflyinpm",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueflyinpm(alldata);
            })
            .catch((err) => {});
          //For Fly In Pm

          //For Fly Out Am
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailflyoutam",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              //console.log(alldata);
              setValueflyoutam(alldata);
            })
            .catch((err) => {});
          //For Fly Out Am

          //For Fly in Am
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailflyinam",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueflyinam(alldata);
            })
            .catch((err) => {});
          //For Fly in Am

          //For Fly In Isolation
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailinisolationonsite",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueinisolationonsite(alldata);
            })
            .catch((err) => {});
          //For Fly In Isolation

          //For Leave Without pay
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailleavewithoutpay",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueleavewithoutpay(alldata);
            })
            .catch((err) => {});
          //For Leave Without pay

          //For Fly out PM
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailflyoutpm",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueflyoutpm(alldata);
            })
            .catch((err) => {});
          //For Fly out PM

          //For Work of Site
          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailworkoffsite",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;
              setValueworkoffsite(alldata);
            })
            .catch((err) => {});
          //For Work of Site

          //Check day off
          axios
            .post("https://jlmining.app/admin/getuserdayoff", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.data;
              console.log("checkd");
              console.log(formdata);
              setValueDayoff(alldata);
            })
            .catch((err) => {});
          //Check day off

          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailForDay",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var alldata = res.data.data;

              setValueget(alldata);
            })
            .catch((err) => {});
          setValuegetclient(alldata);
          //For Night Shift

          axios
            .post(
              "https://jlmining.app/admin/getAttendancedetailForNight",
              formdata,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              var nightshift = res.data.data;
              //console.log(alldata);
              setValuenightshift(nightshift);
            })
            .catch((err) => {});
          //For Night Shift
        }
      })
      .catch((err) => {});

    axios
      .get("https://jlmining.app/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcountry(res.data.results);
      })
      .catch((err) => {});
  }, []);
  const closeBox = () => {
    seteditprofile(false);
  };
  const handlegetClientdata = (client_id, index) => {
    setindx(index);
    var client_id = client_id;
    //All Array
    setValueDayoff([]);
    setValuenightshift([]);
    setValueworkoffsite([]);
    setValueflyoutpm([]);
    setValueleavewithoutpay([]);
    setValueinisolationonsite([]);
    setValueflyinam([]);
    setValueflyoutam([]);
    setValueflyinpm([]);
    setValueworkerscomp([]);
    setValueannualleave([]);
    setValuesickleave([]);
    //All Array
    setTimeout(function () {
      const userStr = JSON.parse(localStorage.getItem("users"));
      let formdata = {
        user_id: userStr[0].id,
        client_id: client_id,
      };
      console.log(formdata);
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailForDay",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);

          setValueget(alldata);
        })
        .catch((err) => {});
      //get client
      axios
        .post("https://jlmining.app/admin/getclientroster", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row;
          console.log("sep");
          console.log(alldata);
          setClientType(alldata[0].type);
          setClientName(alldata[0].name);
          setClientMinesite(alldata[0].location_name);
        })
        .catch((err) => {});
      //get client

      //For Sick Leave
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailsickLeave",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValuesickleave(alldata);
        })
        .catch((err) => {});
      //For Sick Leave
      //For Annual Leave
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailannualLeave",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueannualleave(alldata);
        })
        .catch((err) => {});
      //For Annual Leave

      //For Works Comp
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailworkerscomp",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueworkerscomp(alldata);
        })
        .catch((err) => {});
      //For Works Comp

      //For Fly In Pm
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailflyinpm",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueflyinpm(alldata);
        })
        .catch((err) => {});
      //For Fly In Pm

      //For Fly Out Am
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailflyoutam",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          //console.log(alldata);
          setValueflyoutam(alldata);
        })
        .catch((err) => {});
      //For Fly Out Am

      //For Fly in Am
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailflyinam",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueflyinam(alldata);
        })
        .catch((err) => {});
      //For Fly in Am

      //For Fly In Isolation
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailinisolationonsite",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueinisolationonsite(alldata);
        })
        .catch((err) => {});
      //For Fly In Isolation

      //For Leave Without pay
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailleavewithoutpay",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueleavewithoutpay(alldata);
        })
        .catch((err) => {});
      //For Leave Without pay

      //For Fly out PM
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailflyoutpm",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueflyoutpm(alldata);
        })
        .catch((err) => {});
      //For Fly out PM

      //For Work of Site
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailworkoffsite",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.data;
          setValueworkoffsite(alldata);
        })
        .catch((err) => {});
      //For Work of Site
      setValueDayoff([]);

      //Check day off
      axios
        .post("https://jlmining.app/admin/getuserdayoff", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.data;
          setValueDayoff(alldata);
        })
        .catch((err) => {});
      //Check day off

      //For Night Shift
      axios
        .post(
          "https://jlmining.app/admin/getAttendancedetailForNight",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var nightshift = res.data.data;
          setValuenightshift(nightshift);
        })
        .catch((err) => {});
      //For Night Shift
    }, 500);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const handleLogout = () => {
    localStorage.removeItem("users");
    window.location.href = "/login";
  };
  const numbers = Array.from({ length: 31 }, (_, index) => index + 1);

  const isObjectEmpty = Object.keys(valuenightshift).length === 0;
  const isObjectEmptyday = Object.keys(valueget).length === 0;
  const isObjectEmptysickleave = Object.keys(valuesickleave).length === 0;
  const isObjectEmptydayoff = Object.keys(ValueDayoff).length === 0;

  const isObjectEmptyannualleave = Object.keys(valueannualleave).length === 0;
  const isObjectEmptyworkerscomp = Object.keys(valueworkerscomp).length === 0;
  const isObjectEmptyflyinpm = Object.keys(valueflyinpm).length === 0;
  const isObjectEmptyflyoutam = Object.keys(valueflyoutam).length === 0;
  const isObjectEmptyflyinam = Object.keys(valueflyinam).length === 0;
  const isObjectEmptyinisolationonsite =
    Object.keys(valueinisolationonsite).length === 0;
  const isObjectEmptyleavewithoutpay =
    Object.keys(valueleavewithoutpay).length === 0;
  const isObjectEmptyflyoutpm = Object.keys(valueflyoutpm).length === 0;
  const isObjectEmptyworkoffsite = Object.keys(valueworkoffsite).length === 0;

  const [values, setValuess] = useState({});
  const [vall, setVall] = useState("");

  const handlerole_update = (event) => {
    //return false;

    const userStr = JSON.parse(localStorage.getItem("users"));

    let formData = {
      role: event.target.role.value,
      user_id: userStr[0].id,
    };

    axios
      .post("https://jlmining.app/admin/userroleupdate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  const handleprofile_update = (event) => {
    console.log();
    event.preventDefault();

    const formData = new FormData();
    // Perform actions with the selected file, like uploading to a server
    console.log("Selected file:", selectedFile);

    const userStr = JSON.parse(localStorage.getItem("users"));
    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }
    if (selectvalue.length == "0") {
      if (setoptionskillvalue.length > 0) {
        for (let i = 0; i < setoptionskillvalue.length; i++) {
          console.log(setoptionskillvalue[i]);
          skidata.push(setoptionskillvalue[i].value);
        }
      }
    }

    formData.append("user_id", userStr[0].id); // Adding user_id field
    formData.append("file", selectedFile);
    formData.append("first_name", event.target.first_name.value);
    formData.append("profiledate", event.target.profiledate.value);
    formData.append("last_name", event.target.last_name.value);
    formData.append("middle_name", event.target.middle_name.value);
    formData.append("email", event.target.email.value);
    formData.append("contact", event.target.contact.value);
    formData.append("address", event.target.address.value);
    formData.append("skills", JSON.stringify(skidata));
    axios
      .post("https://jlmining.app/admin/userprofileupdate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        }
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  const profileupdate = (event) => {
    setIsModalOpen(true);
    const options = selectedOptions;
    // console.log(options);
    const handleSelectChange = (selectedOptions) => {
      console.log(selectedOptions);
      setselectvalue(selectedOptions);
      setselectvalue_skill(selectedOptions);
      setSelectedOptions(selectedOptions);
    };

    const handleRemoveOption = (removedOption) => {
      setSelectedOptions((prevSelected) =>
        prevSelected.filter((option) => option.value !== removedOption.value)
      );
    };

    const selectedOptionLabels = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );
  };
  const editrole = (event) => {
    setIsModalOpenRole(true);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  var imageUrl = `${apiurl}${valuegetUser.image}`;
  if (valuegetUser.image == null || valuegetUser.image == "") {
    var im = "male-upload.png";
    var imageUrl = `${apiurl}${im}`;
  } else {
    var imageUrl = `${apiurl}${valuegetUser.image}`;
  }
  const options = selectedOptions;

  const handleRemoveOption = (removedOption) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((option) => option.value !== removedOption.value)
    );
  };

  const selectedOptionLabels = selectedOptions.map(
    (selectedOption) => selectedOption.value
  );
  const fname = valuegetUser.first_name;
  const mname = valuegetUser.middle_name;
  const lname = valuegetUser.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";

  const handlelicense_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSize > maxSize) {
        setsetselect_l_msg(
          "The total size of selected files exceeds the maximum allowed size (5MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedlicenec_File(null);
      } else {
        setsetselect_l_msg("");
        setSelectedlicenec_File(selectedFiles);
      }
    }
  };
  const TradeM = trademach;
  const machinerygett = machineryget;
  const Vocationtraa = Vocationtra;
  const Equipmentworkk = Equipmentwork;
  const handleSelectChange_equipment_work = (selectedOptions) => {
    setselectvalue_equipment_work(selectedOptions);
  };
  const handleSelectChange_mentionl = (selectedOptions) => {
    setselectvalue_mentionl(selectedOptions);
    setMentionLic_get(selectedOptions);
  };
  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
    setselectvalue_skill(selectedOptions);
  };
  const handleSelectChange_trade = (selectedOptions) => {
    setselectvalue_trade(selectedOptions);
  };
  const handleSelectChange_vocational_training = (selectedOptions) => {
    setselectvalue_vocational_training(selectedOptions);
  };
  const handleSelectChange_machinery = (selectedOptions) => {
    setselectvalue_machinery(selectedOptions);
  };
  const handleInputChange_previous_work = (selectedOptions) => {
    setselectvalue_previous_work(selectedOptions);
  };
  const handleInputChange_reference = (selectedOptions) => {
    setselectvalue_reference(selectedOptions);
  };
  const handlemachinery_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB
      console.log(maxSize);

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_m_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedmachinery_file(null);
      } else {
        setsetselect_m_msg("");
        setSelectedmachinery_file(selectedFiles);
      }
    }
  };

  const handleInputChange_edication = (index, name, value) => {
    const updatedSections = [...employmenteducation];
    updatedSections[index][name] = value;
    setEmploymenteducation(updatedSections);
  };
  const trashdiveducation = (index) => {
    const updatedSections = [...employmenteducation];
    updatedSections.splice(index, 1);
    setEmploymenteducation(updatedSections);
  };
  const addMoreDivEducation = () => {
    setEmploymenteducation([
      ...employmenteducation,
      {
        year: "",
        course: "",
        institution: "",
      },
    ]);
  };

  const handleInputChange = (index, name, value) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections[index][name] = value;
    setEmploymentHistorySections(updatedSections);
  };

  const addMoreDiv = () => {
    setEmploymentHistorySections([
      ...employmentHistorySections,
      {
        start_date: "",
        end_date: "",
        company: "",
        role: "",
        company_done: "",
      },
    ]);
  };
  const trashdiv = (index) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections.splice(index, 1);
    setEmploymentHistorySections(updatedSections);
  };
  const edit_profile = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    setUserId(userStr[0].id);
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        if (alldata !== "") {
          setuserdetail(alldata);
          var ress = alldata;
          setallrecord(ress);
          setstep2_title(ress.step2_title);
          setisstep2_postal_state(ress.step2_postal_state);
          setisstep2_postal_country(ress.step2_postal_country);
          setgenderOptions(ress.step2_gender);
          setoptionorigin(ress.step2_origin);
          setoptionstatefirst(ress.step2_state);
          setoptioncountryfirst(ress.step2_country);
          setresidentalAddress(ress.step2_residential_address);
          if (ress.step2_residential_address === "No") {
            setstep2_Postaladdress(true);
          }
          if (ress.step2_residential_address === "Yes") {
            setstep2_Postaladdress(false);
          }

          setselectcountrybirth({
            value: ress.step2_country_birth,
            label: ress.step2_country_birth,
          });
          if (ress.step2_proof_upload) {
            const proofs = ress.step2_proof_upload
              .split(",")
              .map((item) => item.trim());
            const hasBirthCertificate = proofs.includes("Birth Certificate");
            const hasPassport = proofs.includes("Passport");
            const hasCitizenship = proofs.includes("Citizenship Certificate");
            if (hasBirthCertificate === true) {
              setauscitizenYesBirth(true);
            }
            if (hasPassport === true) {
              setauscitizenYesPassport(true);
            }
            if (hasCitizenship === true) {
              setauscitizenYesCert(true);
            }
            //console.log(hasBirthCertificate);
            setSelectedProofs(proofs);
          }
          setislegalwork(ress.step2_legal_work);
          if (ress.step2_legal_work === "No") {
            setislegalworkdesc(true);
          } else {
            setislegalworkdesc(false);
          }
          setContactstep2(ress.contact);
          setcriminaloffensenses(ress.step2_criminal_offenses);
          setservedtime(ress.step2_served_time);
          setdefenceforced(ress.step2_defence_forced);
          setstep2_shirt_size(ress.step2_shirt_size);
          setdayshift(ress.step2_which_dayshift);
          setnightshift(ress.step2_which_nightshift);
          if (ress.step2_employment_type) {
            const proofs = ress.step2_employment_type
              .split(",")
              .map((item) => item.trim());
            setselectedEmployetype(proofs);
          }
          setSelectedpermanentAddress(ress.step2_permanent_address);
          //console.log(ress.step2_permanent_address);
          if (ress.step2_permanent_address !== "") {
            // console.log("sss");
            if (ress.step2_permanent_address === "Yes") {
              setauscitizenYes(true);
              setauscitizenNo(false);
            }
            if (ress.step2_permanent_address === "No") {
              setauscitizenYes(false);
              setauscitizenNo(true);
            }
          }
          setSelectedEmployeePhoto(ress.image);
          setBirthcertificate(ress.step2_birthcertificate_file);
          setPassportcertificate(ress.step2_passportcertificate_file);
          setcertificatefile(ress.step2_auscitizencertificate_file);
          setSelectedPermanentpassport(ress.step2_passport);
          setFormValues({
            step2_title: ress.step2_title || "",
            first_name: ress.first_name || "",
            last_name: ress.last_name || "",
            contact: ress.contact || "",
            step2_gender: ress.step2_gender || "",
            email: ress.email || "",
            step2_confirm_email: ress.step2_confirm_email || "",
            password: ress.password || "",
            step2_confirm_password: ress.step2_confirm_password || "",
            address: ress.address || "",
            step2_address: ress.step2_address || "",
            step2_city: ress.step2_city || "",
            step2_Postal: ress.step2_Postal || "",
            step2_postal_address: ress.step2_postal_address || "",
            step2_postal_address2: ress.step2_postal_address2 || "",
            step2_postal_city: ress.step2_postal_city || "",
            step2_postal_code: ress.step2_postal_code || "",
            step2_dob: ress.step2_dob || "",
            step2_available_date: ress.step2_available_date || "",
          });
          setContactstep3(ress.step3_mobile_number);
          setContactstep3_alter(ress.step3_phone_number);
          setFormValues_Step3({
            step3_title: ress.step3_title || "",
            step3_first_name: ress.step3_first_name || "",
            step3_last_name: ress.step3_last_name || "",
            step3_relationship: ress.step3_relationship || "",

            step3_address: ress.step3_address || "",
            step3_address2: ress.step3_address2 || "",
            step3_city: ress.step3_city || "",

            step3_postal: ress.step3_postal || "",
            step3_country: ress.step3_country || "",
          });
          setFormValues_step5({
            years: ress.years || "",
          });
          if (ress.employmentHistorySections !== null) {
            setEmploymentHistorySections(
              JSON.parse(ress.employmentHistorySections)
            );
          }
          setissstep3_title(ress.step3_title);
          setisstep3_contact(ress.step3_contact);
          setisstep3_postal_state(ress.step3_state);
          setoptioncountrythird(ress.step3_country);

          if (ress.education !== null) {
            setEmploymenteducation(JSON.parse(ress.education));
          }
          if (ress.skills !== null) {
            const inputString = JSON.parse(ress.skills);
            const optionss = inputString.map((item) => ({
              value: item,
              label: item,
            }));

            setselectvalue_skill(optionss);
          }
          if (ress.licence !== null) {
            const mentionss = JSON.parse(ress.licence);
            const options_m = mentionss.map((item) => ({
              value: item,
              label: item,
            }));
            setMentionLic_get(options_m);
          }
          if (ress.certificate !== null) {
            const edd = JSON.parse(ress.certificate);
            const options_m = edd.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedMentionCert_m(options_m);
          }
          if (ress.trade !== null) {
            const sssvt = JSON.parse(ress.trade);
            const options_m = sssvt.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_trade(options_m);
          }
          if (ress.machinery !== null) {
            const sssvm = JSON.parse(ress.machinery);

            const options_m = sssvm.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_machinery(options_m);
          }
          if (ress.vocational_training !== null) {
            const sssv = JSON.parse(ress.vocational_training);
            const options_m = sssv.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_vocational_training(options_m);
          }
          if (ress.equipment_work !== null) {
            const eqp = JSON.parse(ress.equipment_work);
            const options_m = eqp.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_equipment_work(options_m);
          }
          if (ress.licence_file !== null) {
            const resultArrays = JSON.parse(ress.licence_file);
            setlicenseArray(resultArrays);
          }
          if (ress.certificate_file !== null) {
            const edd = JSON.parse(ress.certificate_file);
            setedfile(edd);
          }
          if (ress.trade_file !== null) {
            const sssvt = JSON.parse(ress.trade_file);
            settradetextArray(sssvt);
          }
          if (ress.machinery_file !== null) {
            const eddm = JSON.parse(ress.machinery_file);
            setmachinetextArrayy(eddm);
          }
          setuserdetail(ress);
          setUserId(ress.id);
        }
        seteditprofile(true);
      })
      .catch((err) => {});
  };
  const removemachine = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemachine", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.machinery_file != null) {
            const resultArrays = JSON.parse(alldata.machinery_file);
            setmachinetextArrayy(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removemention = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemention", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.licence_file !== null) {
            const resultArrays = JSON.parse(alldata.licence_file);
            setlicenseArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const handletrade_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_t_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedtrade_file(null);
      } else {
        setsetselect_t_msg("");
        setSelectedtrade_file(selectedFiles);
      }
    }
  };
  const removecertificate = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removecertificate", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.certificate_file != null) {
            const resultArrays = JSON.parse(alldata.certificate_file);
            setedfile(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removetrade = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removetrade", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.trade_file != null) {
            const resultArrays = JSON.parse(alldata.trade_file);
            settradetextArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const handleSelectChange_mentionc = (selectedOptions) => {
    setselectvalue_mentionc(selectedOptions);
    setSelectedMentionCert_m(selectedOptions);
  };
  const handlecertificate_File = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setselect_certmsg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedcertificate_file(null);
      } else {
        setselect_certmsg("");
        setSelectedcertificate_file(selectedFiles);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    const formData = new FormData();
    const formDataa = new FormData();

    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }

    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          //console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }
    //console.log(selectedcertificate_file);
    //return false;
    const selectvaluementionl = [];
    if (selectvalue_mentionl !== null) {
      if (selectvalue_mentionl.length > 0) {
        for (let i = 0; i < selectvalue_mentionl.length; i++) {
          selectvaluementionl.push(selectvalue_mentionl[i].value);
        }
      }
    }

    const selectvaluementionc = [];
    if (selectvalue_mentionc !== null) {
      if (selectvalue_mentionc.length > 0) {
        for (let i = 0; i < selectvalue_mentionc.length; i++) {
          selectvaluementionc.push(selectvalue_mentionc[i].value);
        }
      }
    }

    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }

    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }

    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }

    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }

    const selectvalue_ref = [];
    if (selectvalue_reference !== null) {
      if (selectvalue_reference.length > 0) {
        for (let i = 0; i < selectvalue_reference.length; i++) {
          selectvalue_ref.push(selectvalue_reference[i].value);
        }
      }
    }

    const selectvalue_previouswork = [];
    if (selectvalue_previous_work !== null) {
      if (selectvalue_previous_work.length > 0) {
        for (let i = 0; i < selectvalue_previous_work.length; i++) {
          selectvalue_previouswork.push(selectvalue_previous_work[i].value);
        }
      }
    }

    formData.append("first_name", vll.first_name.value);
    formData.append("UserId", UserId);
    formData.append("middle_name", vll.middle_name.value);
    formData.append("last_name", vll.last_name.value);
    formData.append("role", vll.role.value);
    formData.append("contact", vll.contact.value);
    formData.append("address", vll.address.value);
    formData.append("skills", skidata);
    formData.append("years", vll.years.value);
    formData.append("licence", selectvaluementionl);
    formData.append("certificate", selectvaluementionc);
    formData.append("trade", selectvaluetrade);

    formData.append("machinery", selectvaluemachinery);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("previous_work", selectvalue_previouswork);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );
    formData.append("references", selectvalue_ref);
    formData.append("education", JSON.stringify(employmenteducation));
    //console.log(selectedlicenec_File);

    setButtonDisabled(true);
    setIsActivec(true);
    settextmessage("Don't refresh the page.Please wait...");

    axios
      .post("https://jlmining.app/userUpdateprofile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data.status);

        if (res.data.status === "2") {
          //setShowAlert(true);
          //setShowAlert({ type: "error" });
        } else {
          formDataa.append("file", selectedFile);
          formDataa.append("UserId", UserId);
          formDataa.append("profiledate", event.target.profiledate.value);
          axios
            .post("https://jlmining.app/admin/userprofileupdate", formDataa, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {})
            .catch((err) => {});
          setTimeout(() => {
            setButtonDisabled(false);
            settextmessage("");
            setIsActivec(false);
            edit_profile(UserId);
            window.location.reload();
          }, 3500);
        }
      })
      .catch((err) => {});
  };
  //Edit profile

  const previousstep = (id) => {
    ///console.log(id);
    settab1(id);
  };
  const handlesubmitStep2 = (event) => {
    event.preventDefault();
    var vll = event.target;

    const formattedValue = formatNumber(contactstep2);

    if (isValidFormat(formattedValue)) {
      setContactstep2(formattedValue);
      setinvalidnumberstep2("");
    } else {
      setinvalidnumberstep2("Invalid formate");
      return false;
    }

    console.log("sd");
    settab1("tab3");
  };
  const residentalAddress = (event) => {
    setresidentalAddress(event);
    if (event === "Yes") {
      setstep2_Postaladdress(false);
    }
    if (event === "No") {
      setstep2_Postaladdress(true);
    }
  };
  const citizenaus = (event) => {
    setSelectedpermanentAddress(event);
    if (event === "Yes") {
      setauscitizenYes(true);
      setauscitizenNo(false);
    }
    if (event === "No") {
      setauscitizenYes(false);
      setauscitizenNo(true);
    }
  };
  const birthcheck = (event) => {
    var value = "Birth Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setisbirthcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesBirth(true);
    } else {
      setauscitizenYesBirth(false);
    }
  };
  const passportcheck = (event) => {
    var value = "Passport";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setispassportcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesPassport(true);
    } else {
      setauscitizenYesPassport(false);
    }
  };
  const certificatecheck = (event) => {
    var value = "Citizenship Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setiscertificatecheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesCert(true);
    } else {
      setauscitizenYesCert(false);
    }
  };
  const employetype = (event) => {
    var value = event;
    if (selectedEmployetype.includes(value)) {
      // Remove value if already selected
      setselectedEmployetype(
        selectedEmployetype.filter((item) => item !== value)
      );
    } else {
      // Add value if not selected
      setselectedEmployetype([...selectedEmployetype, value]);
    }
  };
  const legalwork = (event) => {
    setislegalwork(event.target.value);
    //console.log(event.target.checked);
    if (event.target.value === "Yes") {
      setislegalworkdesc(false);
    } else {
      setislegalworkdesc(true);
    }
  };
  const handleEmployeephoto = (event) => {
    setSelectedEmployeePhoto(event.target.files[0]);
  };
  const handlePermanentpassport = (event) => {
    setSelectedPermanentpassport(event.target.files[0]);
  };
  const handleSelectCountryBirth = (selectedOptions) => {
    //console.log(selectedOptions);
    setselectcountrybirth(selectedOptions);
  };
  const handlebirthcertificate = (event) => {
    setBirthcertificate(event.target.files[0]);
  };
  const handlepassportcertificate = (event) => {
    setPassportcertificate(event.target.files[0]);
  };
  const handlecertificatefile = (event) => {
    setcertificatefile(event.target.files[0]);
  };
  const criminaloffensenses = (event) => {
    setcriminaloffensenses(event);
  };
  const servedtime = (event) => {
    setservedtime(event);
  };
  const defenceforced = (event) => {
    setdefenceforced(event);
  };
  const dayshift = (event) => {
    setdayshift(event);
  };
  const nightshift = (event) => {
    setnightshift(event);
  };
  const handlegenderOptions = (event) => {
    //console.log(event.target.value);
    setgenderOptions(event.target.value);
  };
  const handleoptionorigin = (event) => {
    setoptionorigin(event.target.value);
  };
  const handleoptionstatefirst = (event) => {
    setoptionstatefirst(event.target.value);
  };
  const handleoptioncountryfirst = (event) => {
    setoptioncountryfirst(event.target.value);
  };
  const handlestep2_postal_state = (event) => {
    setisstep2_postal_state(event.target.value);
  };
  const handlestep2_postal_country = (event) => {
    setisstep2_postal_country(event.target.value);
  };
  const handlestep2_shirt_size = (event) => {
    setstep2_shirt_size(event.target.value);
  };
  const handlestep2_title = (event) => {
    setstep2_title(event.target.value);
  };

  const handleInputChangeStep2 = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const savelaterStep2 = () => {
    const formData = new FormData();

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );
    const userStr = JSON.parse(localStorage.getItem("users"));

    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("unique_code", userStr[0].id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/savelate_step2", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {})
        .catch((err) => {});
    }, 1500);
  };
  function formatDateForState(dateString) {
    if (!dateString) return ""; // Handle empty values

    // Create a new Date object from the string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.warn("Invalid date format provided:", dateString);
      return ""; // Handle invalid dates gracefully (optional)
    }
    // Format the date in YYYY-MM-DD using template literals
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
    var dd = `${month}/${day}/${year}`;
    //console.log(dd);
    return `${year}-${month}-${day}`;
  }
  const isImageValid = (image) => {
    return image !== null && image !== undefined && image !== "";
  };

  const formatNumber = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Apply formatting: #### ### ###
    const match = cleaned.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(" ");
    }
    return number;
  };

  const handleInputmobiletsep2 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      // Remove non-digit characters and limit the length to 12 digits
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep2(formattedValue);
      console.log(formattedValue);
      if (isValidFormat(cleanedValue)) {
        // Valid format logic (e.g., enable submit button)
        console.log("Valid format");
        setinvalidnumberstep2("");
      } else {
        // Invalid format logic (e.g., disable submit button)
        setinvalidnumberstep2("Invalid format");
        console.log("Invalid format");
      }
    }
  };

  const handleKeyDownstep2 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep2.length > 0) {
        setContactstep2((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const isValidFormat = (number) => {
    // Remove all non-digit characters and spaces
    const cleaned = number.replace(/\D/g, "");
    console.log(cleaned);
    // Check if the cleaned number matches the format #### ### ###
    return /^\d{4} ?\d{3} ?\d{3}$/.test(cleaned);
  };
  //Form Step

  //Form Step3
  const handlesubmitStep3 = (event) => {
    const formattedValue = formatNumber(contactstep3);

    if (isValidFormat(formattedValue)) {
      setContactstep3(formattedValue);
      setinvalidnumberstep3("");
    } else {
      setinvalidnumberstep3("Invalid formate");
      return false;
    }
    const formattedValue_alter = formatNumber(contactstep3_alter);

    if (isValidFormat(formattedValue_alter)) {
      setContactstep3(formattedValue_alter);
      setinvalidnumberstep3_alter("");
    } else {
      setinvalidnumberstep3_alter("Invalid formate");
      return false;
    }
    settab1("tab4");
  };
  const handlestep3_title = (event) => {
    setissstep3_title(event.target.value);
  };
  const handleInputChangeStep3 = (e) => {
    const { name, value } = e.target;
    setFormValues_Step3({
      ...formValues_Step3,
      [name]: value,
    });
  };
  const handlestep3_contact = (event) => {
    setisstep3_contact(event.target.value);
  };
  const handleKeyDownstep3 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3.length > 0) {
        setContactstep3((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const handleInputMobilephone_step3__alter = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3_alter(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3_alter("");
      } else {
        setinvalidnumberstep3_alter("Invalid format");
      }
    }
  };
  const handleKeyDownstep3_alter = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3_alter.length > 0) {
        setContactstep3_alter((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const handleInputMobilephone_step3 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3("");
      } else {
        setinvalidnumberstep3("Invalid format");
      }
    }
  };
  const handlestep3_postal_state = (event) => {
    setisstep3_postal_state(event.target.value);
  };
  const handleoptioncountryfirstthird = (event) => {
    setoptioncountrythird(event.target.value);
  };
  const handleSubmitstep4 = (event) => {
    settab1("tab5");
  };
  //Form Step3

  //Form Step Last

  const handlesubmitstep5 = (event) => {};
  const handleInputChangeStep5 = (e) => {
    const { name, value } = e.target;
    setFormValues_step5({
      ...formValues_step5,
      [name]: value,
    });
  };

  const handleFormFinalsubmit = () => {
    const formData = new FormData();
    setButtonDisabled(false);
    settextmessage(true);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );
    const skidata = [];
    if (selectvalue_skill.length > 0) {
      for (let i = 0; i < selectvalue_skill.length; i++) {
        skidata.push(selectvalue_skill[i].value);
      }
    }
    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }
    const selectvaluementionll = [];
    if (MentionLic_get !== null) {
      if (MentionLic_get.length > 0) {
        for (let i = 0; i < MentionLic_get.length; i++) {
          selectvaluementionll.push(MentionLic_get[i].value);
        }
      }
    }
    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }
    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    const selectvaluementionc = [];
    if (MentionLCert_m !== null) {
      if (MentionLCert_m.length > 0) {
        for (let i = 0; i < MentionLCert_m.length; i++) {
          selectvaluementionc.push(MentionLCert_m[i].value);
        }
      }
    }
    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }
    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }
    const userStr = JSON.parse(localStorage.getItem("users"));
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("certificate", selectvaluementionc);
    formData.append("education", JSON.stringify(employmenteducation));
    formData.append("trade", selectvaluetrade);
    formData.append("licence", selectvaluementionll);
    formData.append("machinery", selectvaluemachinery);
    formData.append("skills", skidata);
    formData.append("years", formValues_step5.years);
    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);
    formData.append("id", userStr[0].id);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/userFormUpdated", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code === "2") {
            settextmessages(false);

            setButtonDisabled(false);
            settab1("tab2");
            seterroremail(true);
            setemail("Email is already exists");
            setTimeout(() => {
              emailerr.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          } else {
            setemail("");
            settextmessages(true);

            setButtonDisabled(false);
            settextmessage(false);
            setTimeout(function () {
              settextmessages(false);
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {});
    }, 500);
  };
  //Form Step Last
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          margin-top:60px;
          z-index:99;

        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Profile</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-4 col-lg-3">
              <div className="d-flex flex-column gap-2 text-center pic-emp">
                <div className="emplo-box-pic ofit mx-auto">
                  <img src={imageUrl} alt="image" />
                </div>
                <div className="d-flex flex-column">
                  <Link
                    to="javascript:void(0)"
                    title="Upload Profile "
                    className="btn btn-danger w-50 m-auto mb-2"
                    onClick={() => edit_profile()}
                  >
                    Edit
                  </Link>
                  {editprofile && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        modalWidth="70%"
                        headerBackgroundColor="red"
                        headerTextColor="white"
                        closeButtonColor="white"
                        bodyBackgroundColor="white"
                        bodyTextColor="black"
                        headerText="Edit Profile"
                        style={{ marginTop: "50px" }}
                      >
                        <div
                          className="profile_mainyh"
                          style={{
                            height: "560px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            paddingTop: "58px",
                          }}
                        >
                          <div className="d-flex flex-column bg-white shadow  inperson mt-4 ">
                            <ul
                              className="nav nav-tabs"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  disabled
                                  className={`nav-link  ${
                                    tab1 === "tab2" ? "active " : ""
                                  } `}
                                  id="profile-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#profile-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="profile-tab-pane"
                                  aria-selected="false"
                                >
                                  <span>01</span>
                                  <span>.</span>
                                  <p className="d-flex">
                                    <span>Basic</span>
                                    <span>Details</span>
                                  </p>
                                </button>
                              </li>
                              <li className="nav-item " role="presentation">
                                <button
                                  disabled
                                  className={`nav-link  ${
                                    tab1 === "tab3" ? "active " : ""
                                  } `}
                                  id="contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#contact-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="contact-tab-pane"
                                  aria-selected="false"
                                >
                                  <span>02</span>
                                  <span>.</span>
                                  <p className="d-flex">
                                    <span>Emergency</span>
                                    <span>con..</span>
                                  </p>
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  disabled
                                  className={`nav-link  ${
                                    tab1 === "tab4" ? "active " : ""
                                  } `}
                                  id="work-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#work-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="work-tab-pane"
                                  aria-selected="false"
                                >
                                  <span>03</span>
                                  <span>.</span>
                                  <p className="d-flex">
                                    <span>Work</span>
                                    <span> History</span>
                                  </p>
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  disabled
                                  className={`nav-link  ${
                                    tab1 === "tab5" ? "active " : ""
                                  } `}
                                  id="qualifications-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#qualifications-tab-pane"
                                  type="button"
                                  role="tab"
                                  aria-controls="qualifications-tab-pane"
                                  aria-selected="false"
                                >
                                  <span>04</span>
                                  <span>.</span>
                                  <p class="d-flex">
                                    <span>Qualifications</span>
                                  </p>
                                </button>
                              </li>
                            </ul>
                            <div
                              className="tab-content py-5 profile-form"
                              id="myTabContent"
                            >
                              <div
                                className={`tab-pane fade  ${
                                  tab1 === "tab2" ? "show active " : ""
                                } `}
                                id="profile-tab-pane"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                                tabindex="0"
                              >
                                <div className="d-block text-center">
                                  <h4>Application Form</h4>
                                </div>
                                <form
                                  action="javascript:void(0)"
                                  onSubmit={handlesubmitStep2}
                                  method="post"
                                >
                                  <div className="row gy-3 align-items-end">
                                    <div className="col-md-3">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Name
                                          <span className="text-danger">*</span>
                                        </label>

                                        <select
                                          className="p-1"
                                          onChange={handlestep2_title}
                                          name="step2_title"
                                          value={issstep2_title}
                                          required
                                        >
                                          <option value="">Title</option>
                                          <option value="Ms">Ms</option>
                                          <option value="Miss">Miss</option>
                                          <option value="Mr">Mr</option>
                                          <option value="Mrs">Mrs</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column">
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          placeholder="First"
                                          type="text"
                                          id="name"
                                          defaultValue={allrecord.first_name}
                                          name="first_name"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="d-flex flex-column">
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          placeholder="Last"
                                          type="text"
                                          defaultValue={allrecord.last_name}
                                          id="name"
                                          name="last_name"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Mobile Number
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          onInput={handleInputmobiletsep2}
                                          onKeyDown={handleKeyDownstep2}
                                          className="px-2"
                                          type="text"
                                          name="contact"
                                          value={contactstep2}
                                          required
                                        />
                                        <span className="text-danger">
                                          {invalidnumberstep2}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="d-flex flex-column">
                                        <label>Gender</label>

                                        <select
                                          onChange={handlegenderOptions}
                                          className="p-1"
                                          name="step2_gender"
                                          id="step2_gender"
                                          value={genderOptions} // Bind value to state for controlled behavior
                                        >
                                          <option value="">
                                            Please select
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Non Binary">
                                            Non Binary
                                          </option>
                                          <option value="Others">Others</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Are you of Aboriginal or Torres Strait
                                          Island origin?
                                        </label>

                                        <select
                                          onChange={handleoptionorigin}
                                          className="p-1"
                                          name="step2_origin"
                                          id="step2_origin"
                                          value={optionorigin}
                                        >
                                          <option value="">
                                            Please select
                                          </option>
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                          <option value="Prefer Not To Say">
                                            Prefer Not To Say
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6" ref={emailerr}>
                                      <div className="d-flex flex-column">
                                        <label>
                                          Email
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="email"
                                          name="email"
                                          defaultValue={allrecord.email}
                                          required
                                        />
                                        {erroremail && (
                                          <span
                                            class="text-danger"
                                            style={{ marginTop: "5px" }}
                                          >
                                            {isemail}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Home Address:
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="text"
                                          defaultValue={allrecord.address}
                                          placeholder="Street Address"
                                          name="address"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="text"
                                          placeholder="Street Address Line 2"
                                          name="step2_address"
                                          defaultValue={allrecord.step2_address}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="text"
                                          defaultValue={allrecord.step2_city}
                                          placeholder="City"
                                          name="step2_city"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <select
                                          className="p-1"
                                          onChange={handleoptionstatefirst}
                                          name="step2_state"
                                          value={optionstatefirst}
                                        >
                                          <option value="">State</option>
                                          <option value="New South Wales">
                                            New South Wales
                                          </option>
                                          <option value="Northern Territory">
                                            Northern Territory
                                          </option>
                                          <option value="Queensland">
                                            Queensland
                                          </option>
                                          <option value="South Australia">
                                            South Australia
                                          </option>
                                          <option value="Tasmania">
                                            Tasmania
                                          </option>
                                          <option value="Victoria">
                                            Victoria
                                          </option>
                                          <option value="Western Australia">
                                            Western Australia
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="text"
                                          placeholder="Postal Code"
                                          name="step2_Postal"
                                          defaultValue={allrecord.step2_Postal}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <select
                                          className="p-1"
                                          onChange={handleoptioncountryfirst}
                                          name="step2_country"
                                          value={optioncountryfirst}
                                        >
                                          <option value="">Country</option>
                                          <option value="Australia">
                                            Australia
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Is your postal address the same as
                                          your residential address?
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              id="step2_residential_address1"
                                              name="step2_residential_address"
                                              value="Yes"
                                              checked={
                                                isresidentalAddress === "Yes"
                                              }
                                              onClick={() =>
                                                residentalAddress("Yes")
                                              }
                                              required
                                            />
                                            <label for="step2_residential_address1">
                                              Yes
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              id="step2_residential_address2"
                                              onClick={() =>
                                                residentalAddress("No")
                                              }
                                              name="step2_residential_address"
                                              value="No"
                                              checked={
                                                isresidentalAddress === "No"
                                              }
                                              required
                                            />
                                            <label for="step2_residential_address2">
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {step2_Postaladdress && (
                                      <div className="col-12">
                                        <div className="d-flex flex-column">
                                          <label>
                                            Postal Address:
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>

                                          <input
                                            className="px-2"
                                            type="text"
                                            onChange={handleInputChangeStep2}
                                            placeholder="Street Address"
                                            name="step2_postal_address"
                                            required
                                            defaultValue={
                                              allrecord.step2_postal_address
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {step2_Postaladdress && (
                                      <div className="col-12">
                                        <div className="d-flex flex-column">
                                          <input
                                            className="px-2"
                                            type="text"
                                            onChange={handleInputChangeStep2}
                                            placeholder="Street Address Line 2"
                                            name="step2_postal_address2"
                                            defaultValue={
                                              allrecord.step2_postal_address2
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {step2_Postaladdress && (
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <input
                                            className="px-2"
                                            type="text"
                                            onChange={handleInputChangeStep2}
                                            placeholder="City"
                                            name="step2_postal_city"
                                            defaultValue={
                                              allrecord.step2_postal_city
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {step2_Postaladdress && (
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <select
                                            className="p-1"
                                            value={isstep2_postal_state}
                                            onChange={handlestep2_postal_state}
                                            name="step2_postal_state"
                                          >
                                            <option value="">State</option>
                                            <option value="New South Wales">
                                              New South Wales
                                            </option>
                                            <option value="Northern Territory">
                                              Northern Territory
                                            </option>
                                            <option value="Queensland">
                                              Queensland
                                            </option>
                                            <option value="South Australia">
                                              South Australia
                                            </option>
                                            <option value="Tasmania">
                                              Tasmania
                                            </option>
                                            <option value="Victoria">
                                              Victoria
                                            </option>
                                            <option value="Western Australia">
                                              Western Australia
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    )}
                                    {step2_Postaladdress && (
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <input
                                            onChange={handleInputChangeStep2}
                                            className="px-2"
                                            type="text"
                                            placeholder="Postal Code"
                                            defaultValue={
                                              allrecord.step2_postal_code
                                            }
                                            name="step2_postal_code"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {step2_Postaladdress && (
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <select
                                            className="p-1"
                                            value={isstep2_postal_country}
                                            onChange={
                                              handlestep2_postal_country
                                            }
                                            name="step2_postal_country"
                                          >
                                            <option value="">Country</option>
                                            <option value="Australia">
                                              Australia
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Employee Photo
                                          <span className="text-danger">*</span>
                                        </label>
                                        <p className="photo-drag">
                                          <i>
                                            Please upload an employee photo,
                                            this photo must be taken within 30
                                            days and be of Passport standard –
                                            white background, from the shoulders
                                            upwards
                                          </i>
                                        </p>
                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                            <input
                                              className="chose-file"
                                              type="file"
                                              name="image"
                                              onChange={handleEmployeephoto}
                                              required={
                                                allrecord.image === null ||
                                                allrecord.image === ""
                                              }
                                            />
                                            <button className="chose-btn">
                                              {allrecord.image === null ||
                                              allrecord.image === ""
                                                ? "Choose files or drag here"
                                                : // Render something else when allrecord.image is not empty
                                                  allrecord.image}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-8"></div>
                                    <div className="col-md-5">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Date of Birth{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          onChange={handleInputChangeStep2}
                                          className="px-2"
                                          type="date"
                                          name="step2_dob"
                                          required
                                          defaultValue={formatDateForState(
                                            allrecord.step2_dob
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Country of Birth
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          onChange={handleSelectCountryBirth}
                                          placeholder="Select a country"
                                          options={allcountry.map(
                                            (country) => ({
                                              value: country.country_name,
                                              label: country.country_name,
                                            })
                                          )}
                                          value={isselectcountrybirth} // Example: setting the default value to the first country
                                          name="step2_country_birth"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Are you a permanent Resident/Citizen
                                          of Australia?
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_permanent_address"
                                              required
                                              onClick={() => citizenaus("Yes")}
                                              checked={
                                                isSelectedpermanentAddress ===
                                                "Yes"
                                              }
                                              value="Yes"
                                            />
                                            <label>Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              onClick={() => citizenaus("No")}
                                              name="step2_permanent_address"
                                              required
                                              checked={
                                                isSelectedpermanentAddress ===
                                                "No"
                                              }
                                              value="No"
                                            />
                                            <label>No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {auscitizenNo && (
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column gap-2">
                                          <label>
                                            Passport
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>

                                          <div className="d-flex align-items-center gap-3">
                                            <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                              <input
                                                className="chose-file"
                                                type="file"
                                                name="step2_passport"
                                                onChange={
                                                  handlePermanentpassport
                                                }
                                                required={
                                                  allrecord.step2_passport ===
                                                    null ||
                                                  allrecord.step2_passport ===
                                                    undefined ||
                                                  allrecord.step2_passport ===
                                                    ""
                                                }
                                              />
                                              <button className="chose-btn">
                                                {allrecord.step2_passport ===
                                                  "" ||
                                                allrecord.step2_passport ===
                                                  null ||
                                                allrecord.step2_passport ===
                                                  undefined
                                                  ? "Choose files or drag here"
                                                  : // Render something else when allrecord.image is not empty
                                                    allrecord.step2_passport}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {auscitizenYes && (
                                      <div className="col-md-12">
                                        <div className="d-flex flex-column gap-2">
                                          <label>
                                            Please select proof to upload
                                          </label>

                                          <div className="d-flex align-items-center gap-3">
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                name="step2_proof_upload"
                                                onChange={birthcheck}
                                                checked={selectedProofs.includes(
                                                  "Birth Certificate"
                                                )}
                                                value="Birth Certificate"
                                              />
                                              <label>Birth Certificate</label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                name="step2_proof_upload"
                                                value="Passport"
                                                checked={selectedProofs.includes(
                                                  "Passport"
                                                )}
                                                onChange={passportcheck}
                                              />
                                              <label>Passport</label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                name="step2_proof_upload"
                                                onChange={certificatecheck}
                                                checked={selectedProofs.includes(
                                                  "Citizenship Certificate"
                                                )}
                                                value="Citizenship Certificate"
                                              />

                                              <label>
                                                Citizenship Certificate
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {auscitizenYes && (
                                      <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                          {auscitizenYesBirth && (
                                            <div className="d-flex flex-column gap-2">
                                              <label>
                                                Birth Certificate
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>

                                              <div className="d-flex align-items-center gap-3">
                                                <div className="d-flex align-items-center gap-2 profile-chosie w-100 position-relative">
                                                  <input
                                                    className="chose-file"
                                                    type="file"
                                                    name="step2_birthcertificate_file"
                                                    required={
                                                      allrecord.step2_birthcertificate_file ===
                                                        null ||
                                                      allrecord.step2_birthcertificate_file ===
                                                        undefined ||
                                                      allrecord.step2_birthcertificate_file ===
                                                        ""
                                                    }
                                                    onChange={
                                                      handlebirthcertificate
                                                    }
                                                  />
                                                  <button className="chose-btn">
                                                    {allrecord.step2_birthcertificate_file ===
                                                      "null" ||
                                                    allrecord.step2_birthcertificate_file ===
                                                      undefined ||
                                                    allrecord.step2_birthcertificate_file ===
                                                      ""
                                                      ? "Choose files or drag here"
                                                      : // Render something else when allrecord.image is not empty
                                                        allrecord.step2_birthcertificate_file}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {auscitizenYesPassport && (
                                            <div
                                              style={{
                                                width: "200px",
                                              }}
                                              className="d-flex flex-column gap-2"
                                            >
                                              <label>
                                                Passport
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>

                                              <div className="d-flex align-items-center gap-3">
                                                <div className="d-flex align-items-center  profile-chosie w-100 gap-2 position-relative">
                                                  <input
                                                    className="chose-file"
                                                    type="file"
                                                    onChange={
                                                      handlepassportcertificate
                                                    }
                                                    name="step2_passportcertificate_file"
                                                    required={
                                                      allrecord.step2_passportcertificate_file ===
                                                        null ||
                                                      allrecord.step2_passportcertificate_file ===
                                                        undefined ||
                                                      allrecord.step2_passportcertificate_file ===
                                                        ""
                                                    }
                                                  />
                                                  <button className="chose-btn">
                                                    {allrecord.step2_passportcertificate_file ===
                                                      "null" ||
                                                    allrecord.step2_passportcertificate_file ===
                                                      undefined ||
                                                    allrecord.step2_passportcertificate_file ===
                                                      ""
                                                      ? "Choose files or drag here"
                                                      : // Render something else when allrecord.image is not empty
                                                        allrecord.step2_passportcertificate_file}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {auscitizenYesCert && (
                                            <div
                                              style={{
                                                width: "200px",
                                              }}
                                              className="d-flex flex-column gap-2"
                                            >
                                              <label>
                                                Australian Citizenship
                                                Certificate Upload
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>

                                              <div className="d-flex align-items-center gap-3">
                                                <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                                  <input
                                                    className="chose-file"
                                                    onChange={
                                                      handlecertificatefile
                                                    }
                                                    type="file"
                                                    name="step2_auscitizencertificate_file"
                                                    required={
                                                      allrecord.step2_auscitizencertificate_file ===
                                                        "null" ||
                                                      allrecord.step2_auscitizencertificate_file ===
                                                        "undefined" ||
                                                      allrecord.step2_auscitizencertificate_file ===
                                                        ""
                                                    }
                                                  />
                                                  <button className="chose-btn">
                                                    {allrecord.step2_auscitizencertificate_file ===
                                                      "null" &&
                                                    allrecord.step2_auscitizencertificate_file ===
                                                      undefined &&
                                                    allrecord.step2_auscitizencertificate_file ===
                                                      ""
                                                      ? "Choose files or drag here"
                                                      : // Render something else when allrecord.image is not empty
                                                        allrecord.step2_auscitizencertificate_file}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Do you have the legal right to live
                                          and work in Australia?
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              id="y1"
                                              name="step2_legal_work"
                                              onChange={legalwork}
                                              checked={islegalwork === "Yes"}
                                              value="Yes"
                                            />
                                            <label for="y1">Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_legal_work"
                                              onChange={legalwork}
                                              checked={islegalwork === "No"}
                                              value="No"
                                              id="n1"
                                            />
                                            <label for="n1">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {islegalworkdesc && (
                                      <div className="col-md-9">
                                        <div className="d-flex flex-column gap-2">
                                          <span className="text-white bgred">
                                            If you do not have the right to work
                                            in Australia, please do not proceed
                                            with this application.
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <label>
                                          What date do you become available?
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          className="px-2"
                                          type="date"
                                          onChange={handleInputChangeStep2}
                                          name="step2_available_date"
                                          required
                                          defaultValue={formatDateForState(
                                            allrecord.step2_available_date
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Have you ever been convicted of any
                                          criminal offenses?
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_criminal_offenses"
                                              onChange={() =>
                                                criminaloffensenses("Yes")
                                              }
                                              checked={
                                                iscriminaloffensenses === "Yes"
                                              }
                                              value="Yes"
                                              id="yy"
                                            />
                                            <label for="yy">Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_criminal_offenses"
                                              value="No"
                                              onChange={() =>
                                                criminaloffensenses("No")
                                              }
                                              checked={
                                                iscriminaloffensenses === "No"
                                              }
                                              id="yy1"
                                            />
                                            <label for="yy1">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Have you served time in prison?
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_served_time"
                                              value="Yes"
                                              onChange={() => servedtime("Yes")}
                                              checked={isservedtime === "Yes"}
                                              id="yy_1"
                                            />
                                            <label for="yy_1">Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_served_time"
                                              value="No"
                                              onChange={() => servedtime("No")}
                                              checked={isservedtime === "No"}
                                              id="yu"
                                            />
                                            <label for="yu">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Are you an ex serving member of the
                                          Australian Defence Force?
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_defence_forced"
                                              value="Yes"
                                              id="uu"
                                              onChange={() =>
                                                defenceforced("Yes")
                                              }
                                              checked={
                                                isdefenceforced === "Yes"
                                              }
                                            />
                                            <label for="uu">Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              name="step2_defence_forced"
                                              value="No"
                                              id="u_u"
                                              onChange={() =>
                                                defenceforced("No")
                                              }
                                              checked={isdefenceforced === "No"}
                                            />
                                            <label for="u_u">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Shirt Size
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="p-1"
                                          onChange={handlestep2_shirt_size}
                                          name="step2_shirt_size"
                                          value={isstep2_shirt_size}
                                          required
                                        >
                                          <option value="">
                                            Please select
                                          </option>
                                          <option
                                            value="Shirt Size S"
                                            data-index="0"
                                          >
                                            Shirt Size S
                                          </option>
                                          <option
                                            value="Shirt Size M"
                                            data-index="1"
                                          >
                                            Shirt Size M
                                          </option>
                                          <option
                                            value="Shirt Size L"
                                            data-index="2"
                                          >
                                            Shirt Size L
                                          </option>
                                          <option
                                            value="Shirt Size XL"
                                            data-index="3"
                                          >
                                            Shirt Size XL
                                          </option>
                                          <option
                                            value="Shirt Size XXL"
                                            data-index="4"
                                          >
                                            Shirt Size XXL
                                          </option>
                                          <option
                                            value="Shirt Size XXXL"
                                            data-index="5"
                                          >
                                            Shirt Size XXXL
                                          </option>
                                          <option
                                            value="Shirt Size XXXXL"
                                            data-index="6"
                                          >
                                            Shirt Size XXXXL
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Which shifts are you willing to work?
                                          Please select all that apply
                                        </label>
                                        <div className="d-flex gap-5">
                                          <div className="d-flex flex-column align-items-start gap-2">
                                            <label>
                                              Day Shift
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="d-flex align-items-center gap-3">
                                              <div className="d-flex align-items-center gap-2">
                                                <input
                                                  type="radio"
                                                  name="step2_which_dayshift"
                                                  value="Yes"
                                                  onChange={() =>
                                                    dayshift("Yes")
                                                  }
                                                  checked={isdayshift === "Yes"}
                                                />
                                                <label>Yes</label>
                                              </div>
                                              <div className="d-flex align-items-center gap-2">
                                                <input
                                                  type="radio"
                                                  name="step2_which_dayshift"
                                                  value="No"
                                                  onChange={() =>
                                                    dayshift("No")
                                                  }
                                                  checked={isdayshift === "No"}
                                                />
                                                <label>No</label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex flex-column align-items-start gap-2">
                                            <label>
                                              Night Shift
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="d-flex align-items-center gap-3">
                                              <div className="d-flex align-items-center gap-2">
                                                <input
                                                  type="radio"
                                                  name="step2_which_nightshift"
                                                  value="Yes"
                                                  onChange={() =>
                                                    nightshift("Yes")
                                                  }
                                                  checked={
                                                    isnightshift === "Yes"
                                                  }
                                                />
                                                <label>Yes</label>
                                              </div>
                                              <div className="d-flex align-items-center gap-2">
                                                <input
                                                  type="radio"
                                                  name="step2_which_nightshift"
                                                  value="No"
                                                  onChange={() =>
                                                    nightshift("No")
                                                  }
                                                  checked={
                                                    isnightshift === "No"
                                                  }
                                                />
                                                <label>No</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Employment Type - Please select all
                                          that apply
                                        </label>
                                        <div className="d-flex flex-column align-items-start  gap-2">
                                          <div className="d-flex flex-md-row flex-column gy-3 align-items-start  w-100 justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                id="fifo"
                                                name="step2_employment_type"
                                                checked={selectedEmployetype.includes(
                                                  "FIFO"
                                                )}
                                                onChange={() =>
                                                  employetype("FIFO")
                                                }
                                              />
                                              <label for="fifo">FIFO</label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                id="Shutdowns"
                                                name="step2_employment_type"
                                                value="Shutdowns"
                                                checked={selectedEmployetype.includes(
                                                  "Shutdowns"
                                                )}
                                                onChange={() =>
                                                  employetype("Shutdowns")
                                                }
                                              />
                                              <label for="Shutdowns">
                                                Shutdowns
                                              </label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                value="Local Work"
                                                id="Local"
                                                name="step2_employment_type"
                                                checked={selectedEmployetype.includes(
                                                  "Local Work"
                                                )}
                                                onChange={() =>
                                                  employetype("Local Work")
                                                }
                                              />
                                              <label for="Local">
                                                Local Work
                                              </label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                name="step2_employment_type"
                                                value="Casual"
                                                id="Casual"
                                                checked={selectedEmployetype.includes(
                                                  "Casual"
                                                )}
                                                onChange={() =>
                                                  employetype("Casual")
                                                }
                                              />
                                              <label for="Casual">Casual</label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                name="step2_employment_type"
                                                value="Full Time"
                                                id="Full"
                                                checked={selectedEmployetype.includes(
                                                  "Full Time"
                                                )}
                                                onChange={() =>
                                                  employetype("Full Time")
                                                }
                                              />
                                              <label for="Full">
                                                Full Time
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                        <button
                                          className="save-btn"
                                          type="button"
                                          onClick={() => previousstep("tab1")}
                                        >
                                          Previous
                                        </button>

                                        <button
                                          className="next-btn"
                                          type="submit"
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div
                                className={`tab-pane fade  ${
                                  tab1 === "tab3" ? "show active " : ""
                                } `}
                                id="contact-tab-pane"
                                role="tabpanel"
                                aria-labelledby="contact-tab"
                                tabindex="0"
                              >
                                <div className="d-block text-center">
                                  <h4>Application Form</h4>
                                </div>
                                <div className="d-block py-3">
                                  <h3>Emergency Contact Details</h3>
                                </div>
                                <form
                                  action="javascript:void(0)"
                                  method="post"
                                  onSubmit={handlesubmitStep3}
                                >
                                  <div className="row gy-3 align-items-end">
                                    <div className="col-md-3">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Name
                                          <span className="text-danger">*</span>
                                        </label>

                                        <select
                                          className="p-1"
                                          name="step3_title"
                                          id="step3_title"
                                          onChange={handlestep3_title}
                                          value={issstep3_title}
                                          required
                                        >
                                          <option value="">Title</option>
                                          <option value="Ms">Ms</option>
                                          <option value="Miss">Miss</option>
                                          <option value="Mr">Mr</option>
                                          <option value="Mrs">Mrs</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          placeholder="First"
                                          type="text"
                                          id="name"
                                          name="step3_first_name"
                                          defaultValue={
                                            allrecord.step3_first_name
                                          }
                                          onChange={handleInputChangeStep3}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          placeholder="Last"
                                          type="text"
                                          defaultValue={
                                            allrecord.step3_last_name
                                          }
                                          onChange={handleInputChangeStep3}
                                          name="step3_last_name"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Relationship to you (eg. spouse,
                                          parent, friend)
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          className="px-2"
                                          required
                                          defaultValue={
                                            allrecord.step3_relationship
                                          }
                                          type="text"
                                          onChange={handleInputChangeStep3}
                                          name="step3_relationship"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Is your Emergency Contact located in
                                          Australia?
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              onChange={handlestep3_contact}
                                              value="Yes"
                                              checked={
                                                isstep3_contact === "Yes"
                                              }
                                              name="step3_contact"
                                              required
                                            />
                                            <label>Yes</label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="radio"
                                              onChange={handlestep3_contact}
                                              value="No"
                                              checked={isstep3_contact === "No"}
                                              name="step3_contact"
                                              required
                                            />
                                            <label>No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Mobile Phone Number
                                          <span className="text-danger">*</span>
                                        </label>
                                        <span className="dark-text">
                                          eg 0422 222 222
                                        </span>
                                        <input
                                          className="px-2"
                                          type="text"
                                          max="12"
                                          value={contactstep3}
                                          onKeyDown={handleKeyDownstep3}
                                          onChange={
                                            handleInputMobilephone_step3
                                          }
                                          required
                                          name="step3_mobile_number"
                                        />
                                        <span className="text-danger">
                                          {invalidnumberstep3}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="d-flex flex-column gap-2">
                                        <label>
                                          Alternative Phone Number
                                          <span className="text-danger">*</span>
                                        </label>
                                        <span className="dark-text">
                                          eg 0422 222 222
                                        </span>
                                        <input
                                          className="px-2"
                                          type="text"
                                          max="12"
                                          value={contactstep3_alter}
                                          onKeyDown={handleKeyDownstep3_alter}
                                          onChange={
                                            handleInputMobilephone_step3__alter
                                          }
                                          name="step3_phone_number"
                                          required
                                        />
                                        <span className="text-danger">
                                          {invalidnumberstep3_alter}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Address
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          className="px-2"
                                          onChange={handleInputChangeStep3}
                                          type="text"
                                          required
                                          defaultValue={allrecord.step3_address}
                                          placeholder="Street Address"
                                          name="step3_address"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          onChange={handleInputChangeStep3}
                                          type="text"
                                          defaultValue={
                                            allrecord.step3_address2
                                          }
                                          placeholder="Street Address Line 2"
                                          name="step3_address2"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          onChange={handleInputChangeStep3}
                                          type="text"
                                          defaultValue={allrecord.step3_city}
                                          placeholder="City"
                                          name="step3_city"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <select
                                          className="p-1"
                                          name="step3_state"
                                          value={isstep3_postal_state}
                                          onChange={handlestep3_postal_state}
                                        >
                                          <option value="">State</option>
                                          <option value="New South Wales">
                                            New South Wales
                                          </option>
                                          <option value="Northern Territory">
                                            Northern Territory
                                          </option>
                                          <option value="Queensland">
                                            Queensland
                                          </option>
                                          <option value="South Australia">
                                            South Australia
                                          </option>
                                          <option value="Tasmania">
                                            Tasmania
                                          </option>
                                          <option value="Victoria">
                                            Victoria
                                          </option>
                                          <option value="Western Australia">
                                            Western Australia
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          type="text"
                                          onChange={handleInputChangeStep3}
                                          defaultValue={allrecord.step3_postal}
                                          placeholder="Postal Code"
                                          name="step3_postal"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <select
                                          className="p-1"
                                          onChange={
                                            handleoptioncountryfirstthird
                                          }
                                          value={optioncountrythird}
                                          name="step3_country"
                                        >
                                          <option value="Country">
                                            Country
                                          </option>
                                          <option value="Australia">
                                            Australia
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                        <button
                                          className="save-btn"
                                          onClick={() => previousstep("tab2")}
                                          type="button"
                                        >
                                          Previous
                                        </button>

                                        <button
                                          className="next-btn"
                                          type="submit"
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div
                                className={`tab-pane fade  ${
                                  tab1 === "tab4" ? "show active " : ""
                                } `}
                                id="work-tab-pane"
                                role="tabpanel"
                                aria-labelledby="work-tab"
                                tabindex="0"
                              >
                                <div className="d-block text-center">
                                  <h4>Application Form</h4>
                                </div>
                                <div className="d-block py-3">
                                  <h3>Employement History</h3>
                                </div>
                                <form
                                  action="javascript:void(0)"
                                  method="post"
                                  onSubmit={handleSubmitstep4}
                                >
                                  <div className="d-block">
                                    {employmentHistorySections.map(
                                      (section, index) => (
                                        <div
                                          className="row mt-2 gy-3 align-items-end"
                                          key={index}
                                        >
                                          {index !== 0 && (
                                            <div className="d-flex align-items-center justify-content-end">
                                              <Link
                                                to="javascript:void(0)"
                                                className="text-white bg-danger py-2 px-3 rounded-1 float-end"
                                                onClick={() => trashdiv(index)}
                                              >
                                                Remove
                                              </Link>
                                            </div>
                                          )}
                                          <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                              <label>Start date </label>
                                              <input
                                                className="px-2"
                                                name={`start_date_${index}`}
                                                value={section.start_date}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    "start_date",
                                                    e.target.value
                                                  )
                                                }
                                                type="date"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                              <label>End date </label>
                                              <input
                                                className="px-2"
                                                type="date"
                                                name={`end_date_${index}`}
                                                value={section.end_date}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    "end_date",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="d-flex flex-column">
                                              <input
                                                className="px-2"
                                                type="text"
                                                name={`company_${index}`}
                                                placeholder="Enter Company"
                                                value={section.company}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    "company",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="d-flex flex-column">
                                              <input
                                                className="px-2"
                                                type="text"
                                                name={`role_${index}`}
                                                value={section.role}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    "role",
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Enter Your Roll"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="d-flex flex-column">
                                              <label>
                                                Enter What you done in this
                                                company
                                              </label>
                                              <textarea
                                                name={`company_done_${index}`}
                                                placeholder="type here.."
                                                id=""
                                                rows="5"
                                                value={section.company_done}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    "company_done",
                                                    e.target.value
                                                  )
                                                }
                                              ></textarea>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="col-12 mt-2">
                                      <div className=" add-btn">
                                        <button
                                          type="button"
                                          className="next-btn"
                                          onClick={addMoreDiv}
                                        >
                                          <span>Add More</span>{" "}
                                          <span>
                                            <i className="fal fa-plus"></i>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-block">
                                      <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                        <button
                                          className="save-btn"
                                          onClick={() => previousstep("tab3")}
                                          type="button"
                                        >
                                          Previous
                                        </button>

                                        <button
                                          className="next-btn"
                                          type="submit"
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div
                                className={`tab-pane fade  ${
                                  tab1 === "tab5" ? "show active " : ""
                                } `}
                                id="qualifications-tab-pane"
                                role="tabpanel"
                                aria-labelledby="qualifications-tab"
                                tabindex="0"
                              >
                                <div className="d-block text-center">
                                  <h4>Application Form</h4>
                                </div>
                                <div className="d-block py-3">
                                  <h3>Education</h3>
                                </div>
                                <form
                                  action="javascript:void(0)"
                                  method="post"
                                  onSubmit={handleFormFinalsubmit}
                                >
                                  <div className="row gy-3 align-items-end">
                                    {employmenteducation.map(
                                      (section, index) => (
                                        <div
                                          key={index}
                                          className="row gy-3 align-items-end"
                                        >
                                          {index !== 0 && (
                                            <div className="d-flex align-items-center justify-content-end ">
                                              <Link
                                                to="javascript:void(0)"
                                                className="bg-danger text-white float-end py-2 px-3 rounded-1"
                                                onClick={() =>
                                                  trashdiveducation(index)
                                                }
                                              >
                                                Remove
                                              </Link>
                                            </div>
                                          )}
                                          <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                              <label>
                                                Year of Completion<span>*</span>
                                              </label>
                                              <input
                                                name={`year_${index}`}
                                                style={{ paddingLeft: "10px" }}
                                                type="text"
                                                placeholder="Year of completion"
                                                value={section.year}
                                                onChange={(e) =>
                                                  handleInputChange_edication(
                                                    index,
                                                    "year",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                              <label>
                                                Course<span>*</span>
                                              </label>
                                              <input
                                                name={`course_${index}`}
                                                style={{ paddingLeft: "10px" }}
                                                type="text"
                                                placeholder="Course"
                                                value={section.course}
                                                onChange={(e) =>
                                                  handleInputChange_edication(
                                                    index,
                                                    "course",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <div className="d-flex flex-column">
                                              <label>
                                                Institution<span>*</span>
                                              </label>
                                              <input
                                                name={`institution_${index}`}
                                                style={{ paddingLeft: "10px" }}
                                                type="text"
                                                placeholder="institution"
                                                value={section.institution}
                                                onChange={(e) =>
                                                  handleInputChange_edication(
                                                    index,
                                                    "institution",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}

                                    <div className="col-12">
                                      <div className=" add-btn">
                                        <button
                                          type="button"
                                          onClick={addMoreDivEducation}
                                        >
                                          <span>Add More</span>{" "}
                                          <span>
                                            <i className="fal fa-plus"></i>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-block py-3">
                                    <h3>Skill and Extra Informations</h3>
                                  </div>
                                  <div className="row gy-3 align-items-end">
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Mention All Skills <span>*</span>
                                        </label>

                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={options}
                                          value={selectvalue_skill}
                                          name="skills"
                                          required
                                          onChange={handleSelectChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <label>Years of Experience</label>
                                        <input
                                          className="px-2"
                                          type="number"
                                          pattern="[0-9]*"
                                          defaultValue={allrecord.years}
                                          onChange={handleInputChangeStep5}
                                          name="years"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>Mention Licences</label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={MentionLic}
                                          value={MentionLic_get}
                                          name="licence"
                                          onChange={handleSelectChange_mentionl}
                                        />
                                        <input
                                          className="px-0 pt-2"
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          onChange={handlelicense_File}
                                          name="licence_file"
                                        />
                                        <br />
                                        <span className="text-danger">
                                          {setselect_l_msg}
                                        </span>
                                        {licenseArray !== null ? (
                                          <div className="row">
                                            {Array.isArray(licenseArray) &&
                                              licenseArray.map(
                                                (item, index) => (
                                                  <div
                                                    key={index}
                                                    className="col-md-2"
                                                  >
                                                    <img
                                                      style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "contain",
                                                      }}
                                                      src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                                      alt="Image"
                                                    />
                                                    <br />
                                                    <button
                                                      className="removeskill text-danger  mt-2"
                                                      type="button"
                                                      onClick={() =>
                                                        removemention(
                                                          item,
                                                          userdetail.id
                                                        )
                                                      }
                                                    >
                                                      <FaTrash />
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        ) : (
                                          <p>No result found</p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Mention Other Certification
                                        </label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={MentionLCert}
                                          value={MentionLCert_m}
                                          name="certificate"
                                          onChange={handleSelectChange_mentionc}
                                        />
                                        <input
                                          type="file"
                                          onChange={handlecertificate_File}
                                          multiple
                                          accept="image/*"
                                          name="certificate_file"
                                          style={{ color: "#fff" }}
                                        />
                                        <br />
                                        <span className="text-danger">
                                          {selectcertmsg}
                                        </span>
                                        {edfile !== null ? (
                                          <div className="row">
                                            {Array.isArray(edfile) &&
                                              edfile.map((item, index) => (
                                                <div
                                                  key={index}
                                                  className="col-md-3"
                                                >
                                                  <img
                                                    style={{
                                                      width: "80px",
                                                      height: "80px",
                                                      objectFit: "contain",
                                                    }}
                                                    src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                                    alt="Image"
                                                  />
                                                  <br />
                                                  <button
                                                    className="removeskill text-danger  mt-2"
                                                    type="button"
                                                    onClick={() =>
                                                      removecertificate(
                                                        item,
                                                        userdetail.id
                                                      )
                                                    }
                                                  >
                                                    <FaTrash />
                                                  </button>
                                                </div>
                                              ))}
                                          </div>
                                        ) : (
                                          <p>No result found</p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>Trade Certifications</label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          name="trade"
                                          options={TradeM}
                                          value={selectvalue_trade}
                                          onChange={handleSelectChange_trade}
                                        />
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          onChange={handletrade_File}
                                          name="trade_file"
                                          style={{ color: "#fff" }}
                                        />
                                        <br />
                                        <span className="text-danger">
                                          {setselect_t_msg}
                                        </span>
                                        {tradetextArray !== null ? (
                                          <div className="row">
                                            {Array.isArray(tradetextArray) &&
                                              tradetextArray.map(
                                                (item, index) => (
                                                  <div
                                                    key={index}
                                                    className="col-md-3"
                                                  >
                                                    <img
                                                      style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "contain",
                                                      }}
                                                      src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                                      alt="Image"
                                                    />
                                                    <br />
                                                    <button
                                                      className="removeskill text-danger  mt-2"
                                                      type="button"
                                                      onClick={() =>
                                                        removetrade(
                                                          item,
                                                          userdetail.id
                                                        )
                                                      }
                                                    >
                                                      <FaTrash />
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        ) : (
                                          <p>No result found</p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>Machinery</label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={machinerygett}
                                          value={selectvalue_machinery}
                                          name="machinery"
                                          onChange={
                                            handleSelectChange_machinery
                                          }
                                        />
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          name="machinery_file"
                                          onChange={handlemachinery_File}
                                          style={{ color: "#fff" }}
                                        />
                                        <br />
                                        <span className="text-danger">
                                          {setselect_m_msg}
                                        </span>
                                        {machinetextArrayy !== null ? (
                                          <div className="row">
                                            {Array.isArray(machinetextArrayy) &&
                                              machinetextArrayy.map(
                                                (item, index) => (
                                                  <div
                                                    key={index}
                                                    className="col-md-3"
                                                  >
                                                    <img
                                                      style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "contain",
                                                      }}
                                                      src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                                      alt="Image"
                                                    />
                                                    <br />
                                                    <button
                                                      className="removeskill text-danger  mt-2"
                                                      type="button"
                                                      onClick={() =>
                                                        removemachine(
                                                          item,
                                                          userdetail.id
                                                        )
                                                      }
                                                    >
                                                      <FaTrash />
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        ) : (
                                          <p>No result found</p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>Vocational training</label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={Vocationtraa}
                                          value={
                                            selectvalue_vocational_training
                                          }
                                          name="vocational_training"
                                          onChange={
                                            handleSelectChange_vocational_training
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-column">
                                        <label>Equipment worked</label>
                                        <CreatableSelect
                                          isMulti
                                          formatCreateLabel={formatCreateLabel}
                                          placeholder={placeholderText}
                                          options={Equipmentworkk}
                                          value={selectvalue_equipment_work}
                                          name="equipment_work"
                                          onChange={
                                            handleSelectChange_equipment_work
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                        <button
                                          className="save-btn"
                                          type="button"
                                          onClick={() => previousstep("tab4")}
                                        >
                                          Previous
                                        </button>
                                        <button
                                          disabled={isButtonDisabled}
                                          className="next-btn"
                                          type="submit"
                                        >
                                          Submit
                                        </button>
                                        {textmessage && (
                                          <div className="textmessage text-center mt-2 ml-2">
                                            <div class="spinner-border text-success"></div>
                                          </div>
                                        )}
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Add Text Modal"
                    className="profile d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                  >
                    {" "}
                    <form
                      action="javascript:void(0)"
                      onSubmit={handleprofile_update}
                      method="post"
                    >
                      <div className="d-flex align-item-center justify-content-between mb-4">
                        <h4> Update Profile </h4>
                        <button className="btn btn-success " type="submit">
                          Submit
                        </button>
                      </div>

                      {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                      <div className="form-scroll-profile">
                        <div class="row">
                          {showAlert?.type === "success" && (
                            <Alert
                              type="success"
                              description="Profile has been updated successfully!"
                              closable
                            />
                          )}
                          {showAlert?.type === "error" && (
                            <Alert
                              type="error"
                              description="Email is already exists!"
                              closable
                            />
                          )}
                          <div class="col-md-6 mt-4">
                            <label className="mb-2">First Name</label>
                            <input
                              type="text"
                              name="first_name"
                              required
                              defaultValue={valuegetUser.first_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6 mt-4">
                            <label className="mb-2">Middle Name</label>
                            <input
                              type="text"
                              name="middle_name"
                              defaultValue={valuegetUser.middle_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Last Name</label>
                            <input
                              type="text"
                              name="last_name"
                              required
                              defaultValue={valuegetUser.last_name}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Email</label>
                            <input
                              type="email"
                              name="email"
                              required
                              defaultValue={valuegetUser.email}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Contact</label>
                            <input
                              type="text"
                              name="contact"
                              required
                              pattern="[0-9]*"
                              minlength="10"
                              defaultValue={valuegetUser.contact}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6">
                            <label className="mb-2">Address</label>
                            <input
                              type="text"
                              name="address"
                              required
                              defaultValue={valuegetUser.address}
                              onChange={handleInputChange}
                              className="form-control mb-2"
                            />
                          </div>
                          <div class="col-md-6 mb-3">
                            <label className="mb-2">Mention your Skills</label>
                            <div className="form-box rel">
                              <Select
                                isMulti
                                options={options}
                                defaultValue={setoptionskillvalue}
                                name="skills"
                                required
                                onChange={handleSelectChange}
                              />
                              <div></div>
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label className="mb-2">
                              Upload profile picture
                            </label>
                            <input
                              type="file"
                              name="image"
                              accept=".jpg, .png, .gif, .jpeg"
                              onChange={handleFileChange}
                              className="form-control mb-2"
                            />
                            <input
                              type="hidden"
                              name="profiledate"
                              defaultValue={valuegetUser.image}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal>
                  <h3 className="fs-26 text-black fw-800">
                    {valuegetUser.first_name} {valuegetUser.middle_name}{" "}
                    {valuegetUser.last_name}
                  </h3>
                  <Modal
                    isOpen={isModalOpenRole}
                    onRequestClose={() => setIsModalOpenRole(false)}
                    contentLabel="Add Text Modal"
                    className="role d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
                  >
                    {" "}
                    <form
                      action="javascript:void(0)"
                      onSubmit={handlerole_update}
                      method="post"
                    >
                      <div className="d-flex align-item-center justify-content-between mb-4">
                        <h4> Update Role </h4>
                      </div>

                      {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                      <div class="row">
                        {showAlert?.type === "success" && (
                          <Alert
                            type="success"
                            description="Profile has been updated successfully!"
                            closable
                          />
                        )}
                        {showAlert?.type === "error" && (
                          <Alert
                            type="error"
                            description="Email is already exists!"
                            closable
                          />
                        )}

                        <div class="col-md-12">
                          <label className="mb-2">Role</label>
                          <input
                            type="text"
                            name="role"
                            required
                            defaultValue={valuegetUser.role}
                            onChange={handleInputChange}
                            className="form-control mb-2"
                          />
                          <button
                            className="btn btn-success w-50"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>

                  <h4 className="fs-18 text-black">
                    <b>{valuegetUser.role}</b>
                  </h4>
                  {/* <br/>
                  <p className="fs-12 text-black">{valuegetUser.years}</p> */}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="row gy-2 emplo-textblock">
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Phone no: </span>
                    {valuegetUser.contact}
                  </h3>
                </div>
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Email id: </span>
                    {valuegetUser.email}
                  </h3>
                </div>
                <div className="col-12">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Address: </span>
                    {valuegetUser.address}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3">
              <div className="d-flex flex-column gap-1">
                <h3 className="text-black fs-24 fw-bold mb-3">
                  You are Working:
                </h3>

                <div className="d-flex flex-column gap-1">
                  {Array.isArray(valuegetClient) ? (
                    valuegetClient.map((item, index) => (
                      <Link
                        to="javascript:void(0)"
                        onClick={() =>
                          handlegetClientdata(item.client_id, index)
                        }
                        className={`client-btn-text d-block ${
                          index === indx && isActive ? "client-active" : ""
                        }`}
                      >
                        {item.name}
                      </Link>
                    ))
                  ) : (
                    <Link> No data available </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column mb-2">
                  <h3 className="text-black fs-20 fw-600">
                    Your Roster for {clientname} and {clientMinesite}
                  </h3>
                  <h4 className="text-black fs-16 fw-400">
                    Your Roster is {clienttype}
                  </h4>
                </div>
                <div className="d-flex flex-column gap-4 heightprofile">
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Day shifts:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyday ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueget).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueget[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueget[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Night shifts:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmpty ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valuenightshift).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valuenightshift[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valuenightshift[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Day Off:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptydayoff ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(ValueDayoff).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {ValueDayoff[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{ValueDayoff[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Sick Leave:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptysickleave ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valuesickleave).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valuesickleave[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valuesickleave[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*******Annual Leave */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Annual Leave:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyannualleave ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueannualleave).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueannualleave[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueannualleave[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*******Annual Leave */}

                  {/*****Work Camp */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Workers Camp:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyworkerscomp ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueworkerscomp).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueworkerscomp[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueworkerscomp[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Work Camp */}

                  {/*****Fly In Pm */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly In PM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyinpm ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyinpm).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyinpm[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyinpm[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly In Pm */}

                  {/*****Fly Out Pm */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly Out AM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyoutam ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyoutam).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyoutam[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyoutam[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly Out Pm */}

                  {/*****Fly In Am */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly In AM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyinam ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyinam).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyinam[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyinam[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Fly In Am */}

                  {/*****In Isolation On Site */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">
                        In Isolation On Site:
                      </h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyinisolationonsite ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueinisolationonsite).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueinisolationonsite[month].map(
                                        (row) => (
                                          <h5 key={row.id}>{row.nd}</h5>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueinisolationonsite[month][0].year %
                                      100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****In Isolation On Site */}

                  {/*****Leave Without Pay */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Leave Without Pay:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyleavewithoutpay ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueleavewithoutpay).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueleavewithoutpay[month].map(
                                        (row) => (
                                          <h5 key={row.id}>{row.nd}</h5>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueleavewithoutpay[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Leave Without Pay */}

                  {/*****FlyOut PM */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Fly Out PM:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyflyoutpm ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueflyoutpm).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueflyoutpm[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/{valueflyoutpm[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****FlyOut PM */}

                  {/*****Work Of Site */}
                  <div className="row">
                    <div className="col-3">
                      <h4 className="text-black fs-16">Work Offsite:</h4>
                    </div>
                    <div className="col-9">
                      {isObjectEmptyworkoffsite ? (
                        <p>No results found.</p>
                      ) : (
                        <div>
                          {Object.keys(valueworkoffsite).map((month) => (
                            <div className="d-flex flex-column" key={month}>
                              <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                <div className="flex-grow-1">
                                  <div className="scroll-date-block">
                                    <div className="d-flex gap-1">
                                      {valueworkoffsite[month].map((row) => (
                                        <h5 key={row.id}>{row.nd}</h5>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-shrink-0">
                                  <h5 className="text-black fs-14">
                                    {month}/
                                    {valueworkoffsite[month][0].year % 100}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*****Work Of Site */}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column gap-1">
                <h3 className="text-black fs-24 fw-bold mb-3">Skills:</h3>

                <div className="d-flex flex-wrap gap-1">
                  <h5 className="sikll-text-head d-block text-black">
                    {vsetval.join(", ")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

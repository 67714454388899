import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Alert } from "antd";
import { saveAs } from "file-saver";
import { Link, useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import Modal from "react-modal";
import moment from "moment";
import Select from "react-select";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  FaTrash,
  FaStar,
  FaFilePdf,
  FaEye,
  FaMoon,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaThermometer,
  FaSun,
  FaEdit,
  FaUmbrella,
  FaSync,
  FaTrashAlt,
} from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import AdminFooter from "./AdminFooter";
import jsPDF from "jspdf";
import { ReactDialogBox } from "react-js-dialog-box";
//import { EmployeeResumePdf } from "./EmployeeResumePdf";
import generatePDF from "./PDFGeneration";
import { render } from "@testing-library/react";
import Timeline from "react-calendar-timeline";

export const AdminRoster = () => {
  const navigate = useNavigate();

  //
  const [Equipmentwork, setSelectedEquipmentwork_g] = useState([]);
  const [textmessage, settextmessage] = useState(false);
  const [textmessages, settextmessages] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const emailerr = useRef();

  const [allrecord, setallrecord] = useState("");
  const [tab1, settab1] = useState("tab2");
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;
  const placeholderText = "Type here...";
  const [loaderfirst, setloaderfirst] = useState(false);
  const [copylink, setcopylink] = useState(false);
  const [mainSection, setmainSection] = useState(true);
  const [sentMailForm, setsentMailForm] = useState(true);
  const [allcountry, setallcountry] = useState([]);
  //Step1
  const [Url_Step1, setUrl_Step1] = useState("");
  const [readyValue, setReadyValue] = useState("");
  const [checkbuttonStep1, setcheckbuttonStep1] = useState("0");
  const [TextCopyClipboard, setTextCopyClipboard] = useState("");
  //Step1

  //
  const [isemail, setemail] = useState("Email does not match");
  const [issstep2_title, setstep2_title] = useState("");
  const [isstep2_shirt_size, setstep2_shirt_size] = useState("");
  const [isstep2_postal_country, setisstep2_postal_country] = useState("");
  const [isstep2_postal_state, setisstep2_postal_state] = useState("");
  const [optioncountryfirst, setoptioncountryfirst] = useState("");
  const [optionstatefirst, setoptionstatefirst] = useState("");
  const [optionorigin, setoptionorigin] = useState("");
  const [genderOptions, setgenderOptions] = useState("");
  const [erroremail, seterroremail] = useState(false);
  const [errorpassword, seterrorpassword] = useState(false);
  const [step2_Postaladdress, setstep2_Postaladdress] = useState(false);
  const [auscitizenNo, setauscitizenNo] = useState(false);
  const [auscitizenYes, setauscitizenYes] = useState(false);

  const [auscitizenYesBirth, setauscitizenYesBirth] = useState(false);
  const [auscitizenYesPassport, setauscitizenYesPassport] = useState(false);
  const [auscitizenYesCert, setauscitizenYesCert] = useState(false);

  const [isbirthcheck, setisbirthcheck] = useState("");
  const [ispassportcheck, setispassportcheck] = useState("");
  const [iscertificatecheck, setiscertificatecheck] = useState("");

  const [islegalwork, setislegalwork] = useState("");
  const [islegalworkdesc, setislegalworkdesc] = useState(false);
  const [isresidentalAddress, setresidentalAddress] = useState("");
  const [isSelectedEmployeePhoto, setSelectedEmployeePhoto] = useState(null);
  const [isselectcountrybirth, setselectcountrybirth] = useState(null);
  const [isSelectedpermanentAddress, setSelectedpermanentAddress] =
    useState("");
  const [isSelectedPermanentpassport, setSelectedPermanentpassport] =
    useState(null);
  const [selectedProofs, setSelectedProofs] = useState([]);
  const [isBirthcertificate, setBirthcertificate] = useState(null);
  const [isPassportcertificate, setPassportcertificate] = useState(null);
  const [iscertificatefile, setcertificatefile] = useState(null);
  const [iscriminaloffensenses, setcriminaloffensenses] = useState("");
  const [isservedtime, setservedtime] = useState("");
  const [isdefenceforced, setdefenceforced] = useState("");
  const [isdayshift, setdayshift] = useState("");
  const [isnightshift, setnightshift] = useState("");
  const [selectedEmployetype, setselectedEmployetype] = useState([]);
  const [formValues, setFormValues] = useState({
    step2_title: allrecord.step2_title || "",
    first_name: allrecord.first_name || "",
    last_name: "",
    contact: "",
    step2_gender: "",
    step2_origin: "",
    email: "",
    step2_confirm_email: "",
    password: "",
    step2_confirm_password: "",
    address: "",
    step2_address: "",
    step2_city: "",
    step2_state: "",
    step2_Postal: "",
    step2_country: "",
    step2_postal_address: "",
    step2_postal_address2: "",
    step2_postal_city: "",
    step2_postal_state: "",
    step2_postal_code: "",
    step2_postal_country: "",
    step2_dob: "",
    step2_country_birth: "",
    step2_proof_upload: "",
    step2_available_date: "",
    step2_shirt_size: "",
  });
  const [contactstep2, setContactstep2] = useState("");
  const [invalidnumberstep2, setinvalidnumberstep2] = useState("");
  const [invalidnumberstep3, setinvalidnumberstep3] = useState("");
  //Step2

  //Step3
  const [invalidnumberstep3_alter, setinvalidnumberstep3_alter] = useState("");
  const [optioncountrythird, setoptioncountrythird] = useState("");
  const [isstep3_postal_state, setisstep3_postal_state] = useState("");
  const [formValues_Step3, setFormValues_Step3] = useState({
    step3_title: "",
    step3_first_name: "",
    step3_last_name: "",
    step3_relationship: "",
    step3_contact: "",
    step3_mobile_number: "",
    step3_phone_number: "",
    step3_address: "",
    step3_address2: "",
    step3_city: "",
    step3_state: "",
    step3_postal: "",
    step3_country: "",
  });
  const [issstep3_title, setissstep3_title] = useState("");
  const [isstep3_contact, setisstep3_contact] = useState("");
  const [contactstep3, setContactstep3] = useState("");
  const [contactstep3_alter, setContactstep3_alter] = useState("");
  //Step3

  //Step 5
  const [reviewApplicationform, setreviewApplicationform] = useState(false);
  const [checkwork, setcheckwork] = useState("");
  const [typecheck, settypecheck] = useState("");

  const [selectvalue_skill, setselectvalue_skill] = useState([]);

  const [MentionLCert, setSelectedMentionCert] = useState([]);
  const [MentionLCert_m, setSelectedMentionCert_m] = useState([]);

  const [formValues_step5, setFormValues_step5] = useState({
    years: "",
  });
  //Step 5
  //
  const [vlexp, setexp] = useState(0);
  const [Docdelete1, setDocdelete1] = useState(false);
  const [Docdown1, setDocdown1] = useState(false);
  const [docUp1, setdocUp1] = useState(false);
  const [docUp2, setdocUp2] = useState(false);
  const [Docdown2, setDocdown2] = useState(false);
  const [Docdelete, setDocdelete] = useState(false);
  const [admindoc1, setadmindoc1] = useState(false);
  const [adminlic1, setadminlic1] = useState(false);
  const [selectedItemsLic, setselectedItemsLic] = useState([]);
  const [uploadfiledialog, setuploadfiledialog] = useState(false);
  const [doca2, setdoca2] = useState(false);
  const [doca1, setdoca1] = useState(false);
  const [Userdocs, setUserdocs] = useState([]);
  const [Userlic, setUserlic] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const apiurl = "https://jlmining.app/uploads/";
  var im = "male-upload.png";
  var imageUrl = `${apiurl}${im}`;
  const [selectcertmsg, setselect_certmsg] = useState("");
  const [selectedcertificate_file, setSelectedcertificate_file] =
    useState(null);
  const [selectvalue_vocational_training, setselectvalue_vocational_training] =
    useState([]);
  const [SelectedEquipmentwork, setSelectedEquipmentwork] = useState([]);
  const [SelectedEquipmentwork_get, setSelectedEquipmentwork_get] = useState(
    []
  );
  const [setSelectedFile_doc, setsetSelectedFile_doc] = useState(null);
  const [setSelectedFile_lic, setsetSelectedFile_lic] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPass, setShowPass] = useState(false);

  const [isActivec, setIsActivec] = useState(false);

  const [references_get, setreferences_get] = useState([]);
  const [sgetreferences, setSelectedreferences] = useState([]);
  const [selectvalue_equipment_work, setselectvalue_equipment_work] = useState(
    []
  );
  const [selectvalue_previous_work, setselectvalue_previous_work] = useState(
    []
  );
  const [selectvalue_reference, setselectvalue_reference] = useState([]);
  const [Previouswork, setSelectedprevwork] = useState([]);
  const [Previouswork_get, setSelectedprevwork_get] = useState([]);
  const [machinetextArrayy, setmachinetextArrayy] = useState([]);
  const [selectedmachinery_file, setSelectedmachinery_file] = useState(null);
  const [selectvalue_machinery, setselectvalue_machinery] = useState([]);
  const [machineryget, setSelectedmachinery] = useState([]);
  const [machineryget_get, setSelectedmachinery_get] = useState([]);
  const [selectvalue_trade, setselectvalue_trade] = useState([]);
  const [selectvalue_mentionc, setselectvalue_mentionc] = useState([]);
  const [trademach, setSelectedTrade] = useState([]);
  const [Vocationtra, setSelectedVocationtra] = useState([]);
  const [Vocationtra_get, setSelectedVocationtra_get] = useState([]);
  const [trademach_get, setSelectedTrade_get] = useState([]);
  const [setselect_m_msg, setsetselect_m_msg] = useState("");
  const [setselect_t_msg, setsetselect_t_msg] = useState("");
  const [setselect_l_msg, setsetselect_l_msg] = useState("");
  const [selectedtrade_file, setSelectedtrade_file] = useState(null);
  const [selectedlicenec_File, setSelectedlicenec_File] = useState(null);
  const [selectvalue_mentionl, setselectvalue_mentionl] = useState([]);
  const [MentionCertt, setMentionCertt] = useState([]);
  const [MentionCertt_get, setMentionCertt_get] = useState([]);
  const [MentionLic, setSelectedMentionLic] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions_get, setSelectedOptions_get] = useState([]);
  const [MentionLic_get, setMentionLic_get] = useState([]);

  const [selectvalue, setselectvalue] = useState([]);
  const [educations, seteducationsr] = useState([]);
  const [rosterdialog, setrosterdialog] = useState(false);
  const [emData, setData] = useState([]);
  const [springloader, setspringloader] = useState(false);
  const [validd, setvalidd] = useState("");
  const [base64url, setbase64url] = useState("");
  const [backurlurl, setbackurlurl] = useState("");
  const [userimage, setuserimage] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [istatus, setIsstaus] = useState(false);
  const [vsetval, setvall] = useState([]);
  const [valueclients, setvalueclients] = useState([]);
  const [notatus, setNostaus] = useState(false);
  const [gdata, getData] = useState("");
  const [rostertype, setrostertype] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClickedclient, setisClickedclient] = useState(false);
  const [isClickedetails, setisClickedetails] = useState(false);
  const [isClickedyourroster, setisClickedyourroster] = useState(false);
  const [valuesickleave, setValuesickleave] = useState({});
  const [valueannualleave, setValueannualleave] = useState({});
  const [valueworkerscomp, setValueworkerscomp] = useState({});
  const [valueflyinpm, setValueflyinpm] = useState({});
  const [valueflyoutam, setValueflyoutam] = useState({});
  const [valueflyinam, setValueflyinam] = useState({});
  const [valueinisolationonsite, setValueinisolationonsite] = useState({});
  const [valueleavewithoutpay, setValueleavewithoutpay] = useState({});
  const [valueflyoutpm, setValueflyoutpm] = useState({});
  const [valueworkoffsite, setValueworkoffsite] = useState({});
  const [valuenightshift, setValuenightshift] = useState({});
  const [valueget, setValueget] = useState({});
  const [ValueDayoff, setValueDayoff] = useState({});

  const [Valueworkingdays, setValueworkingdays] = useState({});
  const [Valuealloffdays, setValuealloffdays] = useState({});

  const [valueemplyId, setvalueemplyId] = useState("");

  const [Valuemainroster, setValuemainroster] = useState({});

  //Resume Pdf
  const [valuegetroster, setValuegetroster] = useState([]);
  const [resumedata, setresumedata] = useState("");
  const [licenseArray, setlicenseArray] = useState([]);
  const [educationsArray, seteducations] = useState([]);
  const [skillsArray, setskillsArray] = useState([]);
  const [licfile, setlicfile] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [vocationalArray, setvocationalArray] = useState([]);

  const [tradetextArray, settradetextArray] = useState([]);
  const [equipmentWorked, setEquipmentWorked] = useState([]);
  const [tradefile, setedtradefile] = useState([]);
  const [certm, setedcertif_file] = useState([]);
  const [machinetextArray, setmachinetextArray] = useState([]);
  const [machinefileArray, semachinefile] = useState([]);

  const [refreArray, setrefreArray] = useState([]);
  const [historyArray, sethostoryArray] = useState([]);
  //Resume Pdf
  //Client Roster
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [valueroster, setValueroster] = useState([]);
  const [setdate_s, setDatessvalue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClickedopen, setisClickedopen] = useState(false);
  const [colorActive, setcolorActive] = useState("");
  const iconClassName = `weather-top-icon active ${colorActive}`;
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [iconset, setIcon] = useState("");
  const [isClickedopen_roster, setisClickedopen_roster] = useState(false);
  const [currentroster, setcurrentroster] = useState(null);
  const [remember_roster, setremember_roster] = useState(false);
  const [shifts, setshifts] = useState("");
  const [shiftmessagevalue, setshiftmessage] = useState("");
  const [isClickedloader, setIsClickedloader] = useState(false);
  const [clientreq, setclientreq] = useState(false);
  const [locatreq, setlocatreq] = useState(false);
  const [isConditionMet, setIsConditionMet] = useState(false);
  const [isConditionMetbtn, setIsConditionMetbtn] = useState("Submit");
  const [rosterreq, setrosterreq] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [rosterclient, setrosterclient] = useState([]);
  const [locationname, setlocationname] = useState([]);
  const [inputvalue_data, setinputvalue_data] = useState("");
  const [locatename, setlocatename] = useState("");
  const [getallroster, setValuegetallroster] = useState([]);
  const [minisClicked, setMinClicked] = useState(false);
  const [IsClickedUser, setIsClickedUser] = useState(false);
  const [clientinfo, setclientInfo] = useState("");
  const [activeIndex_index, setActiveIndex_index] = useState(null);
  const [activesIndex, setActivesIndex] = useState(null);
  const [clientID, setClientId] = useState("");
  const [locatid, setlocatid] = useState("");
  const [locationID, setLocationId] = useState("");
  const [isClickedloc, setIsClickedloc] = useState(false);
  const [located, setlocated] = useState("");
  const [UserId, setUserId] = useState("");
  const [gldata, getDatal] = useState("");
  const [opencloseroster, setopencloseroster] = useState(false);
  const [isModalOpen_hrs, setIsModalOpen_hrs] = useState(false);
  const [openclosecalender, setopenclosecalender] = useState(false);
  const [emDataclient, setDataclient] = useState([]);
  const [locatedname, setlocatedname] = useState("");
  const targetDivRef = useRef(null);
  const [events, setValuegetCalendar] = useState([]);
  const [valueclientId, setvalueclientId] = useState("");
  const [valuelocations, setvaluelocations] = useState("");
  const [valuelocationsId, setvaluelocationsId] = useState("");
  const [setdate, setDates] = useState(null);
  const [isClickededit, setisClickedaddorEdit] = useState(true);
  const [valueclient, setvalueclient] = useState("");
  const [isClickedadd, setisClickedadd] = useState(true);
  //Client Roster
  //edit Profile
  const [editprofile, seteditprofile] = useState(false);
  const [opennotification, setopennotification] = useState(false);
  const [succ_n, setsucc_n] = useState(false);
  const [calendershow, setcalendershow] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Current month
  const [selectedMonths, setSelectedMonths] = useState(new Date().getMonth()); // Current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDateff_f, setstartDateff_f] = useState("");
  const [endDateff_f, setendDateff_f] = useState("");
  var appUrl = "https://jlmining.app/uploads";
  //edit Profile
  const [spinners, setspinners] = useState(false);
  const [sctext, setsctext] = useState("");
  const [missingdates, setmissingdates] = useState("");
  const [missingdatessuccess, setmissingdatessuccess] = useState("");
  const [UserIdd, setUserIdd] = useState("");
  const [shiftshow, setshiftshow] = useState(false);
  const [startDate, setstartDate] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [endDate, setendDate] = useState(null);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Calender Page";
    //getallcalender();
  }, [selectedMonth, selectedYear]);
  const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

  const handleStartDate = (e) => {
    var stdate = new Date(e.target.value);
    var formattedDate = stdate.toString();
    setstartDate(formattedDate);
    updateButtonState(stdate, endDate);
  };
  const handleEndDate = (e) => {
    const date = new Date(e.target.value);
    setSelectedYear(date.getFullYear());
    setSelectedMonth(date.getMonth() + 1);
    var endate = new Date(e.target.value);
    var formattedDate = endate.toString();
    setendDate(formattedDate);
    updateButtonState(startDate, endate);
  };
  const updateButtonState = (start, end) => {
    // Enable the button only when both dates are selected and endDate >= startDate
    if (start && end) {
      const isValid = new Date(end) >= new Date(start); // Ensure endDate is not earlier than startDate
      setIsButtonEnabled(isValid);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const getallcalender = () => {
    const currentDate = new Date(); // Get the current date
    const currentMonthIndex = currentDate.getMonth();
    var year = new Date().getFullYear();

    let formdata = {
      user_id: "",
      month: selectedMonth + 1,
      year: selectedYear,
    };

    axios
      .post(
        "https://jlmining.app/admin/getallCalendardetailForadmin",
        formdata,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.maindata;
        console.log(res.data);
        console.log("mainaa");
        //var d = getday();
        const all_data = [];
        const all_groups = []; // Store groups here
        const all_items = [];
        for (let i = 0; i < alldata.length; i++) {
          //   console.log(alldata[i]);
          var cudate = new Date();
          var st_date = moment(cudate).format("YYYY-MM-DD");

          var clr = "";
          var id = alldata[i].attend_id;
          var signed = "";
          var hourstatus = alldata[i].hours_status;
          if (hourstatus === "Client") {
            var signed = "Signed";
          }
          if (alldata[i].id == "") {
            var title = "";
            var ic = "";
            var colr = "white";
            var pop = "Open";
            var cudate = new Date();

            var st_date = moment(cudate).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = "";
            var hours = "";
          }
          if (alldata[i].shift === "Day") {
            var title = "DS";
            var ic = <FaSun />;
            var colr = "#B2DAF2";
            var clr = "black";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Edit") {
            var title = "Edit | " + alldata[i].client_name;
            var ic = <FaEdit />;
            var pop = "Open";
            var colr = "gray";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Add") {
            var title = "Add";
            var ic = "";
            var pop = "Open";
            var colr = "white";
            var clr = "red";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Night") {
            var title = "DN";
            var ic = <FaMoon />;
            var colr = "#1D0303";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIAM") {
            var title = "FLIAM";
            var ic = <FaPlaneArrival />;
            var colr = "#B9723D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIPM") {
            var title = "FLIPM";
            var ic = <FaPlaneArrival />;
            var colr = "#7DBFFC";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOAM") {
            var title = "FLOAM";
            var ic = <FaPlaneDeparture />;
            var colr = "#9F20AA";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOPM") {
            var title = "FLOPM";
            var ic = <FaPlaneDeparture />;
            var colr = "#77D928";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Sick Leave") {
            var title = "Sick Leave";
            var ic = <FaThermometer />;
            var colr = "#395AEF";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "AL") {
            var title = "AL";
            var ic = <FaUmbrella />;
            var colr = "#F71718";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Offsite") {
            var title = "Work Offsite";
            var ic = <FaUmbrella />;
            var colr = "#490909";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Leave Without Pay") {
            var title = "Leave Without Pay";
            var ic = "";
            var colr = "#F3DC0C";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "In Isolation on site") {
            var title = "In Isolation on site";
            var ic = <FaStar />;
            var colr = "#FB02F5";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Camp") {
            var title = "Work Camp";
            var ic = <FaStar />;
            var colr = "#F8B70D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "SC") {
            var title = "SC " + signed;
            var ic = "";
            var colr = "#0B5ED7";
            var clr = "black";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          var fn = alldata[i].first_name;
          var mn = "";
          var ln = alldata[i].last_name;
          let findata = {
            title: title,
            roster: alldata[i].roster,
            start: start,
            end: end,
            user_id: alldata[i].user_id,
            color: clr,
            backgroundColor: colr,
            text: "center",
            icon: ic,
            pop: pop,
            id: id,
            shft: shft,
            hours: hours,
            loc_name: alldata[i].loc_name,
            client_name: alldata[i].client_name,
            username: fn + " " + mn + " " + ln,
          };

          all_data.push(findata);
        }

        setValuegetCalendar(all_data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    const now = new Date();

    // Get the first date of the current month
    const firstDayOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );

    // Get the last date of the current month
    const lastDayOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    const formatDate = (date) => date.toISOString().split("T")[0];
    const currentDate = new Date(); // Get the current date
    const currentMonthIndex = currentDate.getMonth();
    var year = new Date().getFullYear();

    let formdata = {
      user_id: "",
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    };
    console.log(formdata);

    // Set the start and end date
    setstartDate(firstDayOfMonth);
    setendDate(lastDayOfMonth);
  }, []);
  const numbers = Array.from({ length: 24 }, (_, index) => index + 1);
  const localizer = momentLocalizer(moment);
  const handleevt = (event) => {
    setSelectedEvent(event);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    //console.log(event);
    //console.log("dd");
    //console.log(shifts);
    if (event.shft === "Add") {
      if (event.pop === "Open") {
        setIsModalOpen(true);
      }
      setDatessvalue(event.start);
      setDates(event.start);

      let formdata = {
        checkdate: event.start,
        user_id: UserId,
      };

      setTimeout(() => {
        let fdata = {
          checkdate: event.start,
          user_id: UserId,
        };
        if (event.title === "Add") {
          //console.log(fdata);
          axios
            .post("https://jlmining.app/admin/getforEmp_client", fdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;
              //console.log("add");
              //console.log(alldata);
              if (alldata.length > 0) {
                setValueroster(alldata);
                // setlocatename([]);
              }
            })
            .catch((err) => {});
        } else {
          axios
            .post("https://jlmining.app/admin/getallrosterlimit", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.dataa;

              if (alldata.length > 0) {
                setvalueclient(alldata[0].name);
                setvalueclientId(alldata[0].client_id);
                //setValueroster(alldata);
                let fdata = {
                  checkdate: event.start,
                  user_id: UserId,
                  client_id: alldata[0].client_id,
                };
                axios
                  .post("https://jlmining.app/admin/getlocation_check", fdata, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "POST,PUT",
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.row;
                    // console.log("gttt");
                    // console.log(alldata);
                    if (alldata.length > 0) {
                      // setlocationname(alldata);
                      setvaluelocations(alldata[0].location_name);
                      setvaluelocationsId(alldata[0].location_id);
                      // setlocatename([]);
                    }
                  })
                  .catch((err) => {});
                axios
                  .post("https://jlmining.app/admin/getroster", formdata, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "POST,PUT",
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.results;
                    //console.log("getroster");
                    //console.log(res.data.results);
                    const fn = [];
                    for (let i = 0; i < alldata.length; i++) {
                      fn.push(alldata[i].type);
                    }
                    setValueget(fn);
                  })
                  .catch((err) => {});
              }
            });
        }
        setDataclient([]);
        setlocatedname("");
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
        if (event.title === "Add") {
          setisClickedaddorEdit(false);
          setisClickedadd(true);
        } else {
          setisClickedaddorEdit(true);
          setisClickedadd(false);
        }
      }, 1000);
    } else {
      //console.log(shifts);
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        const userStr = JSON.parse(localStorage.getItem("users"));
        let formdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
          shift: shifts,
        };
        axios
          .post("https://jlmining.app/admin/multiplerosteradd", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("getallcalender");
            getallcalender();
          })
          .catch((err) => {});
        setlocatedname("");
        setDataclient([]);
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
      }
    }
  };
  const CustomEvent1 = ({ event }) => (
    <div
      style={{
        padding: "4px 1px",
        backgroundColor:
          event.title === "Add"
            ? "red"
            : event.title === "Edit"
            ? "gray"
            : event.title === "DN"
            ? "#1D0303"
            : event.title === "FLIAM"
            ? "#B9723D"
            : event.title === "FLIPM"
            ? "#7DBFFC"
            : event.title === "FLOAM"
            ? "#9F20AA"
            : event.title === "FLOPM"
            ? "#77D928"
            : event.title === "Sick Leave"
            ? "#395AEF"
            : event.title === "AL"
            ? "#F71718"
            : event.title === "Work Offsite"
            ? "#490909"
            : event.title === "Leave Without Pay"
            ? "#F3DC0C"
            : event.title === "In Isolation on site"
            ? "#FB02F5"
            : event.title === "Work Camp"
            ? "#F8B70D"
            : event.backgroundColor,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          fontSize: "13px",
          padding: "4px 1px",
          backgroundColor:
            event.title === "Add"
              ? "red"
              : event.title === "Edit"
              ? "gray"
              : event.title === "DN"
              ? "#1D0303"
              : event.title === "FLIAM"
              ? "#B9723D"
              : event.title === "FLIPM"
              ? "#7DBFFC"
              : event.title === "FLOAM"
              ? "#9F20AA"
              : event.title === "FLOPM"
              ? "#77D928"
              : event.title === "Sick Leave"
              ? "#395AEF"
              : event.title === "AL"
              ? "#F71718"
              : event.title === "Work Offsite"
              ? "#490909"
              : event.title === "Leave Without Pay"
              ? "#F3DC0C"
              : event.title === "In Isolation on site"
              ? "#FB02F5"
              : event.title === "Work Camp"
              ? "#F8B70D"
              : event.backgroundColor,
        }}
        onClick={() => handleevt(event)}
      >
        {event.icon}
        {event.title}
      </div>
      {event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined ? (
        <div>
          <select
            className="w-100 mt-2"
            value={event.hours}
            onChange={(e) =>
              handlesubmithours(event.start, e.target.value, event.shft)
            }
          >
            <option value={""}>Choose Hours</option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </div>
  );
  const handlesubmithours = (event, e, s) => {
    //if (e != "") {
    //setshifts("");
    if (e == "") {
      var e = null;
    }
    setIsClickedloader(true);
    setinputvalue_data(e);

    let formvl = {
      date: event,
      value: e,
      shift: s,
      user_id: UserIdd,
    };

    axios
      .post("https://jlmining.app/admin/calenderhoursadd", formvl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsModalOpen_hrs(true);
        setTimeout(() => {
          getallcalender();
          setIsClickedloader(false);
          setIsModalOpen_hrs(false);
          setshiftmessage("");
        }, 1000);
      })
      .catch((err) => {});
    // }
  };
  const CustomEvent2 = ({ event }) => <div></div>;
  const EventComponent = ({ event }) => {
    //console.log(event);
    if (event.id !== "") {
      return <CustomEvent1 event={event} />;
    } else if (
      event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined
    ) {
      return <CustomEvent2 event={event} />;
    }

    return null;
  };
  const handleAttend = (event) => {
    var fr = event.target;
    //console.log(event);

    var sf = fr.shift.value;

    if (fr.shift.value === "") {
      return false;
    }

    let formvl = {
      clientId: valueclientId,
      location: valuelocationsId,
      roster: valueget,
      user_id: UserIdd,
      daystart: setdate,
      shift: fr.shift.value,
    };
    //console.log(formvl);
    //return false;

    axios
      .post("https://jlmining.app/admin/attendancesave", formvl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }

        setTimeout(() => {
          setIsModalOpen(false);
          getallcalender();
          //window.location.reload();
          setShowAlert(false);
          setDataclient([]);
          setlocatedname("");
          let formdata = {
            user_id: UserId,
          };
          axios
            .post("https://jlmining.app/admin/getclientforroster", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              //console.log("client idd");
              // console.log(res.data.results);
              setDataclient(res.data.results);
            })
            .catch((err) => {});
        }, 1500);
      })
      .catch((err) => {});
  };
  const handle_getClient = (event) => {
    let formdata = {
      clientId: event.target.value,
    };
    // console.log(formdata);
    setvalueclientId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getclient_check", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        // console.log("clid");
        //console.log(alldata);
        setlocationname(alldata);
        setlocatename([]);
      })
      .catch((err) => {});
  };

  //Edit profile

  //New Edit Profile

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayAbbreviations = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleMonthClick = (index) => {
    console.log(index);
    setSelectedMonth(index);
    setSelectedMonths(index);
    getallcalender();
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    getallcalender();
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  // Get days in the month (30 or 31)

  //New Edit Profile

  const groupedData = events.reduce((acc, item) => {
    // Create a key for each username to group the events
    const key = item.username;

    // If the username is not already in the accumulator, initialize it as an empty array
    if (!acc[key]) {
      acc[key] = [];
    }

    // Add the item to the grouped array under the corresponding username
    acc[key].push(item);

    return acc;
  }, {});
  const handle_getIcon = (event, bg, shift) => {
    setBackgroundColor(bg);
    //setcolorActive(bg);
    setIcon(event);
    setshifts(shift);
    setshiftmessage("");
    setisClickedopen(true);
    if (event === "SC") {
      setsctext("SC");
    } else {
      setsctext("");
    }
  };
  const handleSubmitSC = (e) => {
    e.preventDefault();
  };
  const handleSelectEvent = (e) => {
    console.log(e);
    setUserIdd(e.user_id);
    setValueget(e.roster);
    if (e.shft === "Add") {
      setshiftshow(false);
      if (e.pop === "Open") {
        setIsModalOpen(true);
      }
      setDatessvalue(e.start);
      setDates(e.start);

      let formdata = {
        checkdate: e.start,
        user_id: e.user_id,
      };

      setTimeout(() => {
        let fdata = {
          checkdate: e.start,
          user_id: e.user_id,
        };
        if (e.title === "Add") {
          //console.log(fdata);
          axios
            .post("https://jlmining.app/admin/getforEmp_client", fdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;
              //console.log("add");
              //console.log(alldata);
              if (alldata.length > 0) {
                setValueroster(alldata);
                // setlocatename([]);
              }
            })
            .catch((err) => {});
        } else {
          axios
            .post("https://jlmining.app/admin/getallrosterlimit", formdata, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.dataa;
              //console.log("getclientdata");
              // console.log(alldata.length);
              if (alldata.length > 0) {
                setvalueclient(alldata[0].name);
                setvalueclientId(alldata[0].client_id);
                //setValueroster(alldata);
                let fdata = {
                  checkdate: e.start,
                  user_id: e.user_id,
                  client_id: alldata[0].client_id,
                };
                axios
                  .post("https://jlmining.app/admin/getlocation_check", fdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.row;
                    // console.log("gttt");
                    // console.log(alldata);
                    if (alldata.length > 0) {
                      // setlocationname(alldata);
                      setvaluelocations(alldata[0].location_name);
                      setvaluelocationsId(alldata[0].location_id);
                      // setlocatename([]);
                    }
                  })
                  .catch((err) => {});
                axios
                  .post("https://jlmining.app/admin/getroster", formdata, {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.results;
                    //console.log("getroster");
                    //console.log(res.data.results);
                    const fn = [];
                    for (let i = 0; i < alldata.length; i++) {
                      fn.push(alldata[i].type);
                    }
                    setValueget(fn);
                  })
                  .catch((err) => {});
              }
            });
        }
        setDataclient([]);
        setlocatedname("");
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
        if (e.title === "Add") {
          setisClickedaddorEdit(false);
          setisClickedadd(true);
        } else {
          setisClickedaddorEdit(true);
          setisClickedadd(false);
        }
      }, 1000);
    } else {
      setshiftshow(true);
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        let formdata = {
          checkdate: e.start,
          user_id: e.user_id,
          shift: shifts,
        };
        axios
          .post("https://jlmining.app/admin/multiplerosteradd", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("getallcalender");
            getallcalender();
          })
          .catch((err) => {});
        setlocatedname("");
        setDataclient([]);
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
      }
    }
  };
  const handle_getClientlocate = (event) => {
    let formdata = {
      clientId: event.target.value,
      user_id: UserIdd,
      datess: setdate_s,
    };
    setDatessvalue(null);
    // console.log("aaa");
    // console.log(formdata);
    setvaluelocationsId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getlocaterostercheck", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.filteredResults;
        setlocatename(alldata);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Calender Page";
    handleSearch();
  }, [startDate, endDate]);
  const handleSearch = () => {
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);

    if (endDate !== "" && startDate !== "") {
      const currentDate = new Date(); // Get the current date
      const currentMonthIndex = currentDate.getMonth();
      var year = new Date().getFullYear();

      let formdata = {
        user_id: "",
        startDate: startDate,
        endDate: endDate,
      };
      axios
        .post(
          "https://jlmining.app/admin/getallCalendardetailForadminsearch",
          formdata,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          var alldata = res.data.maindata;
          // console.log(alldata);
          //var d = getday();
          const all_data = [];
          const all_groups = []; // Store groups here
          const all_items = [];
          for (let i = 0; i < alldata.length; i++) {
            //   console.log(alldata[i]);
            var cudate = new Date();
            var st_date = moment(cudate).format("YYYY-MM-DD");

            var clr = "";
            var id = alldata[i].attend_id;
            var signed = "";
            var hourstatus = alldata[i].hours_status;
            if (hourstatus === "Client") {
              var signed = "Signed";
            }
            if (alldata[i].id == "") {
              var title = "";
              var ic = "";
              var colr = "white";
              var pop = "Open";
              var cudate = new Date();

              var st_date = moment(cudate).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = "";
              var hours = "";
            }
            if (alldata[i].shift === "Day") {
              var title = "DS";
              var ic = <FaSun />;
              var colr = "#B2DAF2";
              var clr = "black";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Edit") {
              var title = "Edit | " + alldata[i].client_name;
              var ic = <FaEdit />;
              var pop = "Open";
              var colr = "gray";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Add") {
              var title = "Add";
              var ic = "";
              var pop = "Open";
              var colr = "white";
              var clr = "red";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Night") {
              var title = "DN";
              var ic = <FaMoon />;
              var colr = "#1D0303";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "FLIAM") {
              var title = "FLIAM";
              var ic = <FaPlaneArrival />;
              var colr = "#B9723D";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "FLIPM") {
              var title = "FLIPM";
              var ic = <FaPlaneArrival />;
              var colr = "#7DBFFC";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "FLOAM") {
              var title = "FLOAM";
              var ic = <FaPlaneDeparture />;
              var colr = "#9F20AA";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "FLOPM") {
              var title = "FLOPM";
              var ic = <FaPlaneDeparture />;
              var colr = "#77D928";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Sick Leave") {
              var title = "Sick Leave";
              var ic = <FaThermometer />;
              var colr = "#395AEF";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "AL") {
              var title = "AL";
              var ic = <FaUmbrella />;
              var colr = "#F71718";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Work Offsite") {
              var title = "Work Offsite";
              var ic = <FaUmbrella />;
              var colr = "#490909";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Leave Without Pay") {
              var title = "Leave Without Pay";
              var ic = "";
              var colr = "#F3DC0C";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "In Isolation on site") {
              var title = "In Isolation on site";
              var ic = <FaStar />;
              var colr = "#FB02F5";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "Work Camp") {
              var title = "Work Camp";
              var ic = <FaStar />;
              var colr = "#F8B70D";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            if (alldata[i].shift === "SC") {
              var title = "SC " + signed;
              var ic = "";
              var colr = "#0B5ED7";
              var clr = "black";
              var pop = "Close";
              var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

              var start = st_date;
              var end = st_date;
              var shft = alldata[i].shift;
              var hours = alldata[i].hours;
            }
            var fn = alldata[i].first_name;
            var mn = "";
            var ln = alldata[i].last_name;
            let findata = {
              title: title,
              roster: alldata[i].roster,
              start: start,
              end: end,
              user_id: alldata[i].user_id,
              color: clr,
              backgroundColor: colr,
              text: "center",
              icon: ic,
              pop: pop,
              id: id,
              shft: shft,
              hours: hours,
              loc_name: alldata[i].loc_name,
              client_name: alldata[i].client_name,
              username: fn + " " + mn + " " + ln,
            };

            all_data.push(findata);
          }

          setValuegetCalendar(all_data);
        })
        .catch((err) => {});
    }
  };
  const startDateObj = new Date(startDate); // Convert string to Date
  const endDateObj = new Date(endDate); // Convert string to Date

  const daysInRange =
    Math.ceil((endDateObj - startDateObj) / (1000 * 3600 * 24)) + 1;
  //console.log(startDateObj, "too", endDateObj);
  //console.log(startDate, "tttt", endDate);
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          margin-top:30px;
          z-index:99;

        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <div>
        <AdminHeader content={vlexp} />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-12">
                <div>
                  <section className="d-block py-5 timesheet_table">
                    <div className="container-xl">
                      <div className="d-flex flex-column gap-4">
                        <div className="table_Filter">
                          <form>
                            <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start  gap-3 w-100 justify-content-between">
                              {/* <div className="d-flex justify-content-between align-items-end gap-4"> */}
                              {/* <div className="filter-item">
                                  <label
                                    className="filter-label"
                                    htmlFor="employee"
                                  >
                                    Month
                                  </label>
                                  <select
                                    id="month-select"
                                    value={
                                      selectedMonth !== null
                                        ? selectedMonth
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleMonthClick(Number(e.target.value))
                                    }
                                    style={{
                                      padding: "13px",
                                      margin: "5px",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select a month
                                    </option>
                                    {months.map((month, index) => (
                                      <option key={month} value={index}>
                                        {month}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="filter-item">
                                  <label
                                    className="filter-label"
                                    htmlFor="employee"
                                  >
                                    Year
                                  </label>
                                  <input
                                    type="number"
                                    style={{
                                      padding: "10px",
                                      margin: "5px",
                                    }}
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    min="1800"
                                    placeholder="YYYY"
                                  />
                                </div> */}
                              {/* </div> */}
                              <div className="d-flex flex-column gap-1">
                                <label
                                  className="filter-label"
                                  htmlFor="employee"
                                >
                                  Start Date
                                </label>

                                <div className="d-flex align-items-center gap-4">
                                  <div className="filter-item">
                                    <input
                                      type="date"
                                      name="startDate"
                                      onChange={handleStartDate}
                                      style={{
                                        padding: "13px",
                                        margin: "5px",
                                      }}
                                    />
                                  </div>
                                  <h5>To </h5>

                                  <div className="filter-item">
                                    <label
                                      className="filter-label"
                                      htmlFor="employee"
                                    >
                                      End Date
                                    </label>
                                    <input
                                      type="date"
                                      name="endDate"
                                      onChange={handleEndDate}
                                      style={{
                                        padding: "13px",
                                        margin: "5px",
                                      }}
                                    />

                                    <input
                                      type="text"
                                      name="endDate"
                                      placeholder="...Search"
                                      style={{
                                        padding: "13px",
                                        margin: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        {shiftshow && (
                          <div className="d-block px-4">
                            <div className="row gy-3 list-weather align-items-center">
                              <div className="col-6 col-md-3">
                                <Link
                                  className="d-flex align-items-center gap-2"
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "fas fa-umbrella-beach",
                                      "#F71718",
                                      "AL"
                                    )
                                  }
                                >
                                  <div className="flex-shrink-0 icon-user-we">
                                    <i className="fas fa-umbrella-beach"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Annual Leave </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-treasure-chest",
                                      "#F8B70D",
                                      "Work Camp"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we bg-warning">
                                    <i className="far fa-treasure-chest"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Workers Comp </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "fas fa-plane-arrival",
                                      "#7DBFFC",
                                      "FLIPM"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we bgfip">
                                    <i className="fas fa-plane-arrival"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Fly in PM </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-plane-departure",
                                      "#9F20AA",
                                      "FLOAM"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we foa">
                                    <i className="far fa-plane-departure"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Fly out AM </h5>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className="row gy-3 list-weather align-items-center mt-4">
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-thermometer",
                                      "#395AEF",
                                      "Sick Leave"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we sl">
                                    <i className="far fa-thermometer"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Sick Leave </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-sun-cloud",
                                      "#B2DAF2",
                                      "Day"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we ds">
                                    <i className="far fa-sun-cloud text-dark"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Day's Worked, dayshift </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-plane-arrival",
                                      "#B9723D",
                                      "FLIAM"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we fia">
                                    <i className="far fa-plane-arrival"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Fly in AM </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-chimney",
                                      "#FB02F5",
                                      "In Isolation on site"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we iso">
                                    <i className="far fa-chimney"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>In Isolation on site </h5>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className="row gy-3 list-weather align-items-center mt-4">
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "fas fa-map-marker-times",
                                      "#F3DC0C",
                                      "Leave Without Pay"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we  lwp">
                                    <i className="fas fa-map-marker-times"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Leave Without Pay </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "far fa-moon-cloud",
                                      "#1D0303",
                                      "Night"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we ns">
                                    <i className="far fa-moon-cloud"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Day's Worked, NightShift </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "fas fa-plane-departure",
                                      "#77D928",
                                      "FLOPM"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we fop">
                                    <i className="fas fa-plane-departure"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Fly out PM </h5>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-6 col-md-3">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon(
                                      "fal fa-digging",
                                      "#490909",
                                      "Work Offsite"
                                    )
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we wo">
                                    <i className="fal fa-digging"></i>
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>Work Offsite </h5>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-6 col-md-3 mt-4">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    handle_getIcon("SC", "#0B5ED7", "SC")
                                  }
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="flex-shrink-0 icon-user-we sc">
                                    SC
                                  </div>
                                  <div className="list-we-name">
                                    {" "}
                                    <h5>SC </h5>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center my-3 text-danger">
                              <b>{shiftmessagevalue}</b>{" "}
                            </div>
                            {isClickedopen && (
                              <div className="d-flex gap-2 justify-content-center my-3">
                                <Link
                                  to=""
                                  className={iconClassName}
                                  style={{ backgroundColor }}
                                >
                                  <i className={iconset}>{sctext}</i>
                                </Link>
                              </div>
                            )}
                            <form onSubmit={handleSubmitSC}>
                              {/* <div className="row mt-4">

                            <div className="col-md-6">
                              <label className="mb-2 float-start font-weight-bold">Start Date</label>
                              <input
                                type="date"
                                name="start_date_shift"
                                required
                                value={startDateSC}
                                onChange={handleStartDateChange}
                                className="form-control mb-2"
                              />
                            </div>


                            <div className="col-md-6">
                              <label className="mb-2 float-start font-weight-bold">End Date</label>
                              <input
                                type="date"
                                name="end_date_shift"
                                required
                                value={endDateSC}
                                onChange={handleEndDateChange}
                                className="form-control mb-2"
                              />
                            </div>
                          </div> */}

                              {/* Error Message */}

                              {/* Submit Button */}
                              <div className="row mt-3">
                                {spinners && (
                                  <div
                                    class="spinner-border text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </div>
                              <span className="text-danger">
                                {missingdates}
                              </span>
                              <span className="text-success">
                                {missingdatessuccess}
                              </span>
                            </form>
                          </div>
                        )}
                        <div className="table-container">
                          <table className="styled-table">
                            <thead>
                              <tr>
                                <th style={{ fontSize: "12px" }}>
                                  Employee Name
                                </th>
                                <th style={{ fontSize: "12px" }}>Client</th>
                                <th style={{ fontSize: "12px" }}>
                                  Employee Role
                                </th>
                                <th style={{ fontSize: "12px" }}>Location</th>
                                <th style={{ fontSize: "12px" }}>RT</th>
                                {Array.from({ length: daysInRange }, (_, i) => {
                                  // Create a new Date object for each day in the range
                                  const currentDay = new Date(
                                    startDateObj.getTime()
                                  );
                                  currentDay.setDate(
                                    startDateObj.getDate() + i
                                  ); // Increment day by i

                                  const day = currentDay.getDate();
                                  const weekday = currentDay.getDay();

                                  return (
                                    <th key={i} style={{ fontSize: "12px" }}>
                                      {day.toString().padStart(2, "0")} <br />
                                      {dayAbbreviations[weekday]}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(groupedData).length === 1 ? (
                                <tr>
                                  <td
                                    colSpan={daysInMonth + 4}
                                    style={{
                                      textAlign: "center",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No results found
                                  </td>
                                </tr>
                              ) : (
                                Object.keys(groupedData).map((username) => {
                                  if (
                                    username === "undefined undefined undefined"
                                  ) {
                                    return ""; // Skip rendering if username is "undefined undefined undefined"
                                  }

                                  const userData = groupedData[username];
                                  const firstItem = userData[0];

                                  return (
                                    <tr key={username}>
                                      <td style={{ fontSize: "12px" }}>
                                        {username}
                                      </td>
                                      <td style={{ fontSize: "12px" }}>
                                        {firstItem ? firstItem.client_name : ""}
                                      </td>
                                      <td style={{ fontSize: "12px" }}>Role</td>
                                      <td style={{ fontSize: "12px" }}>
                                        {firstItem ? firstItem.loc_name : ""}
                                      </td>
                                      <td style={{ fontSize: "12px" }}>
                                        {firstItem ? firstItem.roster : ""}
                                      </td>
                                      {Array.from(
                                        { length: daysInRange },
                                        (_, i) => {
                                          const currentDay = new Date(
                                            startDateObj
                                          );
                                          currentDay.setDate(
                                            startDateObj.getDate() + i
                                          );

                                          const day = currentDay.getDate();
                                          const weekday = currentDay.getDay();

                                          // Format the date in YYYY-MM-DD format for comparison
                                          const formattedDate = `${selectedYear}-${(
                                            selectedMonth + 1
                                          )
                                            .toString()
                                            .padStart(2, "0")}-${day
                                            .toString()
                                            .padStart(2, "0")}`;

                                          // Find the data for the specific day
                                          const dataForDay = userData.find(
                                            (item) => {
                                              const itemDate = moment(
                                                item.start
                                              ).format("YYYY-MM-DD");
                                              return itemDate === formattedDate;
                                            }
                                          );

                                          console.log(formattedDate, "ee");
                                          if (dataForDay !== undefined) {
                                            return (
                                              <td
                                                key={i}
                                                style={{
                                                  backgroundColor:
                                                    dataForDay.backgroundColor,
                                                  color: dataForDay.color,
                                                  fontSize: "12px",
                                                  width: "150px",
                                                }}
                                                onClick={() =>
                                                  handleSelectEvent(dataForDay)
                                                }
                                                className="shiftspace"
                                              >
                                                {dataForDay.shft} <br />
                                                {dataForDay.hours} hrs
                                                {/* Add the select dropdown if the condition matches */}
                                                {dataForDay.shft !== "Add" &&
                                                dataForDay.shft !== "Edit" &&
                                                dataForDay.id !== undefined ? (
                                                  <div
                                                    className="shiftselect"
                                                    style={{
                                                      width: "41px",
                                                    }}
                                                  >
                                                    <select
                                                      className="w-100 mt-2"
                                                      value={dataForDay.hours}
                                                      onChange={(e) =>
                                                        handlesubmithours(
                                                          dataForDay.start,
                                                          e.target.value,
                                                          dataForDay.shft
                                                        )
                                                      }
                                                    >
                                                      <option value={""}>
                                                        Choose Hours
                                                      </option>
                                                      {numbers.map((number) => (
                                                        <option
                                                          key={number}
                                                          value={number}
                                                        >
                                                          {number}
                                                        </option>
                                                      ))}
                                                    </select>
                                                  </div>
                                                ) : null}
                                              </td>
                                            );
                                          } else {
                                            return <td key={i}></td>;
                                          }
                                        }
                                      )}
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Add Text Modal"
          className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
        >
          {" "}
          <form
            action="javascript:void(0)"
            onSubmit={handleAttend}
            method="post"
          >
            {showAlert?.type === "error" && (
              <Alert type="error" description="Already Exist" closable />
            )}
            {showAlert?.type === "success" && (
              <Alert
                type="success"
                description="Successfully created!"
                closable
              />
            )}
            <h4 className="mb-2"> Choose Roster For Attendance </h4>
            {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
            <div class="row">
              <div class="col-md-6">
                <label className="mb-2">Client Name</label>
                {isClickededit && (
                  <input
                    type="text"
                    name="clientId"
                    required
                    disabled
                    value={valueclient}
                    className="form-control mb-2"
                  />
                )}
                {isClickedadd && (
                  <select
                    className="form-control mb-2"
                    required
                    onChange={handle_getClient}
                    name="client_Id"
                  >
                    <option value={""}>Select value</option>
                    {Array.isArray(valueroster) ? (
                      valueroster.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                    ) : (
                      <option value=""></option>
                    )}
                  </select>
                )}
              </div>
              <div class="col-md-6">
                <label className="mb-2">Mine Site</label>
                {isClickededit && (
                  <input
                    type="text"
                    name="location"
                    required
                    disabled
                    value={valuelocations}
                    className="form-control mb-2"
                  />
                )}
                {isClickedadd && (
                  <select
                    className="form-control mb-2"
                    required
                    onChange={handle_getClientlocate}
                    name="location_id"
                  >
                    <option value={""}>Select value</option>
                    {Array.isArray(locationname) ? (
                      locationname.map((item, index) => (
                        <option value={item.id}>{item.location_name}</option>
                      ))
                    ) : (
                      <option value=""></option>
                    )}
                  </select>
                )}
              </div>
              <div class="col-md-6">
                <label className="mb-2">Roster</label>

                <input
                  type="text"
                  name="roster"
                  required
                  disabled
                  value={valueget}
                  className="form-control mb-2"
                />
              </div>
              <div class="col-md-6">
                <label className="mb-2">Shift</label>
                <select name="shift" required className="form-control mb-2">
                  <option value="">--Select--</option>
                  <option value="AL">Annual Leave</option>
                  <option value="Work Camp">Work Camp</option>
                  <option value="FLIPM">Fly in PM</option>
                  <option value="FLOAM">Fly out AM</option>
                  <option value="Sick Leave">Sick Leave</option>
                  <option value="Day">Day's Worked, dayshift</option>
                  <option value="FLIAM">Fly in AM </option>
                  <option value="In Isolation on site">
                    In Isolation on site
                  </option>
                  <option value="Leave Without Pay">Leave Without Pay </option>
                  <option value="Night">Day's Worked, NightShift</option>
                  <option value="FLOPM">Fly out PM </option>
                  <option value="Work Offsite">Work Offsite</option>
                </select>
              </div>
            </div>
            <button className="btn btn-success" type="submit">
              Save
            </button>
          </form>
        </Modal>
        <AdminFooter />
        <Modal
          isOpen={isModalOpen_hrs}
          onRequestClose={() => setIsModalOpen_hrs(false)}
          contentLabel="Add Text Modal"
          className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
        >
          {" "}
          <h3 className="text-success text-center fs-5">
            Hours has been updated
          </h3>
        </Modal>
      </div>
    </>
  );
};

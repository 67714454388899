import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminLocation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [gstart, setstart] = useState("");
  const [gend, setend] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClickedclient, setIsClickedclient] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const [desc, setdesc] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [locid, setlocid] = useState(false);
  const [options, setOptions] = useState([]);
  const handlegetLocation = (locationId, index) => {
    setlocid(locationId);
    let formdata = {
      locationId: locationId,
    };
    setActiveIndex(index);
    axios
      .post("https://jlmining.app/admin/getidlocation", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.locations.duration_start);
        getData(res.data.locations);
        setSelectedClientId(res.data.locations.client_id);
        const originalDatestart = res.data.locations.duration_start;
        const originalDateend = res.data.locations.duration_end;
        const parsedDate = new Date(originalDatestart);

        const day = String(parsedDate.getDate()).padStart(2, "0");
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const year = parsedDate.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setstart(formattedDate);

        const parsedDateend = new Date(originalDateend);

        const days = String(parsedDateend.getDate()).padStart(2, "0");
        const months = String(parsedDateend.getMonth() + 1).padStart(2, "0");
        const years = parsedDateend.getFullYear();
        const formattedDates = `${years}-${months}-${days}`;

        setend(formattedDates);
        setIsClicked(true);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const handlelocationRemove = (locationid) => {
    //console.log(locationid);
    let location_id = {
      location_id: locationid,
    };
    const del = window.confirm(
      "Are you sure you want to delete this location?"
    );
    if (del) {
      axios
        .post("https://jlmining.app/admin/locationdelete", location_id, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.status);
          console.log("sss");
          if (res.data.status === "1") {
            setShowAlert(true);
            setShowAlert({ type: "error" });
            setIsClicked(false);
            setActiveIndex(null);
            setdesc("Deleted successfully");
          } else {
            setdesc(
              "You have no permissions to delete this mine site, because this site use for roster"
            );
            setShowAlerts(true);
            setShowAlerts({ type: "errors" });
          }
          axios
            .get("https://jlmining.app/admin/getlocation", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    document.title = "Location Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getlocation", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setOptions(res.data.results);
      })
      .catch((err) => {});
  }, []);
  const handleedit = (event) => {
    setIsClickedclient(!isClickedclient);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      location_id: vll.location_id.value,
      client_id: vll.client_id.value,
      location_name: vll.location_name.value,
      nearest_town: vll.nearest_town.value,
      commodity: vll.commodity.value,
      contract_type: vll.contract_type.value,
      duration_start: vll.duration_start.value,
      duration_end: vll.duration_end.value,
      scope: vll.scope.value,
    };
    //console.log(formdata);
    // return false;
    axios
      .post("https://jlmining.app/admin/editlocation", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 2) {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .get("https://jlmining.app/admin/getlocation", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          let formdataa = {
            locationId: locid,
          };
          axios
            .post("https://jlmining.app/admin/getidlocation", formdataa, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.locations.duration_start);
              getData(res.data.locations);
              const originalDatestart = res.data.locations.duration_start;
              const originalDateend = res.data.locations.duration_end;
              const parsedDate = new Date(originalDatestart);

              const day = String(parsedDate.getDate()).padStart(2, "0");
              const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
              const year = parsedDate.getFullYear();
              const formattedDate = `${year}-${month}-${day}`;
              setstart(formattedDate);

              const parsedDateend = new Date(originalDateend);

              const days = String(parsedDateend.getDate()).padStart(2, "0");
              const months = String(parsedDateend.getMonth() + 1).padStart(
                2,
                "0"
              );
              const years = parsedDateend.getFullYear();
              const formattedDates = `${years}-${months}-${days}`;
              setSelectedClientId(res.data.locations.client_id);
              setend(formattedDates);
              setIsClicked(true);
            })
            .catch((err) => {
              //console.log(err.response.data);
            });
        }

        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedClientId(selectedValue);
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addlocation" className="addclient">
                  Add Location
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Mine site deleted successfully!"
                    closable
                  />
                )}
                {showAlerts?.type === "errors" && (
                  <Alert type="error" description={desc} closable />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Mining Locations</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(data) ? (
                        data.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetLocation(item.id, index)}
                          >
                            {item.location_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                {isClicked && (
                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row gap-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Location Name (Address):-</label>
                          <span className="fs-14">{gdata.location_name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Nearest Town:-</label>
                          <span className="fs-14">{gdata.nearest_town}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Client Name:-</label>
                          <span className="fs-14">{gdata.client_name}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Commodity:-</label>
                          <span className="fs-14">{gdata.commodity}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Contract Type:-</label>
                          <span className="fs-14">{gdata.contract_type}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Contract Duration:-</label>
                          <span className="fs-14">
                            {gdata.duration_start} to {gdata.duration_end}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Scope:-</label>
                          <span className="fs-14">{gdata.scope}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handlelocationRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="editclient"
                            onClick={() => handleedit(gdata.id)}
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isClickedclient && (
                  <div className="right-form-box bg-white shadow p-2 mt-4">
                    <h4 className="mb-3">Update Locations</h4>
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="Location has been successfully updated"
                        closable
                      />
                    )}
                    {showAlert?.type === "error" && (
                      <Alert
                        type="error"
                        description="You have no permission to change client name because this location already use in other roster."
                        closable
                      />
                    )}
                    <form action="" onSubmit={handleSubmit} method="post">
                      <div className="row gy-2">
                        <div className="col-12 col-lg-6">
                          <input
                            type="hidden"
                            name="location_id"
                            placeholder="type here"
                            Value={gdata.id}
                          />
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Location Name(Address){" "}
                              <span className="text-danger fs-14">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="type here"
                              required
                              name="location_name"
                              Value={gdata.location_name}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Nearest Town</label>
                            <input
                              type="text"
                              placeholder="type here"
                              name="nearest_town"
                              required
                              Value={gdata.nearest_town}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Client Name{" "}
                              <span className="text-danger fs-14">*</span>
                            </label>
                            <select
                              name="client_id"
                              id=""
                              onChange={handleSelectChange}
                              value={selectedClientId}
                              required
                            >
                              <option value="">--Select--</option>
                              {options.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Commodity </label>
                            <input
                              type="text"
                              placeholder="type here"
                              name="commodity"
                              Value={gdata.commodity}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Contract Type </label>
                            <input
                              type="text"
                              placeholder="type here"
                              name="contract_type"
                              Value={gdata.contract_type}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-5">
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              Start Enter Date{" "}
                              <span className="text-danger fs-14">*</span>
                            </label>
                            <input
                              type="date"
                              name="duration_start"
                              placeholder="enter date here"
                              required
                              Value={gstart}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-2">
                          <div className="d-block text-center pt-4">
                            <label>to</label>
                          </div>
                        </div>
                        <div className="col-12 col-lg-5">
                          <div className="d-flex flex-column gap-2">
                            <label for="">
                              End Enter Date{" "}
                              <span className="text-danger fs-14">*</span>
                            </label>
                            <input
                              type="date"
                              name="duration_end"
                              placeholder="enter date here"
                              Value={gend}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="d-flex flex-column gap-2">
                            <label for="">Scope</label>
                            <textarea
                              id=""
                              cols="30"
                              rows="4"
                              name="scope"
                              defaultValue={gdata.scope}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex align-items-center gap-3 justify-content-end">
                            <button className="sub-btn">Update</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};

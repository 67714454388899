import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash } from "react-icons/fa";
import Slider from "react-slick";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";

export const Assigncourse = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [checkedCourses, setCheckedCourses] = useState([]);
  const [errorarray, seterrorarray] = useState(null);
  const [checkedUsers, setcheckedUsers] = useState([]);
  const [closedd, setclosedd] = useState(false);
  const [openn, setopenn] = useState(true);
  const [activeTab, setactiveTab] = useState("first-tab");
  const [showAlert_r, setShowAlert_r] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [ispinnerset, spinnerset] = useState(false);
  const [filemain, setfilemain] = useState("");
  const [isallcourse, setallcourse] = useState([]);
  const [step1, setstep1] = useState(true);
  const [stepsection_1, setstepsection_1] = useState(true);
  const [stepsection_2, setstepsection_2] = useState(false);
  const [step2, setstep2] = useState(false);
  const [isalluser, setisalluser] = useState([]);
  const [stepsection_3, setstepsection_3] = useState(false);
  const [step3, setstep3] = useState(true);
  const [chmultidate, setchmultidate] = useState("");
  const [totalcourse, settotalcourse] = useState("");

  useEffect(() => {
    document.title = "Assign New Course Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      table: "elearningCourse",
    };
    axios
      .post("https://jlmining.app/getselectCommonForhazard", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/getalluseradmin", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setisalluser(res.data.results);
      })
      .catch((err) => {});
    settotalcourse(checkedCourses.length);
    if (checkedCourses.length > 0) {
      setstep1(false);
    } else {
      setstep1(true);
    }

    if (checkedUsers.length > 0) {
      setstep2(false);
    } else {
      setstep2(true);
    }
    if (chmultidate !== "") {
      setstep3(false);
    }
    const hasNonNullSelectedDate1 = checkedCourses.every(
      (course) => course.selectedDate !== null
    );
    console.log(hasNonNullSelectedDate1);
    if (hasNonNullSelectedDate1 === true) {
      setstep3(false);
    } else {
      setstep3(true);
    }
  }, [checkedCourses, checkedUsers, chmultidate, errorarray]);

  //Get User Time Sheet
  const handsubmit = (event) => {
    let formdata = {
      title: event.target.title.value,
      description: event.target.description.value,
      file: filemain,
    };
    axios
      .post("https://jlmining.app/admin/elearningsave", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        event.target.reset();
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        setfilemain("");
        setallcourse(res.data.results);
        if (res.data.results.length > 0) {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    const file_Name = file.name; // Get the file name
    if (file_Name != "") {
      setfilemain(file);
    } else {
      setfilemain("");
    }
  };
  const showhide = (e, index) => {
    setActiveIndex(index);
  };
  const removeskills = (e, index) => {
    let formdata = {
      id: e,
    };
    axios
      .post("https://jlmining.app/admin/removecourse", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setShowAlert_r(true);
        setShowAlert_r({ type: "success" });
        setTimeout(() => {
          setShowAlert(false);
          setShowAlert_r(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const checkopt = (event) => {
    setactiveTab(event);
    if (event === "home-tab") {
      setopenn(true);
      setclosedd(false);
    } else {
      setopenn(false);
      setclosedd(true);
    }
  };
  const searchinput = (event) => {
    var vl = event.target.value;
    let formdata = {
      search: vl,
    };
    console.log(formdata);
    axios
      .post("https://jlmining.app/searchcourse", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
      })
      .catch((err) => {});
  };
  const searchinputempl = (event) => {
    var vl = event.target.value;
    let formdata = {
      search: vl,
    };
    console.log(formdata);
    axios
      .post("https://jlmining.app/admin/searchempl", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setisalluser(res.data.results);
      })
      .catch((err) => {});
  };
  const checkbutton = (id, name) => {
    const isChecked = checkedCourses.some(
      (course) => course.id === id && course.name === name
    );

    if (isChecked) {
      // Uncheck the checkbox
      setCheckedCourses((prevCheckedCourses) =>
        prevCheckedCourses.filter(
          (course) => !(course.id === id && course.name === name)
        )
      );
    } else {
      // Check the checkbox
      setCheckedCourses((prevCheckedCourses) => [
        ...prevCheckedCourses,
        { id, name, selectedDate: null },
      ]);
    }
  };

  const nextstep = (event) => {
    if (event === "1") {
      console.log(checkedCourses.length);
      if (checkedCourses.length > 0) {
        setstepsection_2(true);
        setstepsection_1(false);
      }
      setactiveTab("second-tab");
    }
    if (event === "2") {
      if (checkedCourses.length > 0) {
        setstepsection_3(true);
        setstepsection_2(false);
      }
      setactiveTab("third-tab");
    }

    if (event === "3") {
      console.log(checkedUsers);
      console.log(checkedCourses);
      setstep3(true);
      let formdata = {
        users: checkedUsers,
        course: checkedCourses,
      };
      console.log(formdata);
      axios
        .post("https://jlmining.app/assignCourse", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.results);
          if (res.data.results.length > 0) {
            if (res.data.results[0].status === 2) {
              console.log(res.data.results[0]);
              seterrorarray(res.data.results[0].arr);
            } else {
              seterrorarray(null);
              setShowAlert(true);
              setShowAlert({ type: "success" });
              setTimeout(function () {
                window.location.reload();
              }, 3500);
            }
          } else {
            seterrorarray(null);
            setShowAlert(true);
            setShowAlert({ type: "success" });
            setTimeout(function () {
              window.location.reload();
            }, 3500);
          }
          // if (res.data.status === "1") {
          //   setTimeout(function () {
          //     window.location.reload();
          //   }, 3500);
          // }
        })
        .catch((err) => {});
    }
  };

  const choosedate = (event) => {
    console.log(event.target.value);
    const selectedDate = event.target.value;
    setchmultidate(selectedDate);
    const updatedCheckedCourses1 = checkedCourses.map((course) => ({
      ...course,
      selectedDate,
    }));
    setCheckedCourses(updatedCheckedCourses1);
  };
  const backbtn = (event) => {
    console.log(checkedCourses);
    if (event === "2") {
      setstepsection_2(false);
      setstepsection_1(true);

      setactiveTab("first-tab");
    }
    if (event === "3") {
      setstepsection_3(false);
      setstepsection_2(true);
      const selectedDate = null;
      setchmultidate(selectedDate);
      const updatedCheckedCourses1 = checkedCourses.map((course) => ({
        ...course,
        selectedDate,
      }));
      setCheckedCourses(updatedCheckedCourses1);
      setactiveTab("second-tab");
    }
  };

  const checkbuttonUser = (id) => {
    const isChecked = checkedUsers.includes(id);
    if (isChecked) {
      setcheckedUsers(checkedUsers.filter((courseId) => courseId !== id));
    } else {
      setcheckedUsers([...checkedUsers, id]);
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const choosedatemulti = (index, selectedDate) => {
    const updatedCheckedCourses = [...checkedCourses];
    updatedCheckedCourses[index].selectedDate = selectedDate;
    setCheckedCourses(updatedCheckedCourses);
  };
  const apiurl = "https://jlmining.app/uploads/elearning";
  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row mb-4">
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h4>Assign New Course</h4>
                <Link to="/admin/courselist" className="btn btn-secondary">
                  Back
                </Link>
              </div>
              <div className="col-md-12">
                <ul className="nav nav-tabs inci-tab" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "first-tab" ? "active" : ""
                      }`}
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Select courses
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "second-tab" ? "active" : ""
                      }`}
                      id="closed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#closed-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="closed-tab-pane"
                      aria-selected="true"
                    >
                      Select employees
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "third-tab" ? "active" : ""
                      }`}
                      id="closed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#closed-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="closed-tab-pane"
                      aria-selected="true"
                    >
                      Set due date
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {stepsection_1 && (
              <div className="row">
                <div className="d-flex">
                  <div className="col-md-6">
                    <label>Search</label>
                    <br />
                    <input
                      type="text"
                      class="serch"
                      onInput={searchinput}
                      name="daterange"
                      placeholder="Title, description"
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            )}
            {stepsection_1 && (
              <div className="row">
                <div className="container">
                  <div style={{ height: "60vh", overflow: "scroll" }}>
                    {isallcourse.map((item, index) => (
                      <div className="optionss">
                        <label>
                          <div className="d-flex gap-3 py-3">
                            <div className="flex-shrink-0">
                              <div className="dataimg">
                                <img
                                  src={apiurl + "/" + item.file}
                                  alt="image"
                                />
                              </div>
                            </div>

                            <div className="flex-grow-1">
                              <div className="d-flex flex-column gap-2 data justify-content-center">
                                <div className="d-flex flex-column gap-1">
                                  <span className="cotile">{item.title} </span>
                                  <span className=" cotiming">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 32 32"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M16 29.875C8.275 29.875 2 23.6 2 15.875C2 8.15 8.275 1.875 16 1.875C23.725 1.875 30 8.15 30 15.875C30 23.6 23.725 29.875 16 29.875ZM16 3.875C9.387 3.875 4 9.262 4 15.875C4 22.488 9.387 27.875 16 27.875C22.613 27.875 28 22.488 28 15.875C28 9.262 22.613 3.875 16 3.875Z"></path>
                                      <path d="M23 18H15C14.45 18 14 17.55 14 17V9C14 8.45 14.45 8 15 8C15.55 8 16 8.45 16 9V16H23C23.55 16 24 16.45 24 17C24 17.55 23.55 18 23 18Z"></path>
                                    </svg>{" "}
                                    40 minutes{" "}
                                  </span>{" "}
                                </div>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            onChange={() => checkbutton(item.id, item.title)}
                            name="course"
                            checked={checkedCourses.some(
                              (course) =>
                                course.id === item.id &&
                                course.name === item.title
                            )}
                          />
                          <span className="checkmarks"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <button
                        className={`sub-btn rounded p-2 s ${
                          step1 ? "step1" : ""
                        }`}
                        type="button"
                        onClick={() => nextstep("1")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stepsection_2 && (
              <div className="row">
                <div className="d-flex">
                  <div className="col-md-6">
                    <label>Search</label>
                    <br />
                    <input
                      type="text"
                      class="serch"
                      onInput={searchinputempl}
                      name="daterange"
                      placeholder="Name"
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            )}
            {stepsection_2 && (
              <div className="row">
                <div className="container">
                  <div style={{ height: "350px", overflow: "scroll" }}>
                    {isalluser.map((item, index) => (
                      <div className="optionss" style={{ height: "70px" }}>
                        <label>
                          <div className="d-flex gap-3 ">
                            <div className="flex-shrink-0">
                              <div className="dataimg ">
                                <div
                                  className="rounded-circle"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#c62026",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    color: "white",
                                    margin: "15px",
                                  }}
                                >
                                  {item &&
                                    item.first_name &&
                                    item.last_name && (
                                      <>
                                        {item.first_name.charAt(0)}{" "}
                                        {item.last_name.charAt(0)}
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="flex-grow-1">
                              <div className="d-flex flex-column gap-2 data justify-content-center">
                                <span className="cotile">
                                  {item.step2_title} {item.first_name}{" "}
                                  {item.last_name}
                                </span>
                                <p>
                                  <strong>Job</strong> Not specified
                                </p>
                              </div>
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            onChange={() => checkbuttonUser(item.id)}
                            name="course"
                            checked={checkedUsers.includes(item.id)}
                          />
                          <span className="checkmarks"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <button
                        className="sub-btn rounded p-2"
                        onClick={() => backbtn("2")}
                      >
                        Back
                      </button>
                      <button
                        className={`sub-btn rounded p-2 ${
                          step2 ? "step1" : ""
                        }`}
                        type="button"
                        onClick={() => nextstep("2")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stepsection_3 && (
              <div className="row">
                <div className="d-flex">
                  <div className="col-md-6">
                    <label>Set to all</label>
                    <br />
                    <input
                      type="date"
                      class="serch"
                      onChange={choosedate}
                      name="daterange"
                      placeholder="Name, job, site"
                      min={getCurrentDate()}
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            )}
            {stepsection_3 && (
              <div className="row">
                {checkedCourses.map((item, index) => (
                  <div className="col-md-3">
                    <label>{item.name}</label>
                    <br />
                    <input
                      type="date"
                      class="serch"
                      onChange={(event) =>
                        choosedatemulti(index, event.target.value)
                      }
                      defaultValue={chmultidate}
                      name="daterange"
                      min={getCurrentDate()}
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                ))}
              </div>
            )}
            {stepsection_3 && (
              <div className="row">
                <div className="container">
                  <div style={{ height: "150px" }}></div>
                  {showAlert?.type === "success" && (
                    <Alert
                      type="success"
                      className="text-success "
                      description="Course assign successfully successfully"
                      closable
                    />
                  )}
                  {errorarray ? (
                    <span className="text-danger">
                      <b>{errorarray.course}</b> course already assign to{" "}
                      <b>{errorarray.name}</b>
                    </span>
                  ) : (
                    <div className="no-data-message"></div>
                  )}

                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <button
                        className="sub-btn rounded p-2"
                        onClick={() => backbtn("3")}
                      >
                        Back
                      </button>
                      <button
                        className={`sub-btn rounded p-2 ${
                          step3 ? "step1" : ""
                        }`}
                        type="button"
                        onClick={() => nextstep("3")}
                      >
                        Assign {totalcourse} Course
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};

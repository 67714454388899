import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
import Modal from "react-modal";
import { FaFilePdf } from "react-icons/fa";
import generatePDF from "./PDFGenerationtimesheet";
import imlogo from "../assets/admin/images/logo2.png";
export const AdminTimesheet = () => {
  const apiurl = "https://jlmining.app/uploads/";
  const [isActive, setIsClicked] = useState(false);
  const [isActiveno, setIsClickedno] = useState(false);
  const [isActiveno_time, setIsClickedno_time] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indx, setindx] = useState(null);
  const [emData, setData] = useState([]);
  const [emDatatimesheet, setDatatimesheet_data] = useState([]);
  const [timesheet, setDataTimesheet] = useState([]);
  const [empnamevalue, empname] = useState("");
  const [imgurll, setimgurll] = useState("");
  const [empstartdatevalue, empstartdate] = useState("");
  const [textfiled, settextfiled] = useState("");
  const [UserIdvalue, setUserId] = useState("");
  const [startend, setstartend] = useState("");
  const [Datakms, setDatakms] = useState(0);
  const [Datahrs, setDatahrs] = useState(0);
  const [ticketfile, setticketfile] = useState(0);
  const [otherfile, setotherfile] = useState(0);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Time Sheet Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getemployee", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("getemployee");
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});

    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDivVisible]);
  const handleInputChange = (event) => {
    console.log(event.target.value);
    let user_id = {
      textarea: event.target.value,
    };
    axios
      .post("https://jlmining.app/admin/texareaedit", user_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("s");
        console.log(res.data.row[0]);
        if (res.data.row[0] != "") {
          settextfiled(res.data.row[0].textarea);
        }
      })
      .catch((err) => {});
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };
  //Get User Time Sheet
  const handlegettimesheet = (event, index, f, l) => {
    console.log(event);
    setindx(index);
    setUserId(event);
    setstartend([]);
    setDataTimesheet([]);
    var n = f + " " + l;
    empname(n);
    let user_id = {
      user_id: event,
    };
    let ss = {
      textarea: "",
    };
    axios
      .post("https://jlmining.app/admin/getuser_timesheet", user_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("timesheet");
        console.log(res.data.results.length);
        if (res.data.results.length > 0) {
          setIsClickedno_time(true);
          setDatatimesheet_data(res.data.results);
        } else {
          setIsClickedno_time(false);
          setDatatimesheet_data([]);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getweeklytimesheet", user_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("we");
        console.log(res.data.arr);
        setstartend(res.data.arr);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/gettexareaedit", ss, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("ssss");
        console.log(res.data.row[0]);
        if (res.data.row[0] != "") {
          settextfiled(res.data.row[0].textarea);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getTimesheet", user_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          if (res.data.data !== "") {
            empstartdate(res.data.data[0].nd);
            setIsClickedno(false);
            setIsClicked(true);
            console.log(res.data.data);
            //setDataTimesheet(res.data.data);
          }
        } else {
          setIsClickedno(true);
          setIsClicked(false);
        }
      })
      .catch((err) => {});
  };
  const getimageurl = (imgurl) => {
    console.log(imgurl);
    if (imgurl != "") {
      setimgurll(imgurl);
      setIsModalOpen(true);
    }
  };
  const crosspopup = () => {
    setIsModalOpen(false);
  };
  const getuserweeklydata = (start, end, user_id) => {
    let data = {
      start: start,
      end: end,
      user_id: user_id,
    };
    console.log(data);
    axios
      .post("https://jlmining.app/admin/getuserweeklydata", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataTimesheet(res.data.data);
        if (res.data.data.length > 0) {
          if (res.data.data !== "") {
            empstartdate(res.data.data[0].nd);
            setIsClickedno(false);
            setIsClicked(true);
            console.log(res.data.data);
          }
        } else {
          setIsClickedno(true);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getuserweeklytraveldata", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDatakms(res.data.row[0].kmss);
        setDatahrs(res.data.row[0].hrs);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getuserweeklytravelrecipt", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.result.length > 0) {
          var ft = [];
          var o_tt = [];
          for (let tt = 0; tt < res.data.result.length; tt++) {
            var t = res.data.result[tt].ticket_file;
            if (t != "" && t != null) {
              var ft = t;
            }
          }
          for (let ot = 0; ot < res.data.result.length; ot++) {
            var o = res.data.result[ot].other_file;
            if (o != "" && o != null) {
              var o_tt = o;
            }
          }
          if (o_tt != "" && o_tt != null) {
            const fileo = JSON.parse(o_tt);
            console.log(fileo);
            setotherfile(fileo);
          } else {
            setotherfile([]);
          }

          if (ft != "" && ft != null) {
            const filet = JSON.parse(ft);
            console.log(filet);
            setticketfile(filet);
          } else {
            setticketfile([]);
          }
        }
      })
      .catch((err) => {});
  };
  const handleDownload = (m, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = m;
    link.click();
  };
  function getTotalHoursByDay(dateArray) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const totalHours = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };

    // Iterate through the array of dates and sum the hours by day
    dateArray.forEach((item) => {
      console.log(item.nd);
      const date = new Date(item.nd);
      const dayOfWeek = daysOfWeek[date.getDay()]; // Get the day of the week (0 = Sunday, 1 = Monday, ...)
      totalHours[dayOfWeek] += item.hours;
    });

    return totalHours;
  }
  const tableStyle = {
    border: "1px solid #dededf",
    height: "100%",
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: "1px",
    textAlign: "left",
  };
  const thTdStyle = {
    border: "1px solid #dededf",
    padding: "5px",
    fontSize: "10px", // Corrected from 'fontsize' to 'fontSize'
  };

  const stylelogo = {
    width: "30%",
    display: "block",
    margin: "0",
  };
  const captionStyle = {
    captionSide: "top",
    textAlign: "left",
  };

  const tableComponentStyle = {
    overflow: "auto",
    width: "100%",
  };

  const linkStyle = {
    marginTop: "8px",
  };
  const handlepdf = () => {
    const totalHours = timesheet.reduce((accum, entry) => {
      return accum + parseInt(entry.hours); // Summing hours for the specified client_id
    }, 0);
    const first = timesheet[0];
    let lastElement = timesheet[timesheet.length - 1];

    const mainpdf = (
      <div>
        <section className="d-block py-4 pdf_time">
          <div className="container-xl">
            <div className="row gy-5">
              <div className="col-12">
                <div className="d-flex flex-column gap-5">
                  <div className="d-flex flex-column align-items-start">
                    <img src={imlogo} style={stylelogo} alt="Logo" />
                  </div>
                  <h1 className="text-center">Time Sheet</h1>
                  <div className="d-flex flex-column gap-2">
                    <h4 className="text-start">
                      Total Hours : <span>{totalHours}</span>
                    </h4>
                    <div className="d-flex flex-wrap justify-content-between gap-2 align-items-center">
                      <h4>
                        Employee Name : <span>{empnamevalue}</span>
                      </h4>
                      <h4>
                        Start Date : <span>{empstartdatevalue}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div
                  className="table_component"
                  role="region"
                  tabIndex="0"
                  style={tableComponentStyle}
                >
                  <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Day
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Location
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Roster Icon
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Hours
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Client
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Client Signature
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Sick Leave
                        </th>
                        <th
                          style={{
                            ...thTdStyle,
                            backgroundColor: "#eceff1",
                            color: "#000000",
                          }}
                        >
                          Annual Leave
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(timesheet) ? (
                        timesheet.map((item, index) => (
                          <tr key={index}>
                            <td style={thTdStyle}>{item.dd}</td>
                            <td style={thTdStyle}>{item.nd}</td>
                            <td style={thTdStyle}>{item.location_name}</td>

                            {item.shift === "Add" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6 className="iconroster text-center m-0">
                                  Add
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Edit" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6 className="iconroster text-center m-0">
                                  N/A
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Day" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#B2DAF2",
                                    border: "1px solid #B2DAF2",
                                  }}
                                >
                                  {" "}
                                  DS{" "}
                                  <i
                                    className="far fa-sun-cloud"
                                    style={{ color: "black" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Night" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#1D0303",
                                    border: "1px solid #1D0303",
                                    color: "white",
                                  }}
                                >
                                  {" "}
                                  NS{" "}
                                  <i
                                    className="far fa-moon-cloud"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "FLIAM" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#B9723D",
                                    border: "1px solid #B9723D",
                                  }}
                                >
                                  {" "}
                                  FIA{" "}
                                  <i
                                    className="far fa-plane-arrival"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "FLIPM" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#7DBFFC",
                                    border: "1px solid #7DBFFC",
                                  }}
                                >
                                  {" "}
                                  FIP{" "}
                                  <i
                                    className="fas fa-plane-arrival"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "FLOAM" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#9F20AA",
                                    border: "1px solid #9F20AA",
                                  }}
                                >
                                  {" "}
                                  FOA{" "}
                                  <i
                                    className="far fa-plane-departure"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "FLOPM" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#77D928",
                                    border: "1px solid #77D928",
                                  }}
                                >
                                  {" "}
                                  FOP{" "}
                                  <i
                                    className="fas fa-plane-departure"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Sick Leave" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#395AEF",
                                    border: "1px solid #395AEF",
                                  }}
                                >
                                  {" "}
                                  SL{" "}
                                  <i
                                    className="far fa-thermometer"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "AL" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#F71718",
                                    border: "1px solid #F71718",
                                  }}
                                >
                                  {" "}
                                  AL{" "}
                                  <i
                                    className="fas fa-umbrella-beach"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Work Offsite" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#490909",
                                    border: "1px solid #490909",
                                  }}
                                >
                                  {" "}
                                  WO{" "}
                                  <i
                                    className="fal fa-digging"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Leave Without Pay" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#F3DC0C",
                                    border: "1px solid #F3DC0C",
                                  }}
                                >
                                  {" "}
                                  LWP{" "}
                                  <i
                                    className="fas fa-map-marker-times"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "In Isolation on site" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#FB02F5",
                                    border: "1px solid #FB02F5",
                                  }}
                                >
                                  {" "}
                                  ISO{" "}
                                  <i
                                    className="far fa-chimney"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "SC" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#0B5ED7",
                                    border: "1px solid #0B5ED7",
                                  }}
                                >
                                  {" "}
                                  <i className="" style={{ color: "white" }}>
                                    SC
                                  </i>{" "}
                                </h6>{" "}
                              </td>
                            ) : item.shift === "Work Camp" ? (
                              <td style={thTdStyle}>
                                {" "}
                                <h6
                                  className="iconroster text-center m-0"
                                  style={{
                                    backgroundColor: "#F8B70D",
                                    border: "1px solid #F8B70D",
                                  }}
                                >
                                  {" "}
                                  WC{" "}
                                  <i
                                    className="far fa-treasure-chest"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                </h6>{" "}
                              </td>
                            ) : (
                              <td style={thTdStyle}>
                                <span>Default Content</span>
                              </td>
                            )}

                            <td style={thTdStyle}>{item.admin_view_hours}</td>
                            <td style={thTdStyle}>{item.name}</td>

                            {item.signature_img !== "" ? (
                              <td style={thTdStyle}>
                                <img
                                  className="signsheet"
                                  src={item.signature_img}
                                  alt="signature"
                                />
                              </td>
                            ) : (
                              <td style={thTdStyle}>
                                <h6>No Signature</h6>
                              </td>
                            )}

                            {item.shift === "Sick Leave" ? (
                              <td style={thTdStyle}>
                                <h6>SL</h6>
                              </td>
                            ) : (
                              <td style={thTdStyle}>
                                <h6></h6>
                              </td>
                            )}

                            {item.shift === "AL" ? (
                              <td style={thTdStyle}>
                                <h6>AL</h6>
                              </td>
                            ) : (
                              <td style={thTdStyle}>
                                <h6></h6>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" style={thTdStyle}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>

                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
    generatePDF(mainpdf);
  };
  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-5">
              <div className="col-md-2">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Employee</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link
                            className={`${index === indx ? "active" : ""}`}
                            to="javascript:void(0)"
                            onClick={() =>
                              handlegettimesheet(
                                item.id,
                                index,
                                item.first_name,
                                item.last_name
                              )
                            }
                          >
                            {item.step2_title} {""}
                            {item.first_name} {item.last_name}
                          </Link>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-10">
                <div className="d-flex flex-md-row flex-column gap-2 pb-4 gap-md-5">
                  {isActive && (
                    <div className="d-block red-heading p-2 maintimesheet">
                      {Array.isArray(startend) ? (
                        startend.map((item, index) => (
                          <div className="mb-2 fw-bold shadow  timesheet p-2 text-center">
                            <Link
                              className="text-danger"
                              to="javascript:void(0)"
                              onClick={() =>
                                getuserweeklydata(
                                  formatDate(item.start), // Format start date
                                  formatDate(item.end), // Format end date
                                  item.user_id
                                )
                              }
                            >
                              Time sheet from {formatDate(item.start)} to{" "}
                              {formatDate(item.end)}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  )}
                  {isActiveno_time && (
                    <div className="d-block red-heading p-2 maintimesheet ss">
                      <h4 className="text-danger pb-3">Manual Time Sheet</h4>
                      <div className="d-block  bg-white shadow p-2">
                        <div className="over-empl heit">
                          <div className="d-flex flex-column  clientselectmain ">
                            {Array.isArray(emDatatimesheet) ? (
                              emDatatimesheet.map((item, index) => (
                                <Link
                                  className="text-danger"
                                  onClick={() =>
                                    handleDownload(
                                      item.file,
                                      `https://jlmining.app/uploads/${item.file}`
                                    )
                                  }
                                  to="javascript:void(0)"
                                >
                                  {item.file}
                                </Link>
                              ))
                            ) : (
                              <Link> No data available </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isActive && (
                  <div className="d-flex flex-column gap-2  bg-white shadow p-5 timesheet-boxes">
                    <h5 className="text-black text-center">Time Sheet</h5>
                    <div className="d-flex justify-content-end">
                      <button
                        title="Download"
                        type="button"
                        onClick={() => handlepdf()}
                        className="addclientappfile "
                      >
                        <FaFilePdf />
                      </button>
                    </div>

                    <div className="table-responsive pb-4">
                      <div style={{ display: isDivVisible ? "block" : "none" }}>
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <tr>
                                <td>
                                  <h6 className="">Employee Name:</h6>
                                </td>
                                <td>
                                  <p className="">{empnamevalue}</p>
                                </td>
                                {/* <td>
                                        <h6 className="">CLIENT REP Name:</h6>
                                    </td>
                                    <td>
                                        <p className="">text</p>
                                    </td> */}
                              </tr>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="">Employee Signature:</h6>
                              </td>
                              <td>
                                <p>{empnamevalue}</p>
                              </td>
                              {/* <td><h6 className="">CLIENT REP Signature</h6></td>
                                <td><p>text</p></td> */}
                              <td>
                                <h6 className=""> Date</h6>
                              </td>
                              <td>
                                <p>{empstartdatevalue}</p>
                              </td>
                            </tr>
                          </thead>
                        </table>

                        <div className="table-main">
                          <div className="main-theadbox">
                            <div className="t1">
                              {" "}
                              <h6>Day</h6>
                            </div>
                            <div className="t2">
                              {" "}
                              <h6>Date</h6>
                            </div>
                            <div className="t3">
                              {" "}
                              <h6>Location</h6>
                            </div>
                            <div className="t4">
                              {" "}
                              <h6>Roster Icon</h6>
                            </div>
                            <div className="t5">
                              {" "}
                              <h6>Hours</h6>
                            </div>
                            <div className="t6">
                              {" "}
                              <h6>Client</h6>
                            </div>
                            <div className="t7">
                              {" "}
                              <h6>Client Signature</h6>
                            </div>
                            <div className="t8">
                              {" "}
                              <h6>Sick Leave</h6>
                            </div>
                            <div className="t9">
                              {" "}
                              <h6>Annual Leave</h6>
                            </div>
                          </div>

                          <div className="main-date-scroll">
                            {Array.isArray(timesheet) ? (
                              timesheet.map((item, index) => (
                                <div className="main-theadboxes">
                                  <div className="t1">
                                    {" "}
                                    <h6>{item.dd}</h6>
                                  </div>
                                  <div className="t2">
                                    {" "}
                                    <h6>{item.nd}</h6>
                                  </div>
                                  <div className="t3">
                                    {" "}
                                    <h6>{item.location_name}</h6>
                                  </div>
                                  {item.shift === "Add" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6 className="iconroster text-center m-0 ">
                                        Add{" "}
                                      </h6>
                                    </div>
                                  ) : item.shift === "Edit" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6 className="iconroster text-center m-0 ">
                                        N/A
                                      </h6>
                                    </div>
                                  ) : item.shift === "Day" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#B2DAF2",
                                          border: "1px solid #B2DAF2",
                                        }}
                                      >
                                        DS{" "}
                                        <i
                                          className="far fa-sun-cloud"
                                          style={{ color: "black" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "Night" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#1D0303",
                                          border: "1px solid #1D0303",
                                          color: "white",
                                        }}
                                      >
                                        NS{" "}
                                        <i
                                          className="far fa-moon-cloud"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "FLIAM" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#B9723D",
                                          border: "1px solid #B9723D",
                                        }}
                                      >
                                        FIA{" "}
                                        <i
                                          className="far fa-plane-arrival"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "FLIPM" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#7DBFFC",
                                          border: "1px solid #7DBFFC",
                                        }}
                                      >
                                        FIP{" "}
                                        <i
                                          className="fas fa-plane-arrival"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "FLOAM" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#9F20AA",
                                          border: "1px solid #9F20AA",
                                        }}
                                      >
                                        FOA{" "}
                                        <i
                                          className="far fa-plane-departure"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "FLOPM" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#77D928",
                                          border: "1px solid #77D928",
                                        }}
                                      >
                                        FOP{" "}
                                        <i
                                          className="fas fa-plane-departure"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "Sick Leave" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#395AEF",
                                          border: "1px solid #395AEF",
                                        }}
                                      >
                                        SL{" "}
                                        <i
                                          className="far fa-thermometer"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "AL" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#F71718",
                                          border: "1px solid #F71718",
                                        }}
                                      >
                                        AL{" "}
                                        <i
                                          className="fas fa-umbrella-beach"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "Work Offsite" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#490909",
                                          border: "1px solid #490909",
                                        }}
                                      >
                                        WO{" "}
                                        <i
                                          className="fal fa-digging"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "Leave Without Pay" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#F3DC0C",
                                          border: "1px solid #F3DC0C",
                                        }}
                                      >
                                        LWP{" "}
                                        <i
                                          className="fas fa-map-marker-times"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "In Isolation on site" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#FB02F5",
                                          border: "1px solid #FB02F5",
                                        }}
                                      >
                                        ISO{" "}
                                        <i
                                          className="far fa-chimney"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : item.shift === "SC" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#0B5ED7",
                                          border: "1px solid #0B5ED7",
                                        }}
                                      >
                                        SC{" "}
                                      </h6>
                                    </div>
                                  ) : item.shift === "Work Camp" ? (
                                    <div className="t4">
                                      {" "}
                                      <h6
                                        className="iconroster text-center m-0 "
                                        style={{
                                          backgroundColor: "#F8B70D",
                                          border: "1px solid #F8B70D",
                                        }}
                                      >
                                        WC{" "}
                                        <i
                                          className="far fa-treasure-chest"
                                          style={{ color: "white" }}
                                        ></i>
                                      </h6>
                                    </div>
                                  ) : (
                                    <span>Default Content</span>
                                  )}

                                  <div className="t5">
                                    {" "}
                                    <h6>{item.admin_view_hours}</h6>
                                  </div>
                                  <div className="t6">
                                    {" "}
                                    <h6>{item.name}</h6>
                                  </div>
                                  {item.signature_img != "" ? (
                                    <div className="t7">
                                      {" "}
                                      <img
                                        className="signsheet"
                                        src={item.signature_img}
                                      />
                                    </div>
                                  ) : (
                                    <div className="t7">
                                      {" "}
                                      <h6>{}</h6>
                                    </div>
                                  )}
                                  {item.shift === "Sick Leave" ? (
                                    <div className="t8">
                                      {" "}
                                      <h6>SL</h6>
                                    </div>
                                  ) : (
                                    <div class="t8">
                                      {" "}
                                      <h6>{}</h6>
                                    </div>
                                  )}
                                  {item.shift === "AL" ? (
                                    <div className="t9">
                                      {" "}
                                      <h6>AL</h6>
                                    </div>
                                  ) : (
                                    <div className="t9">
                                      {" "}
                                      <h6>{}</h6>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <Link> No data available </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="mob_table table-responsive"
                        style={{ display: isDivVisible ? "none" : "block" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Date</th>
                              <th>Location</th>
                              <th>Roster Icon</th>
                              <th>Hours</th>
                              <th>Client</th>
                              <th>Client Signature</th>
                              <th>Sick Leave</th>
                              <th>Annual Leave</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(timesheet) ? (
                              timesheet.map((item, index) => (
                                <tr>
                                  <td data-label="Heading 1">{item.dd}</td>
                                  <td data-label="Heading 2">{item.nd}</td>
                                  <td data-label="Heading 12">
                                    {item.location_name}
                                  </td>
                                  <td data-label="Heading 12">
                                    {item.shift === "Day" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#B2DAF2",
                                            border: "1px solid #B2DAF2",
                                          }}
                                        >
                                          DS{" "}
                                          <i
                                            className="far fa-sun-cloud"
                                            style={{ color: "black" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "Night" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#1D0303",
                                            border: "1px solid #1D0303",
                                            color: "white",
                                          }}
                                        >
                                          NS{" "}
                                          <i
                                            className="far fa-moon-cloud"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "FLIAM" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#B9723D",
                                            border: "1px solid #B9723D",
                                          }}
                                        >
                                          FIA{" "}
                                          <i
                                            className="far fa-plane-arrival"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "FLIPM" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#7DBFFC",
                                            border: "1px solid #7DBFFC",
                                          }}
                                        >
                                          FIP{" "}
                                          <i
                                            className="fas fa-plane-arrival"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "FLOAM" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#9F20AA",
                                            border: "1px solid #9F20AA",
                                          }}
                                        >
                                          FOA{" "}
                                          <i
                                            className="far fa-plane-departure"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "FLOPM" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#77D928",
                                            border: "1px solid #77D928",
                                          }}
                                        >
                                          FOP{" "}
                                          <i
                                            className="fas fa-plane-departure"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "Sick Leave" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#395AEF",
                                            border: "1px solid #395AEF",
                                          }}
                                        >
                                          SL{" "}
                                          <i
                                            className="far fa-thermometer"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "AL" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#F71718",
                                            border: "1px solid #F71718",
                                          }}
                                        >
                                          AL{" "}
                                          <i
                                            className="fas fa-umbrella-beach"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "Work Offsite" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#490909",
                                            border: "1px solid #490909",
                                          }}
                                        >
                                          WO{" "}
                                          <i
                                            className="fal fa-digging"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "Leave Without Pay" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#F3DC0C",
                                            border: "1px solid #F3DC0C",
                                          }}
                                        >
                                          LWP{" "}
                                          <i
                                            className="fas fa-map-marker-times"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift ===
                                      "In Isolation on site" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#FB02F5",
                                            border: "1px solid #FB02F5",
                                          }}
                                        >
                                          ISO{" "}
                                          <i
                                            className="far fa-chimney"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : item.shift === "Work Camp" ? (
                                      <div className="t4">
                                        {" "}
                                        <h6
                                          className="iconroster text-center m-0 "
                                          style={{
                                            backgroundColor: "#F8B70D",
                                            border: "1px solid #F8B70D",
                                          }}
                                        >
                                          WC{" "}
                                          <i
                                            className="far fa-treasure-chest"
                                            style={{ color: "white" }}
                                          ></i>
                                        </h6>
                                      </div>
                                    ) : (
                                      <span>Default Content</span>
                                    )}
                                  </td>

                                  <td data-label="Heading 2">
                                    {item.admin_view_hours}
                                  </td>
                                  <td data-label="Heading 12">{item.name}</td>
                                  <td data-label="Heading 1">
                                    {item.signature_img != "" ? (
                                      <div className="t7">
                                        {" "}
                                        <img
                                          className="signsheet"
                                          src={item.signature_img}
                                        />
                                      </div>
                                    ) : (
                                      <div className="t7">
                                        {" "}
                                        <h6>{}</h6>
                                      </div>
                                    )}
                                  </td>
                                  <td data-label="Heading 2">
                                    {item.shift === "Sick Leave" ? (
                                      <div className="t8">
                                        {" "}
                                        <h6>SL</h6>
                                      </div>
                                    ) : (
                                      <div class="t8">
                                        {" "}
                                        <h6>{}</h6>
                                      </div>
                                    )}
                                  </td>
                                  <td data-label="Heading 2">
                                    {item.shift === "AL" ? (
                                      <div className="t9">
                                        {" "}
                                        <h6>AL</h6>
                                      </div>
                                    ) : (
                                      <div className="t9">
                                        {" "}
                                        <h6>{}</h6>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <Link> No data available </Link>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-6">
                              <span>Traveling Distance (KmS)</span>
                            </div>
                            <div className="col-md-6">
                              <span>{Datakms}</span>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-6">
                              <span>Traveling Hours:</span>
                            </div>
                            <div className="col-md-6">
                              <span>{Datahrs}</span>
                            </div>
                          </div>
                          <div className="row  mt-4">
                            <div className="col-md-6">
                              <span>Traveling Receipts:</span>
                            </div>
                            <div className="col-md-6">
                              {Array.isArray(ticketfile) ? (
                                ticketfile.map((fileName, index) => (
                                  <div className="mt-2" key={index}>
                                    <a
                                      href={`${apiurl}/${fileName}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {fileName}
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <div>No data available</div>
                              )}
                            </div>
                          </div>
                          <div className="row  mt-4">
                            <div className="col-md-6">
                              <span>Other Expenses:</span>
                            </div>
                            <div className="col-md-6">
                              {Array.isArray(otherfile) ? (
                                otherfile.map((fileName, index) => (
                                  <div className="mt-2" key={index}>
                                    <a
                                      href={`${apiurl}/${fileName}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {fileName}
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <div>No data available</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Modal
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        contentLabel="Add Text Modal"
                        className="d-flex flex-column time_sheetmodal gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                      >
                        {" "}
                        <div className="d-flex justify-content-between">
                          <h5>View Image</h5>
                          <div className="crosimg">
                            <Link
                              className="imgcross"
                              onClick={() => crosspopup()}
                              to="javascript:void(0)"
                            >
                              &times;
                            </Link>
                          </div>
                        </div>
                        <div className="imgurl">
                          <img src={imgurll} className="divimg" alt="Image" />
                        </div>
                      </Modal>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-9">
                <div className="row">
                  {isActiveno && (
                    <div className="col-md-6 mt-2">No results found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};

import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash } from "react-icons/fa";
import Slider from "react-slick";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";

export const Courselist = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [chh, setchh] = useState(false);
  const [activee, setactivee] = useState(null);
  const [userid, setuserid] = useState("");
  const [successmessage, setsuccessmessage] = useState(false);
  const [allcourse, setallcourse] = useState([]);
  const [allcourselength, setallcourselength] = useState([]);
  const [allcourseUser, setallcourseUser] = useState([]);
  const [allcourseUserlength, setallcourseUserlength] = useState([]);
  const [username, setusername] = useState("");
  const [cap, setcap] = useState("");
  const [spinner, setspinner] = useState(false);
  const [uid, setuser_id] = useState("");
  useEffect(() => {
    document.title = "All Course";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      table: "elearningCourse",
      userId: "",
    };
    axios
      .post("https://jlmining.app/admin/getallcourse", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setallcourselength(res.data.results.length);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getallcourseUsersempty", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const nn = res.data.results[0];

          setuser_id(nn.id);
          console.log("aaa");
          console.log(res.data.results);
        }
      })
      .catch((err) => {});
  }, [uid]);
  const getuserss = (idd) => {
    if (idd !== "") {
      console.log("a");
      console.log(idd);
      setactivee(0);
      let formdataa = {
        userId: idd,
      };

      axios
        .post("https://jlmining.app/admin/getallcourseUsers", formdataa, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          //console.log(res.data.results);
          if (res.data.results.length > 0) {
            const nnn = res.data.results[0];
            console.log("d");
            console.log(nnn);
            setallcourseUser(res.data.results);
            var fn =
              nnn.step2_title +
              nnn.first_name +
              " " +
              nnn.middle_name +
              " " +
              nnn.last_name;
            var mr = capitalizeFirstLetter(nnn.step2_title);
            var f = capitalizeFirstLetter(nnn.first_name);
            var m = capitalizeFirstLetter(nnn.middle_name);
            var l = capitalizeFirstLetter(nnn.last_name);
            var ff = mr + " " + f + "" + m + "" + l;

            setcap(ff);
            setusername(fn);
          }
        })
        .catch((err) => {});
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase();
  }
  //Get User Time Sheet
  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  const apiurl = "https://jlmining.app/uploads/elearning";
  const getusercourse = (user_id, index) => {
    let formdata = {
      userId: user_id,
    };
    setactivee(index);
    setuserid(user_id);
    axios
      .post("https://jlmining.app/admin/getallcourseUsers", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setallcourseUser(res.data.results);
        if (res.data.results.length > 0) {
          var nn = res.data.results[0];
          var fn =
            nn.step2_title +
            " " +
            nn.first_name +
            " " +
            nn.middle_name +
            " " +
            nn.last_name;
          var mr = capitalizeFirstLetter(nn.step2_title);
          var f = capitalizeFirstLetter(nn.first_name);
          var m = capitalizeFirstLetter(nn.middle_name);
          var l = capitalizeFirstLetter(nn.last_name);
          var ff = f + "" + m + "" + l;
          console.log(ff);
          setcap(ff);
          setusername(fn);
        }
      })
      .catch((err) => {});
  };
  const searchcourse = (event) => {
    console.log(event.target.value);
    let formdata = {
      search: event.target.value,
    };
    axios
      .post("https://jlmining.app/admin/getallcourseSearch", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setallcourselength(res.data.results.length);
      })
      .catch((err) => {});
  };
  const unassign = (name) => {
    if (userid === "") {
      setchh(true);
      return false;
    } else {
      setchh(false);
    }
    let formdata = {
      name: name,
      userId: userid,
    };
    setspinner(true);
    axios
      .post("https://jlmining.app/admin/courseunassign", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setsuccessmessage(true);
        setspinner(false);
        //getresponse();
        setTimeout(() => {
          setsuccessmessage(false);
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {});
  };
  function getresponse() {
    let formdata = {
      table: "elearningCourse",
      userId: "",
    };
    axios
      .post("https://jlmining.app/admin/getallcourse", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setallcourselength(res.data.results.length);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getallcourseUsers", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("userr");
        console.log(res.data.results);
        setallcourseUser(res.data.results);
        if (res.data.results.length > 0) {
          var nn = res.data.results[0];
          var fn = nn.first_name + " " + nn.middle_name + " " + nn.last_name;
          var f = capitalizeFirstLetter(nn.first_name);
          var m = capitalizeFirstLetter(nn.middle_name);
          var l = capitalizeFirstLetter(nn.last_name);
          var ff = f + "" + m + "" + l;
          console.log(ff);
          setcap(ff);
          setusername(fn);
        } else {
          setcap("");
          setusername("");
        }
      })
      .catch((err) => {});
  }
  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="d-flex justify-content-between">
              <h4>All Course</h4>
              <Link
                to="/admin/assign-new-course"
                className="btn btn-primary"
                style={{ background: "#F73B3C", border: "1px solid #F73B3C" }}
              >
                Assign New Course
              </Link>
            </div>

            <div className="row mt-4 gap-2">
              <h5>Assignment</h5>
              <div className="col-md-5 border rounded">
                <div className="row  mt-2 p-2">
                  <span>
                    <strong>{allcourselength} users </strong>currently assigned
                    courses
                  </span>
                  <div className="col-md-12 p-3">
                    {chh && (
                      <span className="text-danger">
                        Please choose the assign course
                      </span>
                    )}
                    <div className="mt-2">
                      <input
                        className="form-control"
                        name="search"
                        onInput={searchcourse}
                        type="search"
                        placeholder="Search..."
                      />
                    </div>
                  </div>

                  <div className="coursediv col-md-12">
                    {Array.isArray(allcourse) ? (
                      allcourse.length > 0 ? (
                        allcourse.map((item, index) => (
                          <button
                            type="button"
                            onClick={() => getusercourse(item.user_id, index)}
                            className={`w-100 border rounded shadow-sm p-3 mb-2 bg-body rounded me-4 ${
                              activee === index ? "active" : ""
                            }`}
                          >
                            <div className="d-flex  justify-content-between">
                              <h5 className="text-start mb-3">
                                {item.step2_title} {/* Add space here */}
                                {item.first_name} {/* Add space here */}
                                {item.last_name} {/* Add space here */}{" "}
                                {/* Add space here if needed */}
                              </h5>

                              <div className="d-flex justify-content-between">
                                <div className="d-flex rounded border p-2">
                                  <span> To do {item.coursecount} course</span>
                                </div>
                              </div>
                            </div>
                          </button>
                        ))
                      ) : (
                        <div className="no-data-message">
                          No Courses Available
                        </div>
                      )
                    ) : (
                      <div className="no-data-message">
                        Data is not an array
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 border rounded">
                <div className="row mt-2 p-2">
                  <div className="d-flex ">
                    <div class="p-3 mb-2 bg-secondary rounded-circle text-white coursename">
                      {cap}
                    </div>
                    <span class="mt-3 ml-2 textcourse">{username}</span>
                  </div>
                  {spinner && (
                    <div className="d-flex ">
                      <span class="mt-3  ml-2 spinner-border"></span>
                    </div>
                  )}
                  {successmessage && (
                    <div className="d-flex ">
                      <span class="mt-3  ml-2 text-success">
                        Unassign successfully
                      </span>
                    </div>
                  )}
                </div>
                <div className="row  p-2 w-100">
                  <div className="table_responsive ">
                    <table className="table">
                      <tr className="border-2 tablecourse">
                        <th className="text-dark">Course</th>
                        <th className="text-dark">Due date</th>
                        <th className="text-dark">Action</th>
                      </tr>
                      {Array.isArray(allcourseUser) ? (
                        allcourseUser.length > 0 ? (
                          allcourseUser.map((item, index) => (
                            <tr className="">
                              <td>{item.name}</td>
                              <td>{getdattime(item.assign_date)}</td>
                              <td>
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => unassign(item.name)}
                                  className="unassign"
                                >
                                  Unassign
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="no-data-message mt-4">
                            No Courses Available
                          </div>
                        )
                      ) : (
                        <div className="no-data-message  mt-4">
                          Data is not an array
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};

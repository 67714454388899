import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import Modal from "react-modal";

import moment from "moment-timezone";
import Select from "react-select";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Documents = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timezones, setTimezones] = useState([]);
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [isreferences, setreferences] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");

  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");

  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  const [getincidentData, setgetincidentData] = useState([]);

  const [documentupload, setdocumentupload] = useState("document-tab");
  const [isusername, setusername] = useState("");
  const [isusernameall, setusernameall] = useState("");
  const [fileshow, setfilename] = useState("");
  const [getallnotes, setgetallnotes] = useState([]);
  const [getallnotesl, setgetallnotesl] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const [dis, setdis] = useState(true);
  const [opacit, setopacit] = useState(true);
  const [filemain, setfilemain] = useState("");
  const [isalldocs, setalldocs] = useState([]);
  const [isalldocsl, setalldocsl] = useState(0);
  const [msgsuccess, setmsgsuccess] = useState(false);
  const [remainingChars, setRemainingChars] = useState(1000);
  const [remainingCharsedit, setRemainingCharsedit] = useState(
    Array(getallnotes.length).fill(1000)
  );
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openDropdownIndexedit, setOpenDropdownIndexedit] = useState(null);
  const [textedit, settextedit] = useState(null);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Incident Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australiaTimezones = moment.tz
      .names()
      .filter((zone) => zone.startsWith("Australia/"))
      .map((zone) => ({ value: zone, label: zone }));
    setTimezones(australiaTimezones);
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          console.log("check");
          console.log(indata);
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setStateChecked(indata.state);
          setyesNoChecked(indata.incident_safe);
          setIscountryChecked(indata.au);
          if (indata.au === "nz") {
            setactivecounrty(false);
          }
          setreferences(indata.reference);
          setgetincidentData(indata);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getincidentDoc", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setalldocsl(res.data.results.length);
        setalldocs(res.data.results);
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        if (alldata !== null) {
          var fset = alldata.first_name ? alldata.first_name.charAt(0) : "";
          var mset = alldata.middle_name ? alldata.middle_name.charAt(0) : "";
          var lset = alldata.last_name ? alldata.last_name.charAt(0) : "";
          var finalname = fset + mset + lset;
          setusername(finalname);
          var fsett = alldata.first_name;
          var msett = alldata.middle_name;
          var lsett = alldata.last_name;
          var fn = fsett + " " + msett + " " + lsett;
          setusernameall(fn);
        }
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/getincident_notes", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
      })
      .catch((err) => {});
  }, [id]);
  console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty(event.value);
    }
  };
  const step3Country = (event) => {
    if (event !== "") {
      setisetcountystep3(event.value);
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };
  const backbuttonStep3 = (event) => {
    setactivetab1(false);
    setactivetab3(false);
    setactivetab2(true);
    setactiveTabMenu("injuredperson");
  };
  const backbuttonStep4 = (event) => {
    setactivetab4(false);
    setactivetab3(true);
    setactiveTabMenu("reporter");
  };
  const step2fullname = (e) => {
    console.log(persondetail);
    console.log(e.target.value);
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    console.log(e);
    console.log(e);
  };
  const handincident_safe = (event) => {
    setisincident_safeChecked(event);
    if (event === "Yes") {
      setisincidentafe(true);
    } else {
      setisincidentafe(false);
    }
  };
  const handwasfirst = (event) => {
    setiswasChecked(event);
    if (event === "Yes") {
      setwasfirst(true);
    } else {
      setwasfirst(false);
    }
  };
  const handwhereother = (event) => {
    setiswhereotherChecked(event);
    if (event === "Yes") {
      setwhereother(true);
    } else {
      setwhereother(false);
    }
  };
  const handwitness = (event) => {
    setiswitnessChecked(event);
    if (event === "Yes") {
      setwitness(true);
    } else {
      setwitness(false);
    }
  };
  const references = (event) => {
    setreferences(event.target.value);
  };
  const handbuttonStep1 = (event) => {
    if (isreferences !== "") {
      setactiveTabMenu("injuredperson");
      setactivetab1(false);
      setactivetab2(true);
      var vll = event.target;
      const userStr = JSON.parse(localStorage.getItem("users"));
      if (vll.injury_suffered.value === "on") {
        var inju = "Yes";
      } else {
        var inju = "No";
      }
      let formdata = {
        check_code: uniquecode,
        user_id: userStr[0].id,
        injury_suffered: inju,
        reference: vll.reference.value,
        au: iscountryChecked,
        state: isStateChecked,
      };

      axios
        .post("https://jlmining.app/incident_step1", formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code !== "") {
            var cod = res.data.code;
            setuniquecode(cod);
            const newUrl = "/incident/victim/" + cod;

            // Use history.push to navigate to the new URL
            navigate(newUrl);
          }
        })
        .catch((err) => {});
    }
  };

  const handdocument = (event) => {
    setdocumentupload(event);
  };
  const handincidentNotes = (event) => {
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      code: uniquecode,
      user_id: userStr[0].id,
      notes: vll.notes.value,
    };
    axios
      .post("https://jlmining.app/incident_notes", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
        setRemainingChars("1000");
        event.target.reset();
      })
      .catch((err) => {});
  };
  const docupload = () => {
    setIsModalOpen(true);
  };
  const docclose = () => {
    setIsModalOpen(false);
  };
  const inputFileRef = useRef(null);
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    const file_Name = file.name; // Get the file name
    if (file_Name != "") {
      console.log(file_Name);
      setfilename(file_Name);
      setfilemain(file);
      setdis(false);
      setopacit(false);
    } else {
      setfilemain("");
      setopacit(true);
      setdis(true);
    }
  };
  const removefile = (event) => {
    setfilename("");
    setopacit(true);
    setdis(true);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };
  const handlesubmitdoc = (event) => {
    var fr = event.target;
    const formData = new FormData();
    const userStr = JSON.parse(localStorage.getItem("users"));
    console.log(filemain);
    if (filemain !== "") {
      formData.append("code", uniquecode);
      formData.append("user_id", userStr[0].id);
      formData.append("file", filemain);
      axios
        .post("https://jlmining.app/incidentDocs", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setalldocs(res.data.results);
          setalldocsl(res.data.results.length);
          setmsgsuccess(true);
          setTimeout(() => {
            window.location.reload();
            setIsModalOpen(false);
            setfilename("");
            setopacit(true);
            setdis(true);
            if (inputFileRef.current) {
              inputFileRef.current.value = "";
            }
            setmsgsuccess(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const removedocs = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let rem = {
      id: event,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/incidentremove", rem, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setalldocs(res.data.results);
        setalldocsl(res.data.results.length);
      })
      .catch((err) => {});
  };
  function formatDate_notes(dateString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const notestext = (event) => {
    const inputText = event.target.value;
    const remaining = 1000 - inputText.length;
    console.log(remaining);
    if (remaining > 0) {
      setRemainingChars(remaining);
    }
  };
  const notestextedit = (index, vl) => {
    const inputText = vl;
    const remaining = 1000 - inputText.length;
    setEditedNote(vl);
    if (remaining > 0) {
      setRemainingCharsedit(remaining);
    }
    settextedit(index === textedit ? null : index);
  };
  const removenotes = (event, index) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      id: event,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/incidentnotesremove", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);

        setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : null);
        setOpenDropdownIndex(index === openDropdownIndex ? null : null);
        setTimeout(() => {
          setgetallnotes(res.data.results);
          setgetallnotesl(res.data.results.length);
        }, 500);
      })
      .catch((err) => {});
  };
  const notesedit = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };
  const editnotes = (index) => {
    setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : index);
  };
  const [editedNote, setEditedNote] = useState("");
  const handincidentNotesedit = (id, index) => {
    console.log(id);
    if (editedNote != "") {
      const userStr = JSON.parse(localStorage.getItem("users"));
      let fdata = {
        id: id,
        notes: editedNote,
        user_id: userStr[0].id,
        code: uniquecode,
      };
      axios
        .post("https://jlmining.app/incidentnotesedit", fdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data.results);

          setOpenDropdownIndexedit(
            index === openDropdownIndexedit ? null : null
          );
          setOpenDropdownIndex(index === openDropdownIndex ? null : null);
          setTimeout(() => {
            setgetallnotes(res.data.results);
            setgetallnotesl(res.data.results.length);
          }, 500);
        })
        .catch((err) => {});
    }
    console.log(editedNote);
  };
  const getvalue = (event) => {
    console.log(event.target.value);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let fdata = {
      desc: event.target.value,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/getincident_notesdesc", fdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetallnotes(res.data.results);
        setgetallnotesl(res.data.results.length);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div className="d-flex flex-column flex-md-row gap-3 justify-content-between align-items-center hazard-title mb-3">
        <div className="d-flex gap-2 align-items-center">
          <h3>New incident report</h3>
          <div>
            <i
              className="fal fa-exclamation-circle"
              data-bs-toggle="tooltip"
              data-bs-title="An incident is an unintended event resulting in injury or illness, which could be
                    physical or psychological."
            ></i>
          </div>
        </div>
        {uniquecode && (
          <div>
            <Link
              to="javascript:void(0)"
              onClick={() => docupload()}
              className="attach-doc"
            >
              {" "}
              Attach documents{" "}
            </Link>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Add Text Modal"
        className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
      >
        <div style={{ width: "500px" }}>
          <div className="d-flex flex-column file-pop">
            <div className="d-flex justify-content-between align-items-center top-head">
              <div className="flex-grow-1">
                <h2>Upload</h2>
              </div>
              <div className="flex-shirnk-0">
                <button
                  type="button"
                  onClick={() => docclose()}
                  className="close-btn"
                >
                  <i className="fal fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="d-flex flex-column px-1 py-2">
              <h3 className="text-black choose-text">
                Choose the document you would like to upload.
              </h3>
              {fileshow && (
                <div className="sceenshot-box d-flex align-items-center  border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3">
                  <div className="flex-grow-1">
                    <h4>{fileshow}</h4>
                  </div>
                  <div className="flex-shirnk-0">
                    <button
                      type="button"
                      onClick={() => removefile()}
                      className="scsbtn"
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              )}
              <form
                action="javascript:void(0)"
                onSubmit={handlesubmitdoc}
                method="post"
              >
                <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                  <i className="fal fa-arrow-alt-up"></i>
                  <h1>Drag and drop your files here to upload</h1>
                  <h6>Browse files...</h6>
                  <input
                    type="file"
                    onChange={docfile}
                    ref={inputFileRef}
                    className="file-name-block"
                  />
                </div>
                <div className="d-flex flex-column gap-3 drag-box">
                  <div className="d-flex gap-2 check-box-list">
                    <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                      <div className="flex-shrink-0">
                        <button type="button">Cancel</button>
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          type="submit"
                          className={opacit ? "buttonopcity" : ""}
                          disabled={dis}
                        >
                          Upload
                        </button>
                      </div>
                      {msgsuccess && (
                        <span className="text-success">
                          File Successfully uploaded
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
